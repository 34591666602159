import { Lightning } from '@lightningjs/sdk';
import { List } from '@lightningjs/ui';
import { HoverableComponent } from 'components/common/HoverableComponent';
import PinInputBox from './PinInputBox';
import Color from '@lightningjs/sdk/src/Colors';
import { translate } from 'support/translate';

export interface PinInputTemplateSpec extends Lightning.Component.TemplateSpec {
  PinInputs: typeof List;
  input: string;
  useMaskingCharacter: boolean;
}

const HIGHLIGHT_CONTAINER_WIDTH = 732;
const HIGHLIGHT_CONTAINER_HEIGHT = 191;

export default class PinInput
  extends HoverableComponent<PinInputTemplateSpec>
  implements Lightning.Component.ImplementTemplateSpec<PinInputTemplateSpec>
{
  private pin: string[] = [];

  private _PinInputs = this.getByRef('PinInputs')!;
  useMaskingCharacter = true;

  get announce() {
    return [translate('ttsPrompts.ParentalPin.pinInput')];
  }

  set input(input: string) {
    this.pin = input.substring(0, 4).split('');

    this.updateItems();
  }

  static override _template(): Lightning.Component.Template<PinInputTemplateSpec> {
    return {
      rect: true,
      w: HIGHLIGHT_CONTAINER_WIDTH,
      h: HIGHLIGHT_CONTAINER_HEIGHT,
      color: Color('transparent').get(),
      shader: {
        type: Lightning.shaders.Outline,
        width: 4,
        color: Color('white').alpha(0).get(),
      },
      PinInputs: {
        x: 28,
        y: 28,
        type: List,
        itemType: PinInputBox,
        direction: 'row',
        spacing: 48,
        shader: {
          type: Lightning.shaders.Outline,
          width: 0,
        },
        items: [{}, {}, {}, {}],
      },
    };
  }

  override _focus() {
    this.patch({
      shader: { color: Color('white').alpha(1).get() },
    });
  }

  override _unfocus() {
    this.patch({
      shader: { color: Color('white').alpha(0).get() },
    });
  }

  override _handleEnter() {
    this.signal('$openKeyboard');
  }

  displayError() {
    this.pin = [];
    this.updateItems(true);
  }

  clearError() {
    this.updateItems(false);
  }

  private updateItems(hasError = false) {
    // Property order matters: maskingChar must be defined before pinValue
    this._PinInputs.patch({
      items: [
        {
          hasError,
          maskingChar: this.useMaskingCharacter,
          pinValue: this.pin[0],
        },
        {
          hasError,
          maskingChar: this.useMaskingCharacter,
          pinValue: this.pin[1],
        },
        {
          hasError,
          maskingChar: this.useMaskingCharacter,
          pinValue: this.pin[2],
        },
        {
          hasError,
          maskingChar: this.useMaskingCharacter,
          pinValue: this.pin[3],
        },
      ],
    });
  }
}
