import { translate } from 'support/translate';
import ParentalPin, { ParentalPinTemplateSpec } from './ParentalPin';
import { closeApp } from 'support/appUtils';
import { AppData, Lightning } from '@lightningjs/sdk';
import ResetPinButton from './ResetPinButton';
import ResetParentalPin from './ResetParentalPin';
import Router from '@lightningjs/sdk/src/Router';

export interface EnterParentalPinTemplateSpec extends ParentalPinTemplateSpec {
  Content: {
    ResetPin: typeof ResetPinButton;
  } & ParentalPinTemplateSpec['Content'];
}

const RESET_PIN_MARGIN_TOP = 48;

export default class EnterParentalPin extends ParentalPin<EnterParentalPinTemplateSpec> {
  static focusPageOnCancel = false;

  protected override _Content = this.getByRef('Content')!;
  private _ResetPin = this._Content.getByRef('ResetPin')!;

  static override _template(): Lightning.Component.Template<EnterParentalPinTemplateSpec> {
    const { Content, ...rest } = super._template();
    return {
      Content: {
        ...Content,
        ResetPin: {
          flexItem: { marginTop: RESET_PIN_MARGIN_TOP },
          type: ResetPinButton,
          signals: {
            $onHover: true,
          },
          zIndex: 2,
        },
      },
      ...rest,
    };
  }

  protected override update() {
    this.title = translate('ParentalPin.enterPin.title');
    this.description = translate('ParentalPin.enterPin.description');

    if (EnterParentalPin.focusPageOnCancel) {
      this._ResetPin.patch({ visible: false });
    } else {
      this._ResetPin.patch({ visible: true });
    }

    super.update();
  }

  override getCancelLabel() {
    if (EnterParentalPin.focusPageOnCancel) {
      return translate('ParentalPin.cancel');
    } else {
      return translate('ParentalPin.enterPin.exitApp');
    }
  }

  override _handleDown() {
    if (!EnterParentalPin.focusPageOnCancel) {
      this._setState('ResetPin');
    }
  }

  protected override resetStaticStates() {
    EnterParentalPin.focusWidgetOnClose = null;
    EnterParentalPin.onValidateCallback = null;
    EnterParentalPin.focusPageOnCancel = false;
  }

  protected override closeWidget() {
    this._Content.alpha = 0;
    Router.focusPage();
    this.resetStaticStates();
  }

  override $onHover(target: unknown) {
    super.$onHover(target);
    switch (target) {
      case this._ResetPin:
        this._setState('ResetPin');
        return;
    }
  }

  override $validate() {
    const isValid = AppData!.parentalPinService.validatePin(this.pin);

    if (!isValid) {
      this.handleInvalidPin();
    } else {
      EnterParentalPin.onValidateCallback?.();
      this.closeWidget();
    }
  }

  override $cancel() {
    if (EnterParentalPin.focusPageOnCancel) {
      this.resetStaticStates();
      this.closeWidget();
    } else {
      closeApp();
    }
  }

  private restoreState() {
    const onValidateCallbackRef = EnterParentalPin.onValidateCallback;
    const focusPageOnCancel = EnterParentalPin.focusPageOnCancel;
    const focusWidgetOnClose = EnterParentalPin.focusWidgetOnClose;

    const callback = () => {
      EnterParentalPin.onValidateCallback = onValidateCallbackRef;
      EnterParentalPin.focusPageOnCancel = focusPageOnCancel;
      EnterParentalPin.focusWidgetOnClose = focusWidgetOnClose;
    };

    // .bind(this) added as safety incase the callback above is modified to reference "this"
    return callback.bind(this);
  }

  private passStatesToResetPin() {
    ResetParentalPin.focusWidgetOnClose = 'EnterParentalPin';

    // we pass our validation callback ResetParentalPin and reset EnterParentalPin's state when canceling ResetParentalPin
    ResetParentalPin.onValidateCallback = EnterParentalPin.onValidateCallback;
    ResetParentalPin.onCancelCallback = this.restoreState();
  }

  static override _states(): Lightning.Component.Constructor[] {
    return [
      ...super._states(),
      class ResetPin extends this {
        override _getFocused() {
          return this._ResetPin;
        }

        override _handleEnter() {
          this.passStatesToResetPin();
          this.closeWidget();

          Router.focusWidget('ResetParentalPin');
        }

        override _handleUp() {
          this._setState('ActionsState');
        }
      },
    ];
  }
}
