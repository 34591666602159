import { Colors, Lightning } from '@lightningjs/sdk';
import { Video } from 'types/api/media';
import {
  getSeasonEpisodeFormatted,
  getSeasonEpisodeTtsFormatted,
  isLiveContent,
  isLiveEvent,
  isMovie,
} from 'support/contentUtils';
import { getFontFaceFromStyle } from 'support/textUtils';
import { getImageTextureObj } from 'support/generalUtils';
import { SelectItemContext } from 'types/events';
import Placeholder from 'components/common/Placeholder';
import { STANDARD_FADE } from 'support/animations';
import { SpeechType } from '@lightningjs/ui-components';
import { updateImageSize } from '../../../support/cwImageUtils';
import { HoverableComponent } from 'components/common/HoverableComponent';

export interface EndCardTileTemplateSpec
  extends Lightning.Component.TemplateSpec {
  mediaItem: Video | undefined;
  showTile: boolean;
  announce: SpeechType;
  announceContext: SpeechType;

  size: keyof typeof TILE_SIZE;
  ImageContainer: {
    Placeholder: typeof Placeholder;
    Image: Lightning.Component;
    PlayButton: Lightning.Texture;
    Highlight: Lightning.Component;
  };
  Title: Lightning.Component;
  Description: Lightning.Component;
}

const TILE_SIZE = {
  default: {
    w: 636,
    h: 349,
  },
  sub: {
    w: 413,
    h: 234,
  },
};

const TITLE_MAX_LINES = 1;
const TITLE_FONT_SIZE = 29;
const DESCRIPTION_FONT_SIZE = 32;
const DESCRIPTION_MAX_LINES = 1;
const PLAY_BUTTON_WIDTH = 47;
const PLAY_BUTTON_HEIGHT = 56;
const TITLE_MARGIN_TOP = 9;
const DESCRIPTION_MARGIN_TOP = -2;

export default class EndCardTile
  extends HoverableComponent<EndCardTileTemplateSpec>
  implements Lightning.Component.ImplementTemplateSpec<EndCardTileTemplateSpec>
{
  private _ImageContainer = this.getByRef('ImageContainer')!;
  private _Placeholder = this._ImageContainer.getByRef('Placeholder')!;
  private _Image = this._ImageContainer.getByRef('Image')!;
  private _PlayButton = this._ImageContainer.getByRef('PlayButton')!;
  private _Highlight = this._ImageContainer.getByRef('Highlight')!;
  private _Title = this.getByRef('Title')!;
  private _Description = this.getByRef('Description')!;

  private _mediaItem: Video | undefined;
  private _showTile = true;
  private _titleText = '';
  private _descriptionText = '';
  private _descriptionTtsText = '';
  private _announce: SpeechType = '';
  private _announceContext: SpeechType = '';

  set mediaItem(mediaItem: Video) {
    this._mediaItem = mediaItem;
    this.resetText();

    if (
      isMovie(mediaItem) ||
      isLiveContent(mediaItem) ||
      isLiveEvent(mediaItem)
    ) {
      this._titleText = mediaItem.title;
      this._descriptionText = '';
      this._descriptionTtsText = '';
    } else {
      this._titleText = mediaItem.seriesName;
      this._descriptionText = getSeasonEpisodeFormatted(mediaItem);
      this._descriptionTtsText = getSeasonEpisodeTtsFormatted(mediaItem);
    }

    this.setImage();
    this._Title.patch({ text: { text: this._titleText } });
    this._Description.patch({ text: { text: this._descriptionText } });
  }

  get mediaItem(): Video | undefined {
    return this._mediaItem;
  }

  set announce(string: SpeechType) {
    this._announce = string;
  }

  get announce(): SpeechType[] {
    return [this._announce, this._titleText, this._descriptionTtsText];
  }

  set announceContext(string: SpeechType) {
    this._announceContext = string;
  }

  get announceContext() {
    return this._announceContext;
  }

  set showTile(value: boolean) {
    this._showTile = value;
    this.setImage();
  }

  set size(size: keyof typeof TILE_SIZE) {
    const tileSize = TILE_SIZE[size];

    this._ImageContainer.w = tileSize.w;
    this._ImageContainer.h = tileSize.h;
    this._Placeholder.w = tileSize.w;
    this._Placeholder.h = tileSize.h;
    this._Image.w = tileSize.w;
    this._Image.h = tileSize.h;
    this._Highlight.w = tileSize.w;
    this._Highlight.h = tileSize.h;
    this._PlayButton.x = tileSize.w / 2;
    this._PlayButton.y = tileSize.h / 2;
    this._Title.patch({ text: { wordWrapWidth: tileSize.w } });
    this._Description.patch({ text: { wordWrapWidth: tileSize.w } });
  }

  get action(): SelectItemContext['action'] {
    return 'play';
  }

  static override _template(): Lightning.Component.Template<EndCardTileTemplateSpec> {
    const playButtonTexture = getImageTextureObj(
      'static/images/playback/player-play-icon.svg',
      PLAY_BUTTON_WIDTH,
      PLAY_BUTTON_HEIGHT,
    );

    return {
      flex: { direction: 'column' },
      ImageContainer: {
        w: TILE_SIZE.default.w,
        h: TILE_SIZE.default.h,
        Placeholder: {
          type: Placeholder,
          visible: true,
          w: TILE_SIZE.default.w,
          h: TILE_SIZE.default.h,
        },
        Image: {
          rect: true,
          w: TILE_SIZE.default.w,
          h: TILE_SIZE.default.h,
        },
        PlayButton: {
          alpha: 0,
          mount: 0.5,
          x: TILE_SIZE.default.w / 2,
          y: TILE_SIZE.default.h / 2,
          ...playButtonTexture,
        },
        Highlight: {
          rect: true,
          alpha: 0,
          w: TILE_SIZE.default.w,
          h: TILE_SIZE.default.h,
          color: Colors('transparent').get(),
          shader: {
            type: Lightning.shaders.RoundedRectangle,
            stroke: 4,
            strokeColor: Colors('highlight').get(),
          },
          transitions: STANDARD_FADE,
        },
      },
      Title: {
        flexItem: { marginTop: TITLE_MARGIN_TOP },
        alpha: 0,
        text: {
          fontSize: TITLE_FONT_SIZE,
          fontFace: getFontFaceFromStyle('regular'),
          maxLines: TITLE_MAX_LINES,
          wordWrapWidth: TILE_SIZE.default.w,
        },
      },
      Description: {
        flexItem: { marginTop: DESCRIPTION_MARGIN_TOP },
        alpha: 0,
        text: {
          fontSize: DESCRIPTION_FONT_SIZE,
          fontFace: getFontFaceFromStyle('bold'),
          maxLines: DESCRIPTION_MAX_LINES,
          wordWrapWidth: TILE_SIZE.default.w,
        },
      },
    };
  }

  override _setup() {
    super._setup();
    this._Image.on('txLoaded', () => {
      this._Placeholder.patch({ visible: false });
    });

    this._Image.on('txUnloaded', () => {
      this._Placeholder.patch({ visible: true });
    });
  }

  override _focus() {
    this.updateTileButton();
  }

  override _unfocus() {
    this.updateTileButton();
  }

  override _handleEnter() {
    this.signal('$onTileSelected', this);
  }

  private updateTileButton() {
    const playButtonAlpha = this.hasFocus() ? 0.6 : 0;
    this._PlayButton.setSmooth('alpha', playButtonAlpha);

    const focusAlpha = this.hasFocus() ? 1 : 0;
    this._Title.setSmooth('alpha', focusAlpha);
    this._Description.setSmooth('alpha', focusAlpha);
    this._Highlight.setSmooth('alpha', focusAlpha);
  }

  private setImage() {
    if (!this._mediaItem) return;

    const thumbnailUrl = this._showTile
      ? this._mediaItem.images.image_show_thumbnail
      : this._mediaItem.images.thumbnail;
    const src = updateImageSize(thumbnailUrl, TILE_SIZE.default.w);

    this._Image.patch({ src });
  }

  private resetText() {
    this._titleText = '';
    this._descriptionText = '';
    this._descriptionTtsText = '';
  }
}
