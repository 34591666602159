import { AppData, Colors, Lightning } from '@lightningjs/sdk';
import HorizontalWatchedItem from 'components/common/HorizontalWatchedItem';
import { translate } from 'support/translate';
import WatchHistoryNoResults from '../WatchHistoryNoResults';
import Button from 'components/common/Button';
import ActionsList from 'components/common/ActionsList';
import { VideoLaneItem } from 'services/cwData';
import {
  getContinueEmpty,
  getMediaDataFromIdentifiers,
} from 'services/tivoService';
import { Show, Video } from 'types/api/media';
import { getFontFaceFromStyle } from 'support/textUtils';
import constants from '../../../../../static/constants.json';
import { WatchHistoryColumnList } from 'components/common/WatchHistoryColumnList';
import { SelectItemContext } from 'types/events';
import { StaticViewContexts } from 'types/analytics';
import LoadingSpinner from 'components/common/LoadingSpinner';

export interface WatchHistorySectionTemplateSpec
  extends Lightning.Component.TemplateSpec {
  SectionTitle: typeof Lightning.Element;
  ListActions: {
    ViewModeActions: typeof ActionsList;
    EditModeActions: typeof ActionsList;
  };
  HistoryResults: {
    WatchHistoryList: typeof WatchHistoryColumnList;
  };
  NoHistoryResults: typeof WatchHistoryNoResults;
  LoadingSpinner: typeof LoadingSpinner;
}

const TITLE_Y_PADDING = 16;
const X_PADDING = 20;

const HISTORY_LIST_Y_OFFSET = 80;
const HISTORY_LIST_WIDTH = 810;
const HISTORY_LIST_HEIGHT = 892;
const HISTORY_LIST_HIGHLIGHT_PADDING = 10;
const HISTORY_LIST_SCROLL_MAX = 2;

const EDIT_WIDTH = 84;
const CANCEL_WIDTH = 110;
const SELECT_WIDTH = 154;
const DELETE_WIDTH = 106;

const BUTTON_HEIGHT = 44;

const NO_HISTORY_Y_OFFSET = 89;

const SELECT_INDEX = 1;

const buildButton = (label: string, action: `$${string}`) => {
  return {
    type: Button,
    label,
    flexItem: { margin: 12.5 },
    height: BUTTON_HEIGHT,
    padding: 9,
    fontSize: 20,
    radius: 3,
    action,
    signals: {
      $onHover: '$onHover',
    },
  };
};

export default class WatchHistorySection
  extends Lightning.Component<WatchHistorySectionTemplateSpec>
  implements
    Lightning.Component.ImplementTemplateSpec<WatchHistorySectionTemplateSpec>
{
  private _ListActions = this.getByRef('ListActions')!;
  private _ViewModeActions = this._ListActions.getByRef('ViewModeActions')!;
  private _EditModeActions = this._ListActions.getByRef('EditModeActions')!;
  private _HistoryResults = this.getByRef('HistoryResults')!;
  private _WatchHistoryList =
    this._HistoryResults.getByRef('WatchHistoryList')!;
  private _NoHistoryResults = this.getByRef('NoHistoryResults')!;
  private _LoadingSpinner = this.getByRef('LoadingSpinner')!;

  private isViewMode = true;
  private selectedItems: Record<string, boolean> = {};

  static override _template(): Lightning.Component.Template<WatchHistorySectionTemplateSpec> {
    return {
      x: X_PADDING,
      y: TITLE_Y_PADDING,
      SectionTitle: {
        text: {
          text: translate('settings.WatchHistory.listTitle'),
          fontSize: 32,
          fontFace: getFontFaceFromStyle('regular'),
        },
      },
      ListActions: {
        x: HISTORY_LIST_WIDTH,
        ViewModeActions: {
          type: ActionsList,
          visible: true,
          mountX: 1,
          spacing: 25,
          signals: {
            $edit: true,
            $onHover: true,
          },
        },
        EditModeActions: {
          type: ActionsList,
          visible: false,
          mountX: 1,
          spacing: 25,
          signals: {
            $cancel: true,
            $select: true,
            $delete: true,
            $onHover: true,
          },
        },
      },
      HistoryResults: {
        visible: false,
        w: HISTORY_LIST_WIDTH,
        h: HISTORY_LIST_HEIGHT,
        WatchHistoryList: {
          y: HISTORY_LIST_Y_OFFSET,
          w: w => w,
          h: h => h,
          type: WatchHistoryColumnList,
          clipping: true,
          scrollMax: HISTORY_LIST_SCROLL_MAX,
          highlightPadding: HISTORY_LIST_HIGHLIGHT_PADDING,
          signals: {
            $onHover: '$onHover',
          },
        },
      },
      NoHistoryResults: {
        y: NO_HISTORY_Y_OFFSET,
        visible: false,
        type: WatchHistoryNoResults,
        signals: {
          $onHover: '$onHover',
        },
      },
      LoadingSpinner: {
        type: LoadingSpinner,
        mount: 0.5,
        x: HISTORY_LIST_WIDTH / 2,
        y: HISTORY_LIST_HEIGHT / 2 + HISTORY_LIST_Y_OFFSET,
        show: false,
      },
    };
  }

  override _setup() {
    this._WatchHistoryList.forceLoad = true;
    this.setupViewModeActions();
    this.setupEditModeActions();
  }

  override _active() {
    this._setState('HistoryList');

    this.setupViewMode();
    this.setupContent();
    this.updateSelectText();
  }

  private setupViewModeActions() {
    this._ViewModeActions.clearActions();

    this._ViewModeActions.addAction({
      minWidth: EDIT_WIDTH,
      passSignals: {
        $edit: true,
      },
      ...buildButton(translate('settings.WatchHistory.edit'), '$edit'),
    });
  }

  private setIsLoading(isLoading: boolean) {
    this._LoadingSpinner.patch({ show: isLoading });
  }

  private setupEditModeActions() {
    this._EditModeActions.clearActions();

    this._EditModeActions.addAction({
      minWidth: CANCEL_WIDTH,
      passSignals: {
        $cancel: true,
      },
      ...buildButton(translate('settings.WatchHistory.cancel'), '$cancel'),
    });

    this._EditModeActions.addAction({
      minWidth: SELECT_WIDTH,
      passSignals: {
        $select: true,
      },
      ...buildButton(translate('settings.WatchHistory.selectAll'), '$select'),
    });

    this._EditModeActions.addAction({
      minWidth: DELETE_WIDTH,
      passSignals: {
        $delete: true,
      },
      ...buildButton(translate('settings.WatchHistory.delete'), '$delete'),
      backgroundColor: {
        unfocused: Colors('watchHistoryDelete').get(),
        focused: Colors('white').get(),
      },
    });
  }

  private async getAllContents() {
    return await AppData!.historyService.getWatchHistoryItems();
  }

  private async setupContent() {
    const watchHistoryItems =
      await AppData?.historyService.getWatchHistoryItems(
        1,
        constants.watchHistory.pageSize,
      );

    const sortedShowSlugs = AppData!.historyService.getSortedHistoryShowSlugs();
    this.selectedItems = sortedShowSlugs.reduce<{ [key: string]: boolean }>(
      (result, currentSlug) => {
        result[currentSlug] = false;
        return result;
      },
      {},
    );
    this._WatchHistoryList.selectedItems = this.selectedItems;

    if (watchHistoryItems && watchHistoryItems.length > 0) {
      this.setupHistoryItems(watchHistoryItems);
    } else {
      this.setupRecommended();
    }
  }

  private setupHistoryItems(items: VideoLaneItem[]) {
    this._NoHistoryResults.patch({ visible: false });
    this._HistoryResults.patch({ visible: true });
    this._ListActions.patch({ visible: true });

    this._WatchHistoryList.enableRequests = false;
    this._WatchHistoryList.patch({ items });
    /**
     * if paginate occurs, enableRequests needs to be reenabled as it will be set as
     * false after a "failed"/"no more" paginate response
     * also SizedColumnList calls setIndex when setting items. To prevent onRequestItems
     * from being called while at index 0, enableRequest should be set to true AFTER
     * items are set. (Look at CollectionWrapper::setIndex() and _requestMore())
     */
    this._WatchHistoryList.enableRequests = true;
  }

  private async setupRecommended() {
    this._NoHistoryResults.patch({ visible: true });
    this._HistoryResults.patch({ visible: false });
    this._ListActions.patch({ visible: false });
    this._setState('Recommended');

    const recommendedIds = await getContinueEmpty();
    const recommended = (await getMediaDataFromIdentifiers(
      recommendedIds,
    )) as Show[];
    this._NoHistoryResults.recommended = recommended;
  }

  private isAllSelected() {
    const allSelected = Object.values(this.selectedItems).every(
      value => value === true,
    );
    return allSelected;
  }

  private setupViewMode() {
    this._ViewModeActions.patch({ visible: true });
    this._EditModeActions.patch({ visible: false });

    this.updateViewMode(true);
    this.clearSelected();
    this.updateWatchedItems();
  }

  private updateViewMode(value: boolean) {
    this.isViewMode = value;
    this._WatchHistoryList.isViewMode = value;
  }

  private updateSelectText() {
    const selectButton = this._EditModeActions.getAction(SELECT_INDEX);

    const isAllSelected = this.isAllSelected();

    if (isAllSelected) {
      selectButton?.patch({
        label: translate('settings.WatchHistory.deselectAll'),
      });
    } else {
      selectButton?.patch({
        label: translate('settings.WatchHistory.selectAll'),
      });
    }
  }

  private clearSelected() {
    for (const showSlug in this.selectedItems) {
      this.selectedItems[showSlug] = false;
    }
    this._WatchHistoryList.selectedItems = this.selectedItems;
  }

  private updateWatchedItems() {
    const items =
      this._WatchHistoryList.getListItems() as HorizontalWatchedItem[];

    for (let i = 0; i < items.length; i++) {
      items[i]!.edit = !this.isViewMode;
      items[i]!.isActive = !!this.selectedItems[items[i]!.mediaItem!.showSlug];
    }
  }

  private $edit() {
    this._setState('ListEditActions');
  }

  private $cancel() {
    this._setState('ListViewActions');
  }

  private async $select() {
    this.setIsLoading(true);
    const isActive = !this.isAllSelected();

    const items = await this.getAllContents();

    for (let i = 0; i < items.length; i++) {
      this.selectedItems[items[i]!.showSlug] = isActive;
    }
    this._WatchHistoryList.selectedItems = this.selectedItems;

    this.updateSelectText();
    this.updateWatchedItems();
    this.setIsLoading(false);
  }

  private $delete() {
    if (Object.values(this.selectedItems).every(value => value === false))
      return;

    const selectedSlugs = Object.entries(this.selectedItems)
      .filter(([key, value]) => value === true)
      .map(([key, value]) => key);

    AppData?.historyService.deleteShowHistoryWithSlugs(selectedSlugs);

    this.setupContent();
    this.updateSelectText();
  }

  private $onTileSelected(data: Video, context: SelectItemContext) {
    const viewContext = StaticViewContexts.CONTINUE_WATCHING;
    this.fireAncestors('$onTileSelected', data, {
      viewContext,
      ...context,
    });
  }

  private async $toggleWatchItem(
    isActive: boolean,
    context: HorizontalWatchedItem,
  ) {
    const items =
      this._WatchHistoryList.getListItems() as HorizontalWatchedItem[];
    const index = items.findIndex(item => item === context);

    if (index === -1) return;

    /*
    If user selected 'Select All' when the list is partially loaded, and unselects an item, 
    then unselect all the unloaded contents.
    Only consider the first 16 (watchHistory.pageSize) items available on initial load.
    This is to prevent unintentional deletion of hidden contents.
    */
    if (this.isAllSelected()) {
      const fullList = await this.getAllContents();

      if (items.length < fullList.length && !isActive) {
        for (
          let i = constants.watchHistory.pageSize;
          i < fullList.length;
          i++
        ) {
          this.selectedItems[fullList[i]!.showSlug] = false;
        }
      }
    }
    this.selectedItems[items[index]!.mediaItem!.showSlug] = isActive;
    items[index]!.isActive = isActive;
    this._WatchHistoryList.selectedItems = this.selectedItems;

    this.updateSelectText();
  }

  $onHover(target: unknown) {
    switch (target) {
      case this._WatchHistoryList:
        this._setState('HistoryList');
        break;
      case this._NoHistoryResults:
        this._setState('Recommended');
        break;
      case this._EditModeActions:
        this._setState('ListEditActions');
        break;
      case this._ViewModeActions:
        this._setState('ListViewActions');
    }
  }

  static override _states() {
    return [
      class HistoryList extends this {
        override _getFocused() {
          return this._WatchHistoryList as Lightning.Component;
        }

        override _captureUp() {
          if (this._WatchHistoryList.index === 0) {
            this._setState(
              this.isViewMode ? 'ListViewActions' : 'ListEditActions',
            );
            return true;
          }
          return false;
        }
      },
      class ListViewActions extends this {
        override $enter() {
          if (this.isViewMode) return;

          this.setupViewMode();
        }

        override _getFocused() {
          return this._ViewModeActions as Lightning.Component;
        }

        override _handleDown() {
          this._setState('HistoryList');
        }
      },
      class ListEditActions extends this {
        override $enter() {
          if (!this.isViewMode) return;

          this._ViewModeActions.patch({ visible: false });
          this._EditModeActions.patch({ visible: true });

          this.updateViewMode(false);
          this.updateSelectText();
          this.updateWatchedItems();
        }

        override _getFocused() {
          return this._EditModeActions as Lightning.Component;
        }

        override _handleDown() {
          this._setState('HistoryList');
        }
      },
      class Recommended extends this {
        override _getFocused() {
          return this._NoHistoryResults;
        }
      },
    ];
  }
}
