type DynamicViewContexts = {
  // Content Hub
  CONTENT_HUB_BILLBOARD: `${string} Hub Billboard`; // Selected generic Content Hub billboard
  CONTENT_HUB_SWIMLANE: `${string} Episode Swimlane`; // Selected generic Content Hub episode tile
};

export enum StaticViewContexts {
  // Home
  HOME_BILLBOARD = 'Home Billboard', // Selected Homepage billboard
  HOME_CONTINUE_WATCHING = 'Home Continue Watching', // Selected Home continue watching row tile
  HOME_EPISODES_SWIMLANES = 'Home Episode Swimlane', // Selected Home episode tile

  // Movie Details
  MOVIE_DETAIL_PAGE = 'Movie Detail Page', // Selected Movie Detail page movie

  // Series Details
  SERIES_DETAIL_PAGE = 'Series Detail Page', // Selected Series Detail page episode

  // Search Page
  SEARCH_PAGE = 'Search Page', // Selected Search page episode or movie

  // Continue Watching
  CONTINUE_WATCHING = 'Continue Watching', // Selected horizontal watched item in settings

  // Live Page
  LIVE = 'Live', // Selected Live page content

  // EPG
  EPG = 'EPG', // Selected EPG page content

  // Deeplink
  DEEPLINK = 'Deeplink', // Selected a deep link

  // End Card
  END_CARD_USER_SELECTED = 'End Card User Selected', // End card recommended content selected
  END_CARD_AUTOPLAY = 'End Card Autoplay', // End card recommended content autoplays

  // Unknown
  UNKNOWN = 'Unknown', // Should never be used, placeholder for view context
}

export type ViewContext =
  | DynamicViewContexts[keyof DynamicViewContexts]
  | `${StaticViewContexts}`;

export const getGenericContentHubBillboardViewContext = (
  hubTitle: string,
): DynamicViewContexts['CONTENT_HUB_BILLBOARD'] => `${hubTitle} Hub Billboard`;

export const getGenericContentHubSwimlaneViewContext = (
  hubTitle: string,
): DynamicViewContexts['CONTENT_HUB_SWIMLANE'] =>
  `${hubTitle} Episode Swimlane`;

export enum PlaybackEvent {
  START,
  PAUSE,
  SEEK_PAUSE,
  RESUME,
  BEFORE_SEEK,
  SEEK_START,
  SEEK_END,
  BUFFER_START,
  BUFFER_END,
  AD_POD_START,
  AD_START,
  AD_END,
  AD_POD_END,
  CREDITS_REACHED,
  END,
  COMPLETE,
}

export enum ErrorType {
  MISSING_ID = 'Missing ID',
  MISSING_CONTENT = 'Missing Content',
  MISSING_PLAYBACK_CONTENT = 'Missing Playback Content',
  NOT_FOUND = 'Not Found',
  STREAM_ERROR = 'Stream Error',
}

export enum StartType {
  AUTO_PLAY,
}

export enum SeekType {
  STEP_FORWARD,
  STEP_BACKWARD,
  START_OVER,
}
