// contains all the MParticle attribute definitions (as defined in master data plan)
type AttributeDefinitions = {
  row_name: string;
  row_position: string;
  Series_Name: string;
  creative_position: string;
  banner_type: 'hero' | 'row';
  Season_Name: string;
  Episode_Name: string;
  episode_position: string;
  category: string;
  scroll_depth: string;
  image_file_title: string;
  Source_Site: string;
  Tile_Source: 'Tivo Screens' | 'CMS';
};

// used to define payload types to enforce uniform attributes
type Payload<T extends keyof AttributeDefinitions> = Pick<
  AttributeDefinitions,
  T
>;

export enum MParticleEvents {
  HERO_ENGAGEMENT = 'hero_engagement',
  ROW_ENGAGEMENT = 'row_engagement',
  CATEGORY_ENGAGEMENT = 'category_engagement',
  SHOW_LIST_ITEM_CLICKED = 'show_page_engagement',
  SCROLL_TRACKING = 'scroll_tracking',
  HEADER_NAVIGATION = 'header_navigation',
}

export enum MParticleVideoEvents {
  AD_START = 'Video_Ad_Start',
  AD_COMPLETE = 'Video_Ad_Complete',
  ERROR = 'Error',
  START = 'Video_Start',
  AUTO_START = 'Video_Auto_Starts',
  MIDPOINT = 'Video_50_Complete',
  FINISH = 'Video_Complete',
  TOGGLE_CLOSED_CAPTIONS = 'Toggle_Closed_Captions',
  PAUSE = 'Video_Pause',
  RESUME = 'Video_Resume',
  START_OVER = 'Video_Start_Over',
  ACTION_STEP_BACKWARD = 'Video_Go_Back',
  ACTION_STEP_FORWARD = 'Video_Fast_Forward',
  ACTION_SEEK = 'Video_Seek',
  RECOMMENDATION_AUTO_PLAY = 'Recommendation_Auto_Play',
  RECOMMENDATION_CLICKED = 'Recommendation_Clicked',
}

export type HeaderNavigationPayload = Payload<'category'>;

export type PromotionCustomAttrs = Payload<
  'Series_Name' | 'image_file_title' | 'creative_position' | 'Source_Site'
>;

export type ItemCustomAttrs = Payload<
  | 'creative_position'
  | 'image_file_title'
  | 'row_name'
  | 'row_position'
  | 'Series_Name'
  | 'Tile_Source'
>;

// note we are excluding showpage_header (see PR for EL-316)
export type ShowPageEngagement = Payload<
  | 'Series_Name'
  | 'Season_Name'
  | 'Episode_Name'
  | 'row_position'
  | 'row_name'
  | 'image_file_title'
  | 'creative_position'
>;

export type HeroEngagementPayload = Payload<
  'banner_type' | 'image_file_title' | 'Series_Name' | 'creative_position'
>;

export type RowEngagementPayload = Payload<
  | 'banner_type'
  | 'image_file_title'
  | 'row_name'
  | 'row_position'
  | 'Series_Name'
  | 'creative_position'
  | 'Tile_Source'
>;

export type ScrollTrackingPayload = Payload<'scroll_depth'>;

export type CategoryEngagementPayload = Payload<
  'category' | 'row_position' | 'row_name'
>;

export type CartCustomAttrs = Payload<
  | 'image_file_title'
  | 'Series_Name'
  | 'row_name'
  | 'row_position'
  | 'creative_position'
  | 'Source_Site'
>;
