import { AppData } from '@lightningjs/sdk';
import {
  getConfig,
  getShowByShowSlug,
  getVideos,
  MAX_GUID_COUNT,
} from './cwService';
import TivoApiClient from './TivoApiClient';
import {
  TivoDetailsRecommendedResponse,
  TivoRecommendedResponse,
  TivoSearchResponse,
  TivoTrendingResponse,
  parseMediaIdentifersFromDetailsRecommended,
  parseMediaIdentifersFromSearchResponse,
  parseMediaIdentifiersFromRecommended,
  parseMediaIdentifiersFromTrending,
} from './tivoData';
import { Platform } from 'models/platforms/platform';
import { AxiosResponse, Method } from 'axios';
import { HttpOptions } from './CwApiClient';
import ApiServiceError from 'support/ApiServiceError';
import { ErrorType } from 'types/analytics';
import { MediaIdentifier, Show, Video } from 'types/api/media';
import { parseTivoScreens } from './cwData';

type TivoParamKey = 'guid' | 'device' | 'cwuid' | 'searchterm';
type TivoParams = Partial<Record<TivoParamKey, string>>;

const client = new TivoApiClient();

const sendRequest = async <TResult, TResponse>(
  method: Method,
  endpoint: string,
  parser?: (response: TResponse) => TResult,
  options?: HttpOptions,
) => {
  const response = await client.request<TResponse>(method, endpoint, options);
  return handleResponse<TResult, TResponse>(response, parser);
};

const handleResponse = <TResult, TResponse>(
  response: AxiosResponse,
  parser?: (response: TResponse) => TResult,
): TResult => {
  let result: TResult;
  if (parser) {
    try {
      result = parser(response.data);
    } catch (e) {
      throw new ApiServiceError(ErrorType.MISSING_CONTENT);
    }
  } else {
    result = response.data as TResult;
  }

  return result;
};

const createFilledEndpoint = (endpoint: string, params: TivoParams = {}) => {
  let completedEndpoint = endpoint;
  const staticTivoParams: TivoParams = {
    device: getDeviceParam(),
    cwuid: window.analytics.mParticle.getMpid() ?? undefined,
  };
  Object.entries({ ...staticTivoParams, ...params }).forEach(([key, value]) => {
    completedEndpoint = completedEndpoint.replace(`{${key}}`, value);
  });

  return completedEndpoint;
};

const getDeviceParam = () => {
  switch (AppData!.device.getPlatform()) {
    case Platform.TIZEN:
      return 'tizen';
    case Platform.LG:
      return 'lg';
    case Platform.VIZIO:
      return 'vizio';
  }
};

export const getSearch = async (searchTerm: string) => {
  if (!AppData?.tivoEndpoints?.search) await getConfig();

  const endpoint = createFilledEndpoint(AppData!.tivoEndpoints!.search.url, {
    searchterm: searchTerm,
  });

  return await sendRequest<MediaIdentifier[], TivoSearchResponse>(
    'GET',
    endpoint,
    parseMediaIdentifersFromSearchResponse,
  );
};

export const getRecommended = async (guid: string) => {
  if (!AppData?.tivoEndpoints?.details_recommended_v2) await getConfig();

  const endpoint = createFilledEndpoint(
    AppData!.tivoEndpoints!.details_recommended_v2.url,
    {
      guid,
    },
  );

  return await sendRequest<MediaIdentifier[], TivoDetailsRecommendedResponse>(
    'GET',
    endpoint,
    parseMediaIdentifersFromDetailsRecommended,
  );
};

export const getTrending = async () => {
  if (!AppData?.tivoEndpoints?.trending) await getConfig();

  const endpoint = createFilledEndpoint(AppData!.tivoEndpoints!.trending.url);

  return await sendRequest<MediaIdentifier[], TivoTrendingResponse>(
    'GET',
    endpoint,
    parseMediaIdentifiersFromTrending,
  );
};

export const getContinueEmpty = async () => {
  if (!AppData?.tivoEndpoints?.continue_empty) await getConfig();

  const endpoint = createFilledEndpoint(
    AppData!.tivoEndpoints!.continue_empty.url,
  );

  return await sendRequest<MediaIdentifier[], TivoTrendingResponse>(
    'GET',
    endpoint,
    parseMediaIdentifiersFromTrending,
  );
};

export const getEndCardRecommended = async (guid: string) => {
  if (!AppData?.tivoEndpoints?.recommend) await getConfig();
  const endpoint = createFilledEndpoint(AppData!.tivoEndpoints!.recommend.url, {
    guid,
  });

  return await sendRequest<MediaIdentifier[], TivoRecommendedResponse>(
    'GET',
    endpoint,
    parseMediaIdentifiersFromRecommended,
  );
};

export const getTivoApiScreens = async (apiUrl: string | undefined) => {
  if (!apiUrl) return [];

  const endpoint = createFilledEndpoint(apiUrl);
  return await sendRequest<any, TivoTrendingResponse>(
    'GET',
    endpoint,
    parseTivoScreens,
  );
};

const batchRequestVideosFromIdentifiers = async (
  identifiers: MediaIdentifier[],
) => {
  if (!identifiers.length) return [];
  const requests: Promise<Video[]>[] = [];

  // Loop to create chunks of media identifiers that are MAX_GUID_COUNT sized.
  // This ensures we don't exceed the GUID limit for videos when calling getVideos
  for (let left = 0; left < identifiers.length; left += MAX_GUID_COUNT) {
    const right = Math.min(left + MAX_GUID_COUNT, identifiers.length);
    const chunk = identifiers.slice(left, right);

    const guids = chunk.reduce((prevStr, value) => {
      const guid = value.guid!;
      return prevStr ? `${prevStr},${guid}` : guid;
    }, '');

    requests.push(getVideos(guids));
  }

  const response: Array<Video> = [];
  (await Promise.allSettled(requests)).forEach(result => {
    if (result.status === 'fulfilled')
      result.value.forEach(video => response.push(video));
  });

  return response;
};

const batchRequestShowsFromIdentifiers = async (
  identifiers: MediaIdentifier[],
) => {
  if (!identifiers.length) return [];
  const requests: Promise<Show | null>[] = [];

  identifiers.forEach(identifier => {
    if (identifier.slug) requests.push(getShowByShowSlug(identifier.slug));
  });

  const response: Array<Show> = [];
  (await Promise.allSettled(requests)).forEach(result => {
    if (result.status === 'fulfilled' && result.value)
      response.push(result.value);
  });

  return response;
};

export const getMediaDataFromIdentifiers = async (
  identifiers: MediaIdentifier[],
) => {
  const videoIdentifiers = identifiers.filter(
    identifier => identifier.objectType === 'Episode',
  );
  const showIdentifiers = identifiers.filter(
    identifier => identifier.objectType !== 'Episode',
  );

  const [videoData, showData] = await Promise.all([
    batchRequestVideosFromIdentifiers(videoIdentifiers),
    batchRequestShowsFromIdentifiers(showIdentifiers),
  ]);

  return identifiers
    .map(identifier => {
      if (identifier.objectType === 'Episode') {
        return videoData.find(data => data.guid === identifier.guid);
      } else {
        return showData.find(data => data.showSlug === identifier.slug);
      }
    })
    .filter(value => value !== undefined) as (Show | Video)[];
};
