import { Colors, Lightning } from '@lightningjs/sdk';

export interface PinInputBoxTemplateSpec
  extends Lightning.Component.TemplateSpec {
  Box: Lightning.textures.RectangleTexture;
  ErrorHighlight: Lightning.textures.RectangleTexture;
  Dot: Lightning.textures.RectangleTexture;
  Pin: Lightning.textures.TextTexture;
  maskingChar: boolean;
  pinValue: string;
  hasError: boolean;
}

const BOX_SPAN = 133;
const DOT_DIAMETER = 24;

export default class PinInputBox
  extends Lightning.Component<PinInputBoxTemplateSpec>
  implements Lightning.Component.ImplementTemplateSpec<PinInputBoxTemplateSpec>
{
  maskingChar = true;

  private _Box = this.getByRef('Box')!;
  private _Dot = this.getByRef('Dot')!;
  private _Pin = this.getByRef('Pin')!;

  static get width() {
    return BOX_SPAN;
  }

  static get height() {
    return BOX_SPAN;
  }

  set pinValue(pinValue: string) {
    if (this.maskingChar && !!pinValue) {
      this._Dot.patch({ visible: !!pinValue });
      this._Pin.patch({ visible: false });
    } else {
      this._Dot.patch({ visible: false });
      this._Pin.patch({ visible: !!pinValue, text: { text: pinValue } });
    }
  }

  set hasError(hasError: boolean) {
    this._Box.patch({
      shader: { color: Colors(hasError ? 'error' : 'buttonInactive').get() },
    });
  }

  static override _template(): Lightning.Component.Template<PinInputBoxTemplateSpec> {
    return {
      Box: {
        rect: true,
        w: BOX_SPAN,
        h: BOX_SPAN,
        color: Colors('white').get(),
        zIndex: 2,
        shader: {
          type: Lightning.shaders.Outline,
          color: Colors('buttonInactive').get(),
          width: 2,
        },
      },
      Dot: {
        rect: true,
        visible: false,
        x: BOX_SPAN / 2,
        y: BOX_SPAN / 2,
        mount: 0.5,
        w: DOT_DIAMETER,
        h: DOT_DIAMETER,
        shader: {
          type: Lightning.shaders.RoundedRectangle,
          radius: DOT_DIAMETER / 2,
        },
        color: Colors('black').get(),
        zIndex: 3,
      },
      Pin: {
        visible: false,
        x: BOX_SPAN / 2,
        y: BOX_SPAN / 2 + 5,
        mount: 0.5,
        color: Colors('black').get(),
        text: {
          text: '',
          fontSize: 64,
        },
        zIndex: 3,
      },
    };
  }
}
