/* eslint-disable @typescript-eslint/no-empty-function */
import { Lightning, Colors, Router } from '@lightningjs/sdk';
import Request from '@lightningjs/sdk/src/Router/model/Request';
import { getFontFaceFromStyle } from 'support/textUtils';
import Button from 'components/common/Button';
import { translate } from 'support/translate';
import { closeApp } from 'support/appUtils';
import constants from '../../../static/constants.json';
import { getFocusDepth } from 'support/generalUtils';
import { RoutePath } from 'config/routes';

const TITLE_MARGIN_BOTTOM = 24;
const DESCRIPTION_MARGIN_BOTTOM = 90;

const TITLE_FONT_SIZE = 63;
const DESCRIPTION_FONT_SIZE = 42;

const MIN_BUTTON_WIDTH = 351;
const BUTTON_PADDING = 18;
const BUTTON_PADDINGH = 84;

export enum ErrorCause {
  CHANNEL = 'channel',
  CONFIGURATION = 'configuration',
  PLAYBACK = 'playback',
  SPLASH = 'splash',
  NAVIGATION = 'navigation',
  UNKNOWN = 'unknown',
  UNAVAILABLE = 'unavailable',
}

export type ErrorModalArgs = {
  cause?: ErrorCause;
  navigationRequest?: Request;
};

export interface ErrorModalTemplateSpec
  extends Lightning.Component.TemplateSpec {
  Title: Lightning.textures.TextTexture;
  Description: Lightning.textures.TextTexture;
  Button: typeof Button;
}

export default class ErrorModal
  extends Lightning.Component<ErrorModalTemplateSpec>
  implements Lightning.Component.ImplementTemplateSpec<ErrorModalTemplateSpec>
{
  private _Title = this.getByRef('Title')!;
  private _Description = this.getByRef('Description')!;
  private _Button = this.getByRef('Button')!;

  // Internal properties
  private static _cause: ErrorCause | undefined;
  private static _navigationRequest: Request | undefined;
  private static _retryCount = 0;

  get announce() {
    const title = this._Title.text?.text ?? '';
    const description = this._Description.text?.text ?? '';
    return [title, description];
  }

  static override _template(): Lightning.Component.Template<ErrorModalTemplateSpec> {
    return {
      alpha: 0,
      rect: true,
      w: 1920,
      h: 1080,
      color: Colors('modalBackground').get(),
      flex: {
        direction: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      },
      Title: {
        flexItem: { marginBottom: TITLE_MARGIN_BOTTOM },
        text: {
          fontSize: TITLE_FONT_SIZE,
          fontFace: getFontFaceFromStyle('bold'),
        },
      },
      Description: {
        flexItem: { marginBottom: DESCRIPTION_MARGIN_BOTTOM },
        text: { fontSize: DESCRIPTION_FONT_SIZE },
      },
      Button: {
        type: Button,
        minWidth: MIN_BUTTON_WIDTH,
        padding: BUTTON_PADDING,
        paddingH: BUTTON_PADDINGH,
        action: '$onButtonClicked',
        signals: { $onButtonClicked: '$onButtonClicked' },
        zIndex: 2,
      },
      zIndex: 2,
    };
  }

  override _focus() {
    this.alpha = 1;

    // Must fire announcer refresh or TTS doesn't run on ErrorModal re-focus
    if (ErrorModal._retryCount > 0) {
      this.fireAncestors(
        '$announcerRefresh',
        getFocusDepth(this.application, this),
      );
    }
  }

  override _unfocus() {
    this.alpha = 0;
    this._setState('');
  }

  override _getFocused() {
    return this._Button;
  }

  override _handleBack() {
    const history = Router.getHistory();
    if (history.length) {
      Router.back();
      Router.focusPage();
    } else {
      // Close the app if we can't go back
      closeApp();
    }
    return true;
  }

  override _handleKey() {
    // Do not allow the user to bypass the error modal
    return true;
  }

  update(args: ErrorModalArgs) {
    const { cause, navigationRequest } = args;
    let finalCause = ErrorCause.UNKNOWN;
    let isResolvable = false;

    if (navigationRequest) {
      // Assume it was a navigation error if we received a navigation request
      finalCause = cause ? cause : ErrorCause.NAVIGATION;
      isResolvable = true;
    } else if (cause) {
      finalCause = cause;

      if (cause === ErrorCause.SPLASH) {
        isResolvable = true;
      } else {
        // Fatal, error cause is:
        // - CHANNEL: Never resolvable
        // - PLAYBACK: Never resolvable
        // - CONFIGURATION: Missing navigation request
        // - NAVIGATION: Missing navigation request
        // - UNKNOWN: No leads
        isResolvable = false;
      }
    } else {
      // Neither cause or request reported, unable to do anything
    }

    this.updateRetryCount(finalCause, navigationRequest);
    ErrorModal._cause = finalCause;
    ErrorModal._navigationRequest = navigationRequest;

    // In case of multiple update() calls, we need to reset the state
    // so it can run $enter() again for Resolvable and Fatal
    this._setState('');
    if (isResolvable && this.canRetry()) {
      this._setState('Resolvable');
    } else {
      this._setState('Fatal');
    }
  }

  private updateRetryCount(cause: ErrorCause, navigationRequest?: Request) {
    if (
      ErrorModal._cause === cause &&
      ErrorModal._navigationRequest?.hash === navigationRequest?.hash
    ) {
      ErrorModal._retryCount += 1;
    } else {
      ErrorModal._retryCount = 0;
    }
  }

  private canRetry() {
    return ErrorModal._retryCount < constants.errors.maxRetry;
  }

  private retryNavigationRequest(navigationRequest: Request) {
    const { url, register } = navigationRequest;

    const params: Record<string, unknown> = {};
    register.forEach((value, key) => {
      params[key] = value;
    });

    Router.navigate(url, params);
    Router.reload();
    Router.focusPage();
  }

  private setErrorModalStrings(stringPath: string) {
    const title = translate(`${stringPath}.title`);
    const description = translate(`${stringPath}.description`);
    const button = translate(`${stringPath}.button`);

    this._Title.patch({ text: title });
    this._Description.patch({ visible: true, text: description });
    this._Button.patch({ label: button });
  }

  private setPlaybackErrorModalStrings() {
    const stringPath = 'error.fatal.playback';
    const title = translate(`${stringPath}.title`);
    const button = translate(`${stringPath}.button`);

    this._Title.patch({ text: title });
    // No description for playback error
    this._Description.patch({ visible: false });
    this._Button.patch({ label: button });
  }

  private setUnavailableErrorModalStrings() {
    const stringPath = 'error.fatal.unavailable';
    const title = translate(`${stringPath}.title`);
    const button = translate(`${stringPath}.button`);

    this._Title.patch({ text: title });
    // No description for unavailable error
    this._Description.patch({ visible: false });
    this._Button.patch({ label: button });
  }

  // Implemented in states
  $onButtonClicked() {}

  static override _states() {
    return [
      class Resolvable extends this {
        override $enter() {
          const cause = ErrorModal._cause;

          // Only resolvable error causes are CONFIGURATION, SPLASH, and NAVIGATION
          switch (cause) {
            case ErrorCause.CONFIGURATION:
            case ErrorCause.SPLASH:
              return this.setErrorModalStrings(
                'error.resolvable.configuration',
              );
            case ErrorCause.NAVIGATION:
              return this.setErrorModalStrings('error.resolvable.navigation');
            default:
              return this._setState('Fatal');
          }
        }

        override $onButtonClicked() {
          const navigationRequest = ErrorModal._navigationRequest;
          if (navigationRequest) {
            this.retryNavigationRequest(navigationRequest);
          } else {
            // Assume there was an error in the splash page
            Router.navigate(RoutePath.SPLASH);
            Router.reload();
            Router.focusPage();
          }
        }
      },
      class Fatal extends this {
        override $enter() {
          const cause = ErrorModal._cause;
          switch (cause) {
            case ErrorCause.PLAYBACK:
              return this._setState('Fatal.Playback');
            case ErrorCause.CHANNEL:
              return this._setState('Fatal.Channel');
            case ErrorCause.UNAVAILABLE:
              return this._setState('Fatal.Unavailable');
            default:
              return this._setState('Fatal.Generic');
          }
        }

        static override _states() {
          return [
            // Channel errors are a special case: EPG page should have its own
            // handler for errors since we don't want to leave the page when a
            // channel's playback fails
            class Channel extends this {
              override $enter() {
                this.setPlaybackErrorModalStrings();
              }

              override _handleBack() {
                Router.focusPage();
                return true;
              }

              override $onButtonClicked() {
                Router.focusPage();
              }
            },
            class Playback extends this {
              override $enter() {
                this.setPlaybackErrorModalStrings();
              }

              override $onButtonClicked() {
                this._handleBack();
              }
            },

            class Unavailable extends this {
              override $enter() {
                this.setUnavailableErrorModalStrings();
              }

              override $onButtonClicked() {
                this._handleBack();
              }
            },

            class Generic extends this {
              override $enter() {
                this.setErrorModalStrings('error.fatal.generic');
              }

              override _handleBack() {
                // Unable to leave by pressing back
                return true;
              }

              override $onButtonClicked() {
                closeApp();
              }
            },
          ];
        }
      },
    ];
  }
}
