import { Promo } from 'services/cwData';
import { LiveEventChannel, Video } from '../../types/api/media';
import {
  CategoryEngagementProps,
  SelectItemProps,
  SelectPromoProps,
} from './MParticle';

export const reportNavBarNavigation = (title: string) => {
  window.analytics.mParticle.reportNavBarNavigation(title);
};

/**
 * Reports show page engagement for series and live events.
 *
 * @param mediaItem The item selected
 * @param season `season` for `Video`, `slug` for `LiveEventChannel`
 * @param imageSrc Image source displayed on selected item
 * @param episodeIndex 0-indexed episode index for `Video`, -1 for `LiveEventChannel`
 * @param seasonIndex 0-indexed season index for `Video`, -1 for `LiveEventChannel`
 */
export const reportShowPageEngagement = (
  mediaItem: Video | LiveEventChannel,
  season: string,
  imageSrc: string,
  episodeIndex: number,
  seasonIndex: number,
) => {
  window.analytics.mParticle.reportShowPageEngagement(
    mediaItem,
    season,
    imageSrc,
    episodeIndex,
    seasonIndex,
  );
};

export const reportScrollTracking = (quartile: number) => {
  window.analytics.mParticle.reportScrollTracking(quartile);
};

export const reportViewPromotion = (props: { promo: Promo; index: number }) => {
  window.analytics.mParticle.reportViewPromotion(props);
};

export const reportPromoSelected = (props: SelectPromoProps) => {
  window.analytics.mParticle.reportPromoSelected(props);
};

export const reportItemSelected = (props: SelectItemProps) => {
  window.analytics.mParticle.reportItemSelected(props);
};

export const reportCategoryEngagement = (props: CategoryEngagementProps) => {
  window.analytics.mParticle.reportCategoryEngagement(props);
};
