import { AppData as LightningAppData } from '@lightningjs/sdk';
import { TizenDeviceIntegration } from 'config/platforms/tizen/TizenDeviceIntegration';
import { UnknownDeviceIntegration } from 'config/platforms/unknown/UnknownDeviceIntegration';

import constants from '../../static/constants.json';

/*
This is set by the rollup-virtual plugin during the "lng build" command
implementation of this can be found in: https://github.com/rdkcentral/Lightning-CLI/blob/master/src/configs/rollup.es6.config.js
related discussion: https://forum.lightningjs.io/t/accessing-environment-variables
*/
// @ts-ignore
import processEnv from 'processEnv';
import { Platform } from 'models/platforms/platform';
import PrivacyService from 'services/PrivacyService';
import RecentSearchService from 'services/RecentSearchService';
import StorageService from 'services/StorageService';
import { VizioDeviceIntegration } from './platforms/vizio/VizioDeviceIntegration';
import { LGDeviceIntegration } from './platforms/lg/LGDeviceIntegration';
import MigrationService from 'services/MigrationService';
import TizenHistoryService from 'services/history/TizenHistoryService';
import DefaultHistoryService from 'services/history/DefaultHistoryService';
import ParentalPinService from 'services/ParentalPinService';

export class AppData implements LightningAppData {
  readonly isProduction: LightningAppData['isProduction'];
  readonly useCacheBackup: LightningAppData['useCacheBackup'];
  readonly privacyModifiedDate: LightningAppData['privacyModifiedDate'];
  readonly device: LightningAppData['device'];
  readonly migrationService: LightningAppData['migrationService'];
  readonly storageService: LightningAppData['storageService'];
  readonly historyService: LightningAppData['historyService'];
  readonly recentSearchesService: LightningAppData['recentSearchesService'];
  readonly privacyService: LightningAppData['privacyService'];
  readonly announcerService: LightningAppData['announcerService'];
  readonly api: LightningAppData['api'];
  readonly images: LightningAppData['images'];
  readonly google: LightningAppData['google'];
  readonly comcast: LightningAppData['comcast'];
  readonly widevine: LightningAppData['widevine'];
  readonly conviva: LightningAppData['conviva'];
  readonly comscore: LightningAppData['comscore'];
  readonly nielsen: LightningAppData['nielsen'];
  readonly mParticle: LightningAppData['mParticle'];
  readonly permutive: LightningAppData['permutive'];
  readonly parentalPinService: LightningAppData['parentalPinService'];

  readonly timers: LightningAppData['timers'];

  tivoEndpoints: LightningAppData['tivoEndpoints'];
  ayswIdleTimes: LightningAppData['ayswIdleTimes'];
  isDeepLinkEntry: LightningAppData['isDeepLinkEntry'];

  constructor() {
    this.isProduction = processEnv.NODE_ENV === 'prod';
    this.isDeepLinkEntry = false;
    this.useCacheBackup =
      !this.isProduction && processEnv.APP_USE_CACHE_BACKUP === 'true';
    this.privacyModifiedDate = '';

    const platform: Platform = processEnv.APP_PLATFORM;
    switch (platform) {
      case Platform.TIZEN:
        this.device = new TizenDeviceIntegration();
        this.historyService = new TizenHistoryService(
          this.device as TizenDeviceIntegration,
        );
        break;
      case Platform.VIZIO:
        this.device = new VizioDeviceIntegration();
        this.historyService = new DefaultHistoryService();
        break;
      case Platform.LG:
        this.device = new LGDeviceIntegration();
        this.historyService = new DefaultHistoryService();
        break;
      case Platform.UNKNOWN:
      default:
        this.device = new UnknownDeviceIntegration();
        this.historyService = new DefaultHistoryService();
    }

    this.migrationService = new MigrationService(this.device);
    this.storageService = new StorageService(this.device);
    this.parentalPinService = new ParentalPinService({
      device: this.device,
      storageService: this.storageService,
    });

    this.recentSearchesService = new RecentSearchService();
    this.privacyService = new PrivacyService();

    // These values will be set by the Announcer class
    this.announcerService = {
      getIsSpeaking: null,
      setOnSpeakEvent: null,
      removeOnSpeakEvent: null,
    } as any;

    this.api = {
      baseUrl: processEnv.APP_API_BASE_URL,
      device: processEnv.APP_API_DEVICE_PARAMETER,
      username: processEnv.APP_API_USER,
      password: processEnv.APP_API_PASS,
    };

    this.images = {
      baseUrl: processEnv.APP_IMAGES_DEV_BASE_URL,
    };

    this.google = {
      apiKey: processEnv.APP_IMA_API_KEY,
      idtype: processEnv.APP_GOOGLE_ID_TYPE,
      iu: {
        ...constants.google.iu,
        platform: processEnv.APP_GOOGLE_IU_PLATFORM,
      },
      time: constants.google.time,
      daiSr: constants.google.daiSr,
      descriptionUrl: constants.google.descriptionUrl,
    };

    this.comcast = {
      signinUrl: processEnv.APP_COMCAST_SIGNIN_URL,
      credentials: processEnv.APP_COMCAST_CREDENTIALS,
    };

    this.widevine = {
      licenseUrl: processEnv.APP_WIDEVINE_LICENSE_URL,
      licenseAccountUrl: processEnv.APP_WIDEVINE_LICENSE_ACCOUNT_PARAM_BASE_URL,
    };

    this.timers = {
      seriesExpiryDayLimit: constants.timers.seriesExpiryDayLimit,
      movieExpiryDayLimit: constants.timers.movieExpiryDayLimit,
    };

    this.conviva = {
      customerKey: processEnv.APP_CONVIVA_KEY,
      gatewayUrl: this.isProduction
        ? undefined
        : processEnv.APP_CONVIVA_GATEWAY_URL,
      mediaFileApiFramework: constants.conviva.mediaFileApiFramework,
      playerName: processEnv.APP_CONVIVA_PLAYER_NAME,
      technology: constants.conviva.technology,
      adStitcher: constants.conviva.adStitcher,
      brand: constants.conviva.brand,
    };
    this.comscore = {
      clientId: processEnv.APP_COMSCORE_CLIENT_ID,
      publisherId: processEnv.APP_COMSCORE_PUBLISHER_ID,
      publisherSecret: processEnv.APP_COMSCORE_PUBLISHER_SECRET,
      appName: constants.comscore.appName,
      brandName: constants.comscore.brandName,
      stationTitle: constants.comscore.stationTitle,
      videoMetrixC3: constants.comscore.videoMetrixC3,
    };
    this.nielsen = {
      appId: processEnv.APP_NIELSEN_APP_ID,
      instanceName: constants.nielsen.instanceName,
      baseUrl: processEnv.APP_NIELSEN_BASE_URL,
    };
    this.mParticle = {
      key: processEnv.APP_MPARTICLE_KEY,
    };

    this.permutive = {
      organizationId: processEnv.APP_PERMUTIVE_ORGANIZATION_ID,
      workspaceId: processEnv.APP_PERMUTIVE_WORKSPACE_ID,
      apiKey: processEnv.APP_PERMUTIVE_WORKSPACE_API_KEY,
      domain: processEnv.APP_PERMUTIVE_DOMAIN,
    };
  }
}
