import { Colors, Lightning } from '@lightningjs/sdk';
import Tile, { TileTemplateSpec } from './Tile';
import { LiveEventOverlays, LiveEvent } from 'types/api/media';
import { getFontFaceFromStyle } from 'support/textUtils';
import LiveEventOverlay from '../LiveEventOverlay';

export interface LiveEventTileTemplateSpec extends TileTemplateSpec {
  data: LiveEvent;

  Overlay: typeof LiveEventOverlay;
  TextWrapper: {
    Title: Lightning.textures.TextTexture;
    SubTitle: Lightning.textures.TextTexture;
  };
}

const TILE_WIDTH = 400;
const TILE_HEIGHT = 297;

const TILE_HIGHLIGHT_WIDTH = 400;
const TILE_HIGHLIGHT_HEIGHT = 225;

const TEXT_WRAPPER_HEIGHT = 57;
const TEXT_WRAPPER_Y = 240;

const EVENT_TEXT_FONT_SIZE = 23;

const OVERLAY_SPACING = 4;

export default class LiveEventTile extends Tile<LiveEventTileTemplateSpec> {
  protected _TextWrapper = this.getByRef('TextWrapper')!;
  protected _Title = this._TextWrapper.getByRef('Title')!;
  protected _SubTitle = this._TextWrapper.getByRef('SubTitle')!;

  protected _Overlay = this.getByRef('Overlay')!;
  protected _LiveIcon = this._Overlay.getByRef('LiveIcon')!;

  override get title() {
    const overlayStatus = this._Overlay.title;
    const title = this._Title.text?.text ?? '';
    const subtitle = this._SubTitle.text?.text ?? '';

    return [overlayStatus, title, subtitle];
  }

  override set data(data: LiveEvent) {
    super.data = data;

    this._Title.patch({ text: { text: data.title } });
    this._SubTitle.patch({ text: { text: data.tuneIn } });

    this.updateEventStatus();
    this.updateOverlay();
  }

  static override get width() {
    return TILE_WIDTH;
  }

  static override get height() {
    // Value will need to be larger to account for text underneath
    return TILE_HEIGHT;
  }

  static override get highlightWidth() {
    return TILE_HIGHLIGHT_WIDTH;
  }

  static override get highlightHeight() {
    return TILE_HIGHLIGHT_HEIGHT;
  }

  static override _template() {
    return {
      ...super._template(),
      Overlay: {
        type: LiveEventOverlay,
        mountX: 1,
        x: (w: number) => w - OVERLAY_SPACING,
        y: OVERLAY_SPACING,
      },
      TextWrapper: {
        y: TEXT_WRAPPER_Y,
        w: TILE_WIDTH,
        h: TEXT_WRAPPER_HEIGHT,
        Title: {
          text: {
            fontSize: EVENT_TEXT_FONT_SIZE,
            maxLines: 1,
            textColor: Colors('text').get(),
            fontFace: getFontFaceFromStyle('bold'),
            wordWrapWidth: TILE_WIDTH,
          },
        },
        SubTitle: {
          y: 30,
          text: {
            fontSize: EVENT_TEXT_FONT_SIZE,
            maxLines: 1,
            textColor: Colors('text').get(),
            fontFace: getFontFaceFromStyle('light'),
            wordWrapWidth: TILE_WIDTH,
          },
        },
      },
    };
  }

  private updateEventStatus() {
    const currentDate = new Date();
    const streamStartDate = new Date(this._data.startDateStream);
    const streamEndDate = new Date(this._data.endDateStream);

    let eventStatus: keyof LiveEventOverlays;
    if (currentDate <= streamStartDate) {
      eventStatus = 'upcoming';
    } else if (streamStartDate <= currentDate && currentDate <= streamEndDate) {
      eventStatus = 'live';
    } else {
      eventStatus = 'ended';
    }

    this._Overlay.patch({ eventStatus });
  }

  private updateOverlay() {
    const overlays = this._data.overlays;
    this._Overlay.patch({ overlays });
  }
}
