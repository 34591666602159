import { Lightning } from '@lightningjs/sdk';
import { LiveEventOverlays } from 'types/api/media';
import {
  convertHexColor,
  getImageTextureObj,
  separateHexColorAlpha,
} from 'support/generalUtils';

export interface LiveEventOverlayTemplateSpec
  extends Lightning.Component.TemplateSpec {
  eventStatus: keyof LiveEventOverlays;
  overlays: LiveEventOverlays | null;

  LiveIcon: Lightning.textures.ImageTexture;
}

const OVERLAY_HEIGHT = 31;
const OVERLAY_TEXT_HEIGHT = 23;
const OVERLAY_FONT_SIZE = 20;

const OVERLAY_ICON_WIDTH = 20;
const OVERLAY_ICON_HEIGHT = 20;
const OVERLAY_ICON_SPACING = 5;

const OVERLAY_PADDING = 10;
const OVERLAY_PADDING_LEFT_WITH_ICON = OVERLAY_PADDING * 2 + OVERLAY_ICON_WIDTH;

const liveIconTexture = getImageTextureObj(
  'static/images/navigation/live-icon-inactive.svg',
  OVERLAY_ICON_WIDTH,
  OVERLAY_ICON_HEIGHT,
);

export default class LiveEventOverlay
  extends Lightning.Component<LiveEventOverlayTemplateSpec>
  implements
    Lightning.Component.ImplementTemplateSpec<LiveEventOverlayTemplateSpec>
{
  private _LiveIcon = this.getByRef('LiveIcon')!;

  private _eventStatus: keyof LiveEventOverlays = 'upcoming';
  private _overlays: LiveEventOverlays | null = null;

  get title() {
    return this.text?.text ?? '';
  }

  set eventStatus(eventStatus: keyof LiveEventOverlays) {
    this._eventStatus = eventStatus;
    this.updateEventStatus();
  }

  set overlays(overlays: LiveEventOverlays | null) {
    this._overlays = overlays;
    this.updateEventStatus();
  }

  static override _template(): Lightning.Component.Template<LiveEventOverlayTemplateSpec> {
    return {
      h: OVERLAY_HEIGHT,
      LiveIcon: {
        ...liveIconTexture,
        x: OVERLAY_PADDING,
        y: OVERLAY_ICON_SPACING,
        visible: false,
      },
      text: {
        lineHeight: OVERLAY_TEXT_HEIGHT,
        offsetY: OVERLAY_TEXT_HEIGHT,
        fontSize: OVERLAY_FONT_SIZE,
        highlight: true,
        highlightHeight: OVERLAY_HEIGHT,
        highlightPaddingLeft: OVERLAY_PADDING,
        highlightPaddingRight: OVERLAY_PADDING,
        paddingLeft: OVERLAY_PADDING,
        paddingRight: OVERLAY_PADDING,
      },
    };
  }

  private updateEventStatus() {
    if (!this._overlays) return;
    const eventStatus = this._eventStatus;
    const overlay = this._overlays[eventStatus];

    const isLive = eventStatus === 'live';
    const textColor = separateHexColorAlpha(overlay.fg_color);
    const highlightColor = separateHexColorAlpha(overlay.bg_color);

    this._LiveIcon.visible = isLive;
    const paddingLeft = isLive
      ? OVERLAY_PADDING_LEFT_WITH_ICON
      : OVERLAY_PADDING;

    this.patch({
      text: {
        text: overlay.title,
        highlightPaddingLeft: paddingLeft,
        paddingLeft: paddingLeft,
        ...(textColor && { textColor: convertHexColor(textColor.color) }),
        ...(highlightColor && {
          highlightColor: convertHexColor(highlightColor.color),
        }),
      },
    });
  }
}
