import { Lightning } from '@lightningjs/sdk';
import SizedRowList, {
  ListTypes,
  SizedRowListTemplateSpec,
} from 'components/common/SizedRowList';
import { LaneItem } from 'services/cwData';
import {
  ChannelImages,
  LiveEventImages,
  PlayableMediaImages,
} from 'types/api/media';
import { LightningBuilder } from 'types/lightning';
import { Merge } from 'types';
import { TileTemplateSpec } from 'components/common/tiles/Tile';
import { FeaturedTileTemplateSpec } from 'components/common/tiles/FeaturedTile';
import { TileParentContext } from './ContentHubPage';

interface SwimlaneRowListTemplateSpec extends SizedRowListTemplateSpec {
  laneIndex: number | null;
  imageKey:
    | keyof PlayableMediaImages
    | keyof ChannelImages
    | keyof LiveEventImages
    | null;
  expandedImageKey?: keyof PlayableMediaImages;
  laneItems: LaneItem[];
}

export class SwimlaneRow
  extends SizedRowList
  implements
    Lightning.Component.ImplementTemplateSpec<SwimlaneRowListTemplateSpec>
{
  laneIndex: number | null = null;
  imageKey:
    | keyof PlayableMediaImages
    | keyof ChannelImages
    | keyof LiveEventImages
    | null = null;
  expandedImageKey?: keyof PlayableMediaImages;

  set laneItems(items: LaneItem[]) {
    const laneItems = this.buildListItems(items);
    super.items = laneItems;
  }

  private buildListItems(items: Array<LaneItem>) {
    if (!this.imageKey) return [];

    return items.map((item, index) => {
      const parentContext: TileParentContext = {
        crossIndex: index,
        mainIndex: this.laneIndex!,
        rowTitle: this.label ?? '',
        isTivoSource: item.contextType === 'tivo-api', // if null we default to false
      };

      const result: LightningBuilder<
        ListTypes,
        Merge<TileTemplateSpec, FeaturedTileTemplateSpec>
      > = {
        data: item,
        notifyWhenFullyVisible: true,
        parentContext,
      };

      if (item.images && this.imageKey! in item.images)
        result.imageUrl =
          item.images[this.imageKey as keyof typeof item.images];

      if (
        item.images &&
        this.expandedImageKey &&
        this.expandedImageKey in item.images
      )
        result.expandedImageUrl =
          item.images[this.expandedImageKey as keyof typeof item.images];

      return result;
    });
  }
}
