import { AppData, Router } from '@lightningjs/sdk';
import MovieDetailsPage from '../components/pages/movie-details/MovieDetailsPage';
import SeriesDetailsPage from '../components/pages/series-details/SeriesDetailsPage';
import SplashPage from '../components/pages/splash/SplashPage';
import PlaybackPage from '../components/pages/playback/PlaybackPage';
import LivePage from 'components/pages/live/LivePage';
import EpgPage from 'components/pages/epg/EpgPage';
import SettingsPage from 'components/pages/settings/SettingsPage';
import ErrorPage from '../components/pages/error/ErrorPage';
import SearchPage from '../components/pages/search/SearchPage';
import ConvivaAnalytics from 'services/analytics/Conviva';
import {
  getHomePageSwimlanes,
  getShowByShowSlug,
  getEpisodeListByShowSlug,
  getExtrasListByShowSlug,
  getVideo,
  initializeCacheTimeouts,
  getLiveStream,
  useCacheBackup,
  getEpgPage,
  getContentHub,
  getConfig,
  getEpgChannelBySlug,
  getLiveEvent,
} from 'services/cwService';
import { getDrmData } from 'services/drmService';
import ComscoreAnalytics from 'services/analytics/Comscore';
import { StaticViewContexts, ViewContext } from 'types/analytics';
import { loadImaDaiScript } from '../libraries/googleImaDai';
import NielsenAnalytics from 'services/analytics/Nielsen';
import MParticleAnalytics from 'services/analytics/MParticle';
import { PageId } from 'types/pageId';
import {
  getMediaDataFromIdentifiers,
  getRecommended,
  getTrending,
  getEndCardRecommended,
} from 'services/tivoService';
import {
  Live,
  MediaIdentifier,
  Show,
  ShowVideos,
  Video,
} from 'types/api/media';
import PermutiveAnalytics from 'services/analytics/Permutive';
import { cleanupEpgChannelData } from 'services/cwData';
import ContentHubPage from 'components/pages/content-hub/ContentHubPage';
import BackgroundingService from 'services/BackgroundingService';
import constants from '../../static/constants.json';
import { getPageIdFromHash, getContentWrapper } from 'support/routerUtils';
import { ErrorCause } from 'components/common/ErrorModal';
import { ListItemContext } from '../types/events';
import CollectionVisibilityService from 'services/CollectionVisibilityService';
import { ContentHubSlugs } from 'types/contentHubs';
import LiveEventDetailsPage from 'components/pages/live-event-details/LiveEventDetailsPage';
import AppLaunch from 'components/widgets/AppLaunch';

export enum RoutePath {
  SPLASH = '$', // do not change this value, special path (see: https://lightningjs.io/docs/#/lightning-sdk-reference/plugins/router/configuration?id=bootpage)
  SEARCH = 'search',
  LIVE = 'live',
  EPG = 'channels',
  SETTINGS = 'settings',
  MOVIE_DETAIL = 'movie-detail',
  SERIES_DETAIL = 'series-detail',
  LIVE_EVENT_DETAIL = 'live-event-detail',
  PLAY = 'play',
  CONTENT_HUB = 'content-hub',
  ERROR = '!', // Special error path (Loads any time navigation hooks fail)
}

export enum FromRoute {
  NAVBAR = 'NavBar',
}

const defaultWidgets: Array<keyof Router.Widgets> = [
  'AppLaunch',
  'EnterParentalPin',
  'ResetParentalPin',
  'SetParentalPin',
];

const routes = [
  {
    // on, before, or after functions don't work for the boot page
    path: RoutePath.SPLASH,
    widgets: ['ErrorModal'],
    component: SplashPage,
    options: {
      preventStorage: true,
      reuseInstance: false,
    },
  },
  {
    path: RoutePath.SEARCH,
    component: SearchPage,
    widgets: [...defaultWidgets, 'NavBar', 'ExitModal'],
    on: async (page: SearchPage, params: Router.PageParams) => {
      const trendingHits = await getTrending();
      const trendingData = await getMediaDataFromIdentifiers(trendingHits);
      const { from } = params;
      const reset = from === FromRoute.NAVBAR;

      page.pageData = { trendingData, reset };
    },
  },
  {
    path: RoutePath.LIVE,
    component: LivePage,
    widgets: [...defaultWidgets, 'NavBar', 'ExitModal'],
    on: async (page: LivePage) => {
      const liveStream = await getContentWrapper(() => getLiveStream());
      if (!liveStream) return Promise.reject('Live: Missing livestream');
      let recommendations: ShowVideos | undefined;

      try {
        const showSlug = liveStream.analytics.slug;
        if (showSlug) {
          const showData = await getShowByShowSlug(showSlug);
          if (showData && showData.seasons.length > 0) {
            const firstSeason = showData.seasons[0]!;
            recommendations = await getEpisodeListByShowSlug(
              showSlug,
              firstSeason,
            );
          }
        }
      } catch (e) {
        // Ignore error, will only show billboard
        console.warn(e);
      }

      page.pageData = { liveStream, recommendations };
    },
  },
  {
    path: `${RoutePath.EPG}/:channel?`,
    component: EpgPage,
    widgets: [...defaultWidgets, 'NavBar', 'ExitModal', 'ErrorModal', 'Modal'],
    options: {
      reuseInstance: false,
    },
    on: async (
      page: EpgPage,
      routes: { channel?: string; viewContext?: ViewContext },
    ) => {
      // Get first EPG page
      const pageSize = constants.epg.pageSize;
      const epgPageData = await getContentWrapper(() =>
        getEpgPage(1, pageSize),
      );
      if (!epgPageData) return;

      // Keep track of channel added to first EPG page
      const { channel: selectedChannelSlug } = routes;
      let addedChannelSlug: string | undefined;

      if (selectedChannelSlug) {
        // Check if selected channel is in the first page
        const selectedChannelIndex = epgPageData.channels.findIndex(
          channel => channel.slug === selectedChannelSlug,
        );

        if (selectedChannelIndex === -1) {
          // Selected channel is not available in the first page: Fetch it
          try {
            const epgChannel = await getEpgChannelBySlug(selectedChannelSlug);
            const channel = epgChannel.channels[0]!;
            epgPageData.channels.unshift(channel);

            addedChannelSlug = selectedChannelSlug;
          } catch (e) {
            // Ignore error, won't show selected channel if not found
            console.warn(e);
          }
        } else {
          // Selected channel is in the first page: Swap it with the first channel
          const selectedChannel = epgPageData.channels[selectedChannelIndex]!;
          epgPageData.channels[selectedChannelIndex] = epgPageData.channels[0]!;
          epgPageData.channels[0] = selectedChannel;
        }
      }

      // Note that we can provide the current date since the cleanup function
      // will convert it to a 30 minute interval for us
      const channels = cleanupEpgChannelData(epgPageData.channels, new Date());
      const maxChannels = epgPageData.maxChannels;

      let viewContext: ViewContext;
      if (selectedChannelSlug && routes.viewContext) {
        viewContext = routes.viewContext;
      } else if (selectedChannelSlug) {
        viewContext = StaticViewContexts.DEEPLINK;
      } else {
        viewContext = StaticViewContexts.EPG;
      }

      page.pageData = {
        channels,
        maxChannels,
        addedChannelSlug,
        viewContext,
      };
    },
  },
  {
    path: RoutePath.SETTINGS,
    component: SettingsPage,
    widgets: [...defaultWidgets, 'NavBar', 'ExitModal'],
  },
  {
    path: `${RoutePath.MOVIE_DETAIL}/:id`,
    component: MovieDetailsPage,
    widgets: [...defaultWidgets],
    on: async (
      page: MovieDetailsPage,
      routes: {
        id: string;
        listItemContext: ListItemContext;
        fromPageId: PageId;
      },
    ) => {
      const slug = routes.id;
      const show = await getContentWrapper(() => getShowByShowSlug(slug));
      if (!show) return Promise.reject('Movie Detais: Missing movie');

      const { listItemContext, fromPageId } = routes;
      let recommended: Show[] = [];

      try {
        const recommendedIds = await getRecommended(
          show.firstEpisode?.guid ?? '',
        );

        // Recommended data returned by CW should only contain shows
        recommended = (await getMediaDataFromIdentifiers(
          recommendedIds,
        )) as Show[];
      } catch (e) {
        // Continue to movie details w/o recommendations
        console.warn(e);
      }

      const pageData = {
        show,
        recommended,
        listItemContext: listItemContext,
        fromPageId: fromPageId ?? PageId.UNKNOWN,
      };

      page.pageData = pageData;
    },
  },
  {
    path: `${RoutePath.SERIES_DETAIL}/:id/:season?`,
    component: SeriesDetailsPage,
    widgets: [...defaultWidgets],
    on: async (
      page: SeriesDetailsPage,
      routes: {
        id: string;
        season: string;
        listItemContext: ListItemContext;
        fromPageId: PageId;
      },
    ) => {
      const slug = routes.id;
      const show = await getContentWrapper(() => getShowByShowSlug(slug));
      if (!show) return Promise.reject('Series Detail: Missing series');

      const { listItemContext, fromPageId, season } = routes;
      const { seasons, id } = show;

      const episodesListPromises = seasons.map(season =>
        getEpisodeListByShowSlug(id, season),
      );
      const episodes = await Promise.all(episodesListPromises);
      episodes.forEach(episode => {
        if (episode.season) {
          episode.season.episodeCount = episode.items.length;
        }
      });

      const extras = await getContentWrapper(
        () => getExtrasListByShowSlug(id),
        false,
        false,
      );

      const pageData = {
        show,
        episodes,
        extras,
        season,
        listItemContext: listItemContext,
        fromPageId: fromPageId ?? PageId.UNKNOWN,
      };

      page.pageData = pageData;
    },
  },
  {
    path: `${RoutePath.LIVE_EVENT_DETAIL}/:id`,
    component: LiveEventDetailsPage,
    widgets: ['AppLaunch'],
    on: async (page: LiveEventDetailsPage, routes: { id: string }) => {
      const guid = routes.id;
      const liveEvent = await getContentWrapper(() => getLiveEvent(guid));
      if (!liveEvent)
        return Promise.reject('Live Event Detail: Missing live event');

      // TODO
    },
  },
  {
    path: `${RoutePath.PLAY}/:contentType/:guid?`,
    component: PlaybackPage,
    widgets: [...defaultWidgets, 'Modal', 'ErrorModal'],
    options: {
      preventStorage: true,
    },
    on: async (
      page: PlaybackPage,
      routes: {
        contentType: 'show' | 'live';
        guid: string | undefined;
        viewContext: ViewContext;
        listItemContext: ListItemContext;
        fromPageId: PageId;
      },
    ) => {
      const { contentType, guid } = routes;
      let upNext: Video[] = [];
      let mediaContent: Video | Live | undefined | null;
      let hasDrm: boolean;
      let drmLicenseUrl: string | undefined;
      let progress: number;

      if (contentType === 'show') {
        if (!guid) return Promise.reject('Playback: Cannot play missing show');

        mediaContent = await getContentWrapper(() => getVideo(guid));
        if (!mediaContent)
          return Promise.reject('Playback: Cannot play missing show');

        const drmData = await getDrmData(mediaContent.mpxUrl);
        hasDrm = !!drmData?.hasDrm;
        drmLicenseUrl = drmData?.licenseUrl;

        progress =
          AppData?.historyService.getVideoProgress(
            mediaContent.showSlug,
            guid,
          ) ?? 0;
        try {
          const recommendedList: MediaIdentifier[] =
            await getEndCardRecommended(guid);
          // pull the first 3. the first is the same as nextEpisode
          const recommendedIds = recommendedList.splice(0, 3);
          upNext = (await getMediaDataFromIdentifiers(
            recommendedIds,
          )) as Video[];
        } catch (e) {
          // upNext and recommended should be undefined and empty. do not display EndCard
          console.warn(e);
        }
      } else if (contentType === 'live') {
        mediaContent = await getContentWrapper(() => getLiveStream(guid));
        if (!mediaContent)
          return Promise.reject('Playback: Cannot play missing live');

        hasDrm = false;
        progress = 0;
      } else {
        return;
      }

      const contentSourceId = AppData?.google.contentSourceId;
      if (!contentSourceId) return;

      const pageData = {
        mediaContent,
        contentSourceId,
        hasDrm,
        drmLicenseUrl,
        progress,
        upNext,
        viewContext: routes.viewContext ?? StaticViewContexts.DEEPLINK,
        listItemContext: routes.listItemContext,
        fromPageId: routes.fromPageId ?? PageId.UNKNOWN,
      };
      page.pageData = pageData;
      return;
    },
  },
  {
    path: `${RoutePath.CONTENT_HUB}/:hubSlug/:nested?`,
    component: ContentHubPage,
    widgets: [...defaultWidgets, 'NavBar', 'ExitModal', 'ErrorModal'],
    on: async (
      page: ContentHubPage,
      routes: { hubSlug: string; nested?: boolean },
    ) => {
      const { hubSlug } = routes;
      const swimlanes = await getContentWrapper(() => getContentHub(hubSlug));
      if (!swimlanes) return Promise.reject('ContentHub: Missing swimlanes');

      // TODO EL-739: Remove workaround when swimlanes always provide title value
      if (!swimlanes.title) {
        const navItem = AppData?.navigationItems?.find(
          item => item.slug === hubSlug,
        );
        swimlanes.title = navItem?.title ?? '';
      }

      window.analytics.permutive.reportPageChange(swimlanes.title);
      page.pageData = {
        swimlanes,
        template: swimlanes.layout || 'carousels',
      };
    },
  },
  {
    path: RoutePath.ERROR,
    component: ErrorPage,
    widgets: ['ErrorModal'],
    options: { preventStorage: true },
  },
];

export default {
  boot: async () => {
    if (AppData?.useCacheBackup) {
      // Set up cache backup when we want to use internal cache for testing
      await useCacheBackup();
    }

    window.analytics = {} as any;

    const analyticsInitPromise = (async () => {
      window.backgroundingService = new BackgroundingService();
      window.collectionVisibilityService = new CollectionVisibilityService();

      const loadDai = loadImaDaiScript();

      await AppData!.device.load.bind(AppData!.device)();

      window.analytics.mParticle = new MParticleAnalytics();

      const [permutive, conviva] = await Promise.all([
        PermutiveAnalytics.AsyncConstruct(),
        ConvivaAnalytics.AsyncConstruct(),
        loadDai,
      ]);

      window.analytics.permutive = permutive;
      window.analytics.conviva = conviva;
      window.analytics.comscore = new ComscoreAnalytics();
      window.analytics.nielsen = new NielsenAnalytics();

      AppData!.privacyService.initPrivacyStatus();
    })();

    window.analytics.initializationPromise = analyticsInitPromise;

    const migrationService = AppData!.migrationService;

    const shouldMigrateFromPreviousApp =
      migrationService.shouldMigratePreviousAppStorage();
    const migratePreviousAppStorageFn =
      migrationService.migratePreviousAppStorage.bind(migrationService);

    const shouldMigrateFromPreviousVersion =
      migrationService.shouldMigratePreviousVersionStorage();
    const migratePreviousVersionStorageFn =
      migrationService.migratePreviousVersionStorage.bind(migrationService);

    const historyService = AppData!.historyService;
    const shouldUpdateHistoryOnBoot =
      historyService.shouldUpdateHistoryOnBoot();
    const updateHistoryOnBootFn =
      historyService.updateHistoryOnBoot.bind(historyService);

    // Array of requests that need to be completed before the splash page finishes.
    // Note that we don't have to explicitly pass the results to the next page since
    // results get cached.
    const requestFunctions: (() => Promise<unknown>)[] = [];
    requestFunctions.push(initializeCacheTimeouts);

    if (shouldMigrateFromPreviousApp)
      requestFunctions.push(migratePreviousAppStorageFn);
    if (shouldMigrateFromPreviousVersion)
      requestFunctions.push(migratePreviousVersionStorageFn);
    if (shouldUpdateHistoryOnBoot) requestFunctions.push(updateHistoryOnBootFn);

    requestFunctions.push(getHomePageSwimlanes);

    SplashPage.requestFunctions = requestFunctions;
  },
  root: `${RoutePath.CONTENT_HUB}/${ContentHubSlugs.HOME}`,
  beforeEachRoute: async (fromHash: string | undefined, toRequest: any) => {
    try {
      const pageId: string = getPageIdFromHash(toRequest.hash);

      if (pageId !== PageId.SPLASH && pageId !== PageId.ERROR) {
        await window.analytics.initializationPromise;

        await getConfig();
      }
    } catch (e) {
      // We can return an object with path and params for navigation
      return {
        path: RoutePath.ERROR,
        params: {
          cause: ErrorCause.CONFIGURATION,
          request: toRequest,
        },
      };
    }
    return true;
  },
  afterEachRoute: async request => {
    const [routePath] = request.url.split('/');

    // We won't report the playback or splash page
    // Content hubs will be reported in their "on" functions
    if (
      routePath &&
      routePath !== RoutePath.PLAY &&
      routePath !== RoutePath.SPLASH &&
      routePath !== RoutePath.CONTENT_HUB
    ) {
      await window.analytics.initializationPromise;
      window.analytics.permutive.reportPageChange(getPageIdFromHash(routePath));
    }

    if (routePath !== RoutePath.SPLASH && routePath !== RoutePath.ERROR) {
      const termsAcceptedTimestamp =
        AppData!.storageService.termsAcceptedTimestamp.get();
      const privacyModifiedDate = AppData!.privacyModifiedDate;

      const isPinRequired = AppData!.parentalPinService.isPinRequired();

      // If we are missing terms accepted timestamp or privacy modified date,
      // show app launch as safeguard
      if (!termsAcceptedTimestamp || !privacyModifiedDate) {
        if (isPinRequired) {
          AppLaunch.widgetAfter = 'EnterParentalPin';
        }

        return Router.focusWidget('AppLaunch');
      }

      if (isPinRequired) {
        return Router.focusWidget('EnterParentalPin');
      }

      const termsAcceptedTime = new Date(termsAcceptedTimestamp).getTime();
      const privacyModifiedTime = new Date(privacyModifiedDate).getTime();

      if (termsAcceptedTime < privacyModifiedTime) {
        Router.focusWidget('AppLaunch');
      }
    }
  },
  routes,
} as Router.Config;
