import { translate } from 'support/translate';
import ParentalPin from './ParentalPin';
import { Router } from '@lightningjs/sdk';
import SetParentalPin from './SetParentalPin';

const TARGET_YEAR = 12;

export default class ResetParentalPin extends ParentalPin {
  protected override update() {
    this.title = translate('ParentalPin.resetPin.title');
    this.description = translate('ParentalPin.resetPin.description');
    this._PinInput.patch({ useMaskingCharacter: false });
    super.update();
  }

  override closeWidget() {
    this._Content.alpha = 0;
    if (ResetParentalPin.focusWidgetOnClose) {
      Router.focusWidget(ResetParentalPin.focusWidgetOnClose);
    } else {
      Router.focusPage();
    }
    this.resetStaticStates();
  }

  protected override getErrorText() {
    return translate('ParentalPin.resetPin.errorText');
  }

  protected override resetStaticStates() {
    ResetParentalPin.onValidateCallback = null;
    ResetParentalPin.focusWidgetOnClose = null;
    ResetParentalPin.onCancelCallback = null;
  }

  override $cancel() {
    ResetParentalPin.onCancelCallback?.();
    this.closeWidget();
  }

  private passStatesToSetPin() {
    SetParentalPin.focusWidgetOnClose = ResetParentalPin.focusWidgetOnClose;

    // instead of calling onValidateCallback, we pass the callback to SetParentalPin
    SetParentalPin.onValidateCallback = ResetParentalPin.onValidateCallback;
    SetParentalPin.onCancelCallback = ResetParentalPin.onCancelCallback;
  }

  override $validate() {
    const targetYear = new Date().getFullYear() - TARGET_YEAR;

    const enteredYear = Number(this.pin);
    if (this.pin.length === 4 && enteredYear <= targetYear) {
      this.passStatesToSetPin();

      ResetParentalPin.focusWidgetOnClose = 'SetParentalPin';
      this.closeWidget();
    } else {
      this.handleInvalidPin();
    }
  }
}
