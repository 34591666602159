import { Colors, Lightning, Router } from '@lightningjs/sdk';
import { EpgChannel, EpgProgram } from 'types/api/media';
import OnDemandBadge from 'components/common/OnDemandBadge';
import RatingBadge from 'components/common/RatingBadge';
import { ListWithSpeech } from 'components/common/CollectionWrappersWithSpeech';
import { translate } from 'support/translate';
import { getFontFaceFromStyle, withSeparator } from 'support/textUtils';
import { timeTo12HourClockHoursMinutes } from 'support/dateUtils';
import EpgModalButton, { EpgModalButtonTemplateSpec } from './EpgModalButton';
import { RoutePath } from 'config/routes';

export interface EpgModalTemplateSpec extends Lightning.Component.TemplateSpec {
  channel: EpgChannel;
  program: EpgProgram;

  BackgroundOverlay: Lightning.textures.RectangleTexture;
  Modal: {
    Background: Lightning.textures.ImageTexture;
    Content: {
      ChannelMetadata: Lightning.textures.TextTexture;
      Title: Lightning.textures.TextTexture;
      ProgramMetadata: {
        Subtitle: Lightning.textures.TextTexture;
        RatingBadge: typeof RatingBadge;
        OnDemand: typeof OnDemandBadge;
      };
      Description: Lightning.textures.TextTexture;
    };
    ButtonList: typeof ListWithSpeech;
  };
}

interface EpgModalSignalMap extends Lightning.Component.SignalMap {
  dismissModal(): void;
}

interface EpgModalTypeConfig extends Lightning.Component.TypeConfig {
  SignalMapType: EpgModalSignalMap;
}

const MODAL_X = 1159;
const MODAL_Y = 134;
const MODAL_WIDTH = 666;
const MODAL_HEIGHT = 812;

const PROGRAM_IMAGE_WIDTH = MODAL_WIDTH;
const PROGRAM_IMAGE_HEIGHT = 375;

const CONTENT_X = 41;
const DEFAULT_CONTENT_PADDING = 22;

const PROGRAM_METADATA_MARGIN_RIGHT = 7;

const BUTTON_WIDTH = MODAL_WIDTH;
const BUTTON_HEIGHT = 87;

const TEXT_DEFAULT_FONT_SIZE = 18;
const TEXT_DEFAULT_MAX_WIDTH = 611;

const modalButtonTemplates: {
  goToShowButton: Lightning.Element.PatchTemplate<EpgModalButtonTemplateSpec>;
  backToGuideButton: Lightning.Element.PatchTemplate<EpgModalButtonTemplateSpec>;
} = {
  goToShowButton: {
    w: BUTTON_WIDTH,
    h: BUTTON_HEIGHT,
    titleKey: 'epg.modal.goToDetails',
    icon: {
      focused: 'static/images/arrow-right-dark.svg',
      unfocused: 'static/images/arrow-right-light.svg',
    },
    iconSizing: {
      width: 26,
      height: 22,
    },
    zIndex: 1,
  },
  backToGuideButton: {
    w: BUTTON_WIDTH,
    h: BUTTON_HEIGHT,
    titleKey: 'epg.backToGuide',
    icon: {
      focused: 'static/images/epg/back-to-channel-guide-icon-focused.svg',
      unfocused: 'static/images/epg/back-to-channel-guide-icon-unfocused.svg',
    },
    iconSizing: {
      width: 27,
      height: 20,
    },
    zIndex: 1,
  },
};

export default class EpgModal
  extends Lightning.Component<EpgModalTemplateSpec, EpgModalTypeConfig>
  implements Lightning.Component.ImplementTemplateSpec<EpgModalTemplateSpec>
{
  private _channel?: EpgChannel;
  private _program?: EpgProgram;

  private _Modal = this.getByRef('Modal')!;
  private _Background = this._Modal.getByRef('Background')!;
  private _Content = this._Modal.getByRef('Content')!;
  private _ButtonList = this._Modal.getByRef('ButtonList')!;

  private _ChannelMetadata = this._Content.getByRef('ChannelMetadata')!;
  private _Title = this._Content.getByRef('Title')!;
  private _ProgramMetadata = this._Content.getByRef('ProgramMetadata')!;
  private _Description = this._Content.getByRef('Description')!;

  private _Subtitle = this._ProgramMetadata.getByRef('Subtitle')!;
  private _RatingBadge = this._ProgramMetadata.getByRef('RatingBadge')!;
  private _OnDemand = this._ProgramMetadata.getByRef('OnDemand')!;

  get title() {
    const { description, ratings, subtitle, title } = this._program ?? {};
    const channelMetadata = this._ChannelMetadata.text?.text ?? '';
    const onDemand = this._OnDemand.visible ? translate('global.onDemand') : '';

    return [
      channelMetadata,
      title ?? '',
      subtitle ?? '',
      ratings ?? '',
      onDemand,
      description ?? '',
    ];
  }

  set channel(channel: EpgChannel) {
    this._channel = channel;

    this.updateChannelMetadata();
  }

  set program(program: EpgProgram) {
    this._program = program;

    this._Background.patch({ src: program.images.detailsUrl ?? '' });
    this._Title.patch({ text: program.title ?? '' });
    this._Description.patch({ text: program.description ?? '' });

    this._Subtitle.patch({ text: program.subtitle ?? '' });
    this._RatingBadge.patch({ rating: program.ratings ?? '' });
    this._OnDemand.patch({ visible: program.isOnDemand ?? false });

    this.updateChannelMetadata();
    this.updateButtons();
  }

  static override _template(): Lightning.Component.Template<EpgModalTemplateSpec> {
    return {
      BackgroundOverlay: {
        rect: true,
        w: 1920,
        h: 1080,
        color: Colors('black').alpha(0.8).get(),
        collision: true,
        zIndex: 1,
      },
      Modal: {
        x: MODAL_X,
        y: MODAL_Y,
        rect: true,
        color: Colors('appBackground').get(),
        w: MODAL_WIDTH,
        h: MODAL_HEIGHT,
        zIndex: 1,
        Background: {
          rect: true,
          w: PROGRAM_IMAGE_WIDTH,
          h: PROGRAM_IMAGE_HEIGHT,
        },
        Content: {
          flex: {
            direction: 'column',
            paddingTop: DEFAULT_CONTENT_PADDING,
          },
          x: CONTENT_X,
          y: PROGRAM_IMAGE_HEIGHT,
          ChannelMetadata: {
            flexItem: { marginBottom: DEFAULT_CONTENT_PADDING },
            text: { fontSize: TEXT_DEFAULT_FONT_SIZE },
          },
          Title: {
            flexItem: { marginBottom: 4 },
            text: {
              fontSize: 30,
              fontFace: getFontFaceFromStyle('bold'),
              textColor: Colors('text').get(),
              maxLines: 1,
              maxLinesSuffix: translate('global.defaultMaxLineSuffix'),
            },
          },
          ProgramMetadata: {
            flexItem: { marginBottom: 20 },
            flex: {
              direction: 'row',
              alignItems: 'center',
            },
            Subtitle: {
              flexItem: { marginRight: PROGRAM_METADATA_MARGIN_RIGHT },
              text: {
                fontSize: TEXT_DEFAULT_FONT_SIZE,
                textColor: Colors('text').alpha(0.65).get(),
              },
            },
            RatingBadge: {
              flexItem: { marginRight: PROGRAM_METADATA_MARGIN_RIGHT },
              type: RatingBadge,
              size: 'extraSmall',
            },
            OnDemand: {
              type: OnDemandBadge,
              visible: false,
              w: 84,
              h: 22,
            },
          },
          Description: {
            w: TEXT_DEFAULT_MAX_WIDTH,
            text: {
              fontSize: TEXT_DEFAULT_FONT_SIZE,
              lineHeight: 21,
              textColor: Colors('text').get(),
              maxLines: 4,
              maxLinesSuffix: translate('global.defaultMaxLineSuffix'),
            },
          },
        },
        ButtonList: {
          type: ListWithSpeech,
          direction: 'column',
          itemType: EpgModalButton,
          zIndex: 1,
        },
      },
    };
  }

  override _getFocused() {
    return this._ButtonList;
  }

  override _handleLeft() {
    // Do not focus on navbar
    return true;
  }

  override _handleBack() {
    this.dismissModal();
  }

  override _handleEnter() {
    const currentItem = this._ButtonList.currentItem as EpgModalButton;
    const { goToShowButton, backToGuideButton } = modalButtonTemplates;

    if (goToShowButton.titleKey === currentItem.titleKey) {
      this.goToShow();
    } else if (backToGuideButton.titleKey === currentItem.titleKey) {
      this.dismissModal();
    }
  }

  private updateButtons() {
    const { backToGuideButton, goToShowButton } = modalButtonTemplates;
    const items = [backToGuideButton];
    if (this._program?.seriesSlug) items.unshift(goToShowButton);

    const buttonListY = MODAL_HEIGHT - items.length * BUTTON_HEIGHT;

    this._ButtonList.patch({ items, y: buttonListY });
  }

  private updateChannelMetadata() {
    const channelMetadata: string[] = [];

    if (this._program) {
      const startDate = new Date(this._program.startTime);
      const startTime = timeTo12HourClockHoursMinutes(startDate);
      channelMetadata.push(startTime);
    }
    if (this._channel) {
      const channelName = this._channel.title;
      channelMetadata.push(channelName);
    }

    const channelMetadataText = withSeparator(...channelMetadata);
    this._ChannelMetadata.patch({ text: channelMetadataText });
  }

  private goToShow() {
    const { seriesType, seriesSlug } = this._program!;
    const basePath =
      seriesType === 'series'
        ? RoutePath.SERIES_DETAIL
        : RoutePath.MOVIE_DETAIL;

    Router.navigate(`${basePath}/${seriesSlug}`);
  }

  private dismissModal() {
    this.resetUI();
    this.signal('dismissModal');
  }

  private resetUI() {
    this._Background.patch({ src: '' });
    this._ChannelMetadata.patch({ text: '' });
    this._Title.patch({ text: '' });
    this._Description.patch({ text: '' });

    this._Subtitle.patch({ text: '' });
    this._RatingBadge.patch({ rating: '' });
    this._OnDemand.patch({ visible: false });

    this._ButtonList.clear();
  }
}
