import { isShow } from 'support/contentUtils';
import { daysToMilliseconds } from 'support/dateUtils';
import { Merge } from 'types';
import { Show, Video } from 'types/api/media';
import StorageService from './StorageService';

type SearchItem = {
  item: Merge<Show, Video>;
  dateAdded: number;
};

type ConstructorArgs = {
  storageService: StorageService;
};

export type RecentSearches = Record<string, SearchItem>;

export default class RecentSearchService {
  private readonly DAYS_TO_KEEP_SEARCHES = 30;
  private readonly MAX_ITEMS = 20;
  private readonly storageService: StorageService;

  constructor({ storageService }: ConstructorArgs) {
    this.storageService = storageService;
  }

  private searches: RecentSearches | null = null;

  private lazyInit() {
    if (this.searches) return;

    const recentSearches = this.storageService.recentSearches.get();
    this.searches = recentSearches ?? {};
    this.purge();
  }

  private updateStorage() {
    if (!this.searches) return;

    this.storageService.recentSearches.set(this.searches);
  }

  private remove(identifier: string) {
    if (!this.searches || !(identifier in this.searches)) return;

    delete this.searches[identifier];
  }

  // Purges recent searches of any expired content
  private purge(): void {
    const currentDate = new Date().getTime();
    let hasChange = false;

    Object.keys(this.searches ?? {}).forEach(id => {
      const data = this.searches?.[id];

      if (
        data &&
        currentDate - data.dateAdded >
          daysToMilliseconds(this.DAYS_TO_KEEP_SEARCHES)
      ) {
        this.remove(id);
        hasChange = true;
      }
    });

    if (hasChange) {
      this.updateStorage();
    }
  }

  add(item: Merge<Show, Video>) {
    this.lazyInit();
    if (!this.searches) return;

    const currentDate = new Date().getTime();

    let identifier: string;
    if (isShow(item)) {
      identifier = (item as Show).id;
    } else {
      identifier = (item as Video).guid;
    }

    if (identifier in this.searches) {
      this.searches[identifier]!.dateAdded = currentDate;
      return;
    }

    const searchEntries = Object.entries(this.searches);

    if (searchEntries.length >= this.MAX_ITEMS) {
      const oldest = searchEntries.sort(
        (a, b) => a[1].dateAdded - b[1].dateAdded,
      );

      const toRemove = oldest.slice(
        0,
        searchEntries.length - this.MAX_ITEMS + 1,
      );

      toRemove.forEach(entry => this.remove(entry[0]));
    }

    this.searches[identifier] = { item, dateAdded: currentDate };

    this.updateStorage();
  }

  getRecentSearches() {
    this.lazyInit();
    if (!this.searches) return;

    const items = Object.values(this.searches);

    items.sort((a, b) => b.dateAdded - a.dateAdded);

    return items.map(({ item }) => item);
  }
}
