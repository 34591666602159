import {
  Episode,
  Show,
  Video,
  Media,
  PlayableMedia,
  EpgChannel,
  EpgProgram,
  PlayableMediaImages,
  ChannelImages,
  ShowVideos,
} from 'types/api/media';
import { translate } from './translate';
import { diffInDays } from './dateUtils';
import { AppData } from '@lightningjs/sdk';
import { NextEpisode } from 'services/history/AbstractHistoryService';
import { ContentHub, PromoChannel } from 'services/cwData';

export const isShow = (content: Media) => {
  return content.type === 'series' || content.type === 'movie';
};

export const isEpisode = (content: Media) => {
  return content.type === 'Episode';
};

export const isVideo = (content: Media) => {
  return content.type === 'Video';
};

export const isLive = (content: Media) => {
  return !!content?.isLive;
};

export const isLiveProgram = (content: Media) => {
  return !!content?.isLive && content.type === 'LiveProgramVideo';
};

export const isFullContent = (content: Media) => {
  return !!content.isFullEpisode;
};

export const isSeries = (content: Media) => {
  return content.type === 'series' || content.seriesType === 'series';
};

export const isMovie = (content: Media) => {
  return content.type === 'movie' || content.seriesType === 'movie';
};

export const isLiveEvent = (content: Media) => {
  return content?.isLiveEvent === true;
};

export const isLiveEventChannel = (content: Media) => {
  return content?.type === 'LiveEventChannel';
};

export const isChannel = (content: Media) => {
  return content.type === 'LiveChannel' || content.contentType === 'channel';
};

export const isContentHub = (data: unknown) => {
  return (data as ContentHub)?.type === 'content-hub';
};

export const isPromoChannel = (data: unknown) => {
  return (data as PromoChannel)?.type === 'PromoChannel';
};

// Keep both for now in episodeInSeason is missing in feed
// We can remove episode later once we are sure it is not needed
export const getEpisode = <
  T extends { episodeInSeason?: string; episode?: string },
>(
  content: T | null,
) => {
  if (!content || (!content.episodeInSeason && !content.episode)) return '';

  return `${
    content.episodeInSeason
      ? Number(content.episodeInSeason)
      : Number(content.episode) % 100
  }`;
};

export const getSeason = <T extends { season?: string }>(content: T | null) => {
  return content?.season ?? '';
};

export const getDurationMinutes = (content: Show | PlayableMedia): number => {
  const durationSecs = Number(
    isShow(content)
      ? (content as Show).firstEpisode?.durationSecs
      : (content as PlayableMedia).durationSecs,
  );
  return durationSecs ? Math.ceil(durationSecs / 60) : 0;
};

/**
 * Formats the next episodes available date
 *
 * @param unformattedDate Date string in the format of '{month} . {day} . {year}'
 * @returns Date string in the format of '{weekday}, {month} {day}, {year}'
 */
export const getNextEpisodeAvailableFormatted = (unformattedDate: string) => {
  const formattedDate = unformattedDate.replace(' ', ''); // Removes whitespace
  const date = new Date(formattedDate);
  const strFormatOptions: Intl.DateTimeFormatOptions = {
    weekday: 'long',
    month: 'long',
    day: 'numeric',
    year: 'numeric',
  };

  return date.toLocaleString('en-US', strFormatOptions);
};

export const getSeasonEpisodeFormatted = (
  content: Video | Episode,
  delimiter = ' : ',
) => {
  const season = getSeason(content);
  const episode = getEpisode(content);

  return (
    translate('global.season', season) +
    delimiter +
    translate('global.episode', episode)
  );
};

export const getSeasonEpisodeTtsFormatted = (
  content: Video | Episode,
  delimiter = ' , ',
) => {
  const season = getSeason(content);
  const episode = getEpisode(content);

  return (
    translate('ttsPrompts.seasonCount', season) +
    delimiter +
    translate('ttsPrompts.episodeCount', episode)
  );
};

export const getDurationMinutesNoSpaceFormatted = (
  content: Show | PlayableMedia,
) => {
  return translate(
    'global.durationMinutesNoSpace',
    getDurationMinutes(content),
  );
};

export const getDurationMinutesFormatted = (content: Show | PlayableMedia) => {
  return translate('global.durationMinutes', getDurationMinutes(content));
};

export const getDurationHourMinutesNoSpaceFormatted = (
  content: Show | PlayableMedia,
) => {
  const minutes = getDurationMinutes(content);
  const hours = Math.floor(minutes / 60);
  return hours === 0
    ? translate('global.durationMinutesNoSpace', minutes)
    : translate('global.durationHoursMinutesNoSpace', hours, minutes % 60);
};

export const isInCredits = (video: Video, timestamp: number) => {
  return timestamp >= getCreditStartTime(video);
};

export const getCreditStartTime = (video: Video) => {
  const duration = parseFloat(video.durationSecs);
  const creditOffset = parseFloat(video.creditSecs);
  return duration - creditOffset;
};

export const canSaveVideoProgress = (video: Video | null, progress: number) => {
  return video !== null && isFullContent(video) && progress > 0;
};

export const isMediaExpiring = (content: Show | Video) => {
  if (isShow(content)) return false;

  const { seriesType, expireTime } = content as Video;

  const currentData = new Date();
  const expirationDate = new Date(expireTime);

  const daysToExpire = diffInDays(expirationDate, currentData);

  let expiryInterval = 0;
  if (seriesType === 'series') {
    expiryInterval = AppData?.timers.seriesExpiryDayLimit ?? 0;
  } else if (seriesType === 'movie') {
    expiryInterval = AppData?.timers.movieExpiryDayLimit ?? 0;
  }

  return expiryInterval > daysToExpire;
};

export const getExpiringDateString = (content: Video) => {
  const expiringDate = new Date(content.expireTime);
  const expiringDateString = expiringDate
    .toLocaleString('en-US', {
      month: 'short',
      day: '2-digit',
    })
    .toUpperCase();

  return translate('episode.expiring', expiringDateString);
};

export const isEpisodeReleased = (content: Episode | NextEpisode | null) => {
  return !!content && parseInt(content.durationSecs) !== 0;
};

export const getVideoReleaseYear = (content: Video) => {
  const airDate = new Date(content.airDate);
  return airDate.getFullYear();
};

export const formatPlayMedia = (
  content: Show | Episode,
  options?: { progress?: number; isComplete?: boolean; isFromTitle?: boolean },
): string => {
  const { progress, isComplete, isFromTitle } = options ?? {};

  if (isMovie(content)) {
    return !progress || isComplete
      ? translate(isFromTitle ? 'global.play' : 'global.playMovie')
      : translate('global.continueWatching');
  } else {
    const playEpisode =
      isEpisode(content) || isVideo(content)
        ? (content as Episode)
        : (content as Show).firstEpisode;

    const season = getSeason(playEpisode);
    const episode = getEpisode(playEpisode);

    return !progress || isComplete
      ? translate('global.playMedia', season, episode)
      : translate('global.continueMedia', season, episode);
  }
};

/**
 * Formats a number in seconds to a string of the form mm:ss or h:mm:ss
 *
 * @param time - time in seconds
 * @returns the formatted time
 */
export const formatVideoTime = (time: number) => {
  const hours = Math.floor(time / 3600);
  const hoursAsSeconds = hours * 3600;
  const minutes = Math.floor((time - hoursAsSeconds) / 60);
  const minutesAsSeconds = minutes * 60;
  const seconds = Math.floor(time - hoursAsSeconds - minutesAsSeconds);

  const formattedSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`;
  let result: string;
  if (hours > 0) {
    const formattedMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;
    result = `${hours}:${formattedMinutes}:${formattedSeconds}`;
  } else {
    result = `${minutes}:${formattedSeconds}`;
  }
  return result;
};

/**
 * Returns the text of the spoken video time
 *
 * @param time - time in mm:ss or h:mm:ss
 * @returns the spoken time
 */
export const getVideoTimeTts = (time: string) => {
  const [secProgress = 0, minProgress = 0, hrProgress = 0] =
    time
      .split(':')
      .reverse()
      .map(n => parseInt(n)) ?? [];
  let formattedSeconds = '',
    formattedMinutes = '',
    formattedHours = '';

  if (hrProgress === 1) {
    formattedHours = `${hrProgress} ${translate('ttsPrompts.hour')} `;
  } else if (hrProgress > 1) {
    formattedHours = `${hrProgress} ${translate('ttsPrompts.hours')} `;
  }

  if (minProgress === 1) {
    formattedMinutes = `${minProgress} ${translate('ttsPrompts.minute')} `;
  } else if (minProgress > 1) {
    formattedMinutes = `${minProgress} ${translate('ttsPrompts.minutes')} `;
  }

  if (secProgress === 1) {
    formattedSeconds = `${secProgress} ${translate('ttsPrompts.second')}`;
  } else if (secProgress > 1) {
    formattedSeconds = `${secProgress} ${translate('ttsPrompts.seconds')}`;
  }

  return `${formattedHours}${formattedMinutes}${formattedSeconds}`;
};

export const getCurrentEpgProgram = (channel: EpgChannel) => {
  const currentTime = new Date().valueOf();

  return channel.programs.find(program => {
    const start = new Date(program.startTime).valueOf();
    const end = new Date(program.endTime).valueOf();

    return start <= currentTime && end >= currentTime;
  });
};

export const isProgramPlaying = (
  program: EpgProgram,
  currentTime = new Date(),
) => {
  const programStartDate = new Date(program.startTime);
  const programEndTime = new Date(program.endTime);
  return programStartDate <= currentTime && currentTime <= programEndTime;
};

/**
 * Gets the first episode of the specified season or the earliest season
 *
 * @param showVideos Array of show videos
 * @param seasonCode If `seasonCode` is specified, gets the first episode from
 * the season with this code. If not provided or found, gets the first episode
 * of the earliest season
 * @returns The first episode of the specified season or the earliest season.
 * Returns `undefined` if there are no seasons
 */
export const getFirstEpisodeFromShowVideos = (
  showVideos: ShowVideos[],
  seasonCode?: string,
) => {
  const seasonShowVideos = getSeasonFromShowVideos(showVideos, seasonCode);
  const videos = seasonShowVideos?.items;
  if (!videos?.length) return;

  return videos.reduce((acc, curr) => {
    return acc.episodeInSeason < curr.episodeInSeason ? acc : curr;
  });
};

/**
 * Gets the videos of the specified season or the earliest season
 *
 * @param showVideos Array of show videos
 * @param seasonCode If `seasonCode` is specified, gets the videos from the
 * season with this code. If not provided or found, gets the videos of the
 * earliest season
 * @returns The videos of the specified season or the earliest season. Returns
 * `undefined` if there are no season
 */
export const getSeasonFromShowVideos = (
  showVideos: ShowVideos[],
  seasonCode?: string,
) => {
  if (!showVideos.length) return undefined;

  if (seasonCode) {
    const selectedSeason = showVideos.find(showVideo => {
      const currentSeasonCode = showVideo.season?.code;
      return seasonCode && currentSeasonCode === seasonCode;
    });
    if (selectedSeason) return selectedSeason;
  }

  return showVideos.reduce((acc, curr) => {
    const accSeason = Number(acc.season?.code ?? '0');
    const currSeason = Number(curr.season?.code ?? '0');
    return accSeason < currSeason ? acc : curr;
  });
};

export const getThumbnail = (data: Media) => {
  return (
    (data.images as PlayableMediaImages).thumbnail ??
    (data.images as ChannelImages).homeThumbnail ??
    ''
  );
};
