import { Lightning } from '@lightningjs/sdk';
import { StaticViewContexts } from 'types/analytics';
import { PageId } from 'types/pageId';
import Page from '../../Page';

export interface LiveEventDetailsPageSpec
  extends Lightning.Component.TemplateSpec {
  pageData: {
    // TODO
  };

  Content: {
    // TODO
  };
}

export default class LiveEventDetailsPage
  extends Page<LiveEventDetailsPageSpec>
  implements
    Lightning.Component.ImplementTemplateSpec<LiveEventDetailsPageSpec>
{
  protected override _pageId = PageId.LIVE_EVENT_DETAIL;
  protected override _viewContext = StaticViewContexts.UNKNOWN; // TODO

  static override _template(): Lightning.Component.Template<LiveEventDetailsPageSpec> {
    return {
      ...super._template(),
      Content: {
        // TODO
      },
    };
  }

  override _onDataProvided() {
    // TODO
  }
}
