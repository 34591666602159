import dashjs from 'dashjs';
import AbstractPlayerInstance from './AbstractPlayerInstance';
import { AppData } from '@lightningjs/sdk';
import { Platform } from '../../../models/platforms/platform';

export default class DashPlayerInstance extends AbstractPlayerInstance<dashjs.TextTracksAddedEvent> {
  private dash: dashjs.MediaPlayerClass;

  // Keep track of player listeners so we can clear them on player close
  private listeners: Record<string, (e: any) => void> = {};

  constructor(
    videoElement: HTMLMediaElement,
    licenseUrl: string,
    isDynamicStream = false,
  ) {
    super(videoElement);

    /* 
    currently there's a bug when we have a dynamic stream (like a DRM EPG program) we don't fetch subsequent sections unless we set src='' (EL-459)
    what causes this is unknown, we have thoroughly confirmed that our Dash configuration should work using https://reference.dashif.org/dash.js/ 
    - the current assumption of the issue is that there is a problem with our environment/strange interaction with our packages that is causing this
    - our guess as to why this fix works is that when we set src='' it throws an error thats caught and handled by Dash, when Dash handles this error it somehow
      fixes the dynamic stream issue (this fix also works if we set src to something invalid like src='error'). Further investigation of this will be required

    This logic causes an error to be reported to Conviva (EL-566), so we add this if condition to limit this false error to only DRM EPG programs
    */
    if (isDynamicStream) {
      videoElement.setAttribute('src', '');
    }

    const protectionData = {
      'com.widevine.alpha': {
        serverURL: licenseUrl,
        priority: 0,
      },
    };

    const settings: dashjs.MediaPlayerSettingClass = {
      // Uncomment line below to see detailed streaming logs
      // debug: { logLevel: 5 },
      streaming: {
        text: {
          defaultEnabled: false,
        },
        ...this.getAdditionalSettings(),
      },
    };

    this.dash = dashjs.MediaPlayer().create();
    this.dash.initialize();
    this.dash.updateSettings(settings);
    const audio = AppData?.storageService.audio.get();
    if (audio) {
      this.dash.setInitialMediaSettingsFor('audio', { lang: audio });
    }
    this.dash.attachView(videoElement);
    this.dash.setAutoPlay(true);
    this.dash.setProtectionData(protectionData);

    this.addManifestLoadedListener();
    this.addTextTracksAddedListener();
  }

  override async destroy() {
    this.clearListeners();
    this.dash.reset();
    this.dash.destroy();
  }

  override async load(url: string, startTime: number | undefined) {
    this.dash.attachSource(url, startTime);
  }

  override onAdaption(handleAdaption: (bitrate: number) => void): void {
    this.addAdaptionListener(handleAdaption);
  }

  override onError(
    handleError: (isFatal: boolean, errorMessage: string) => void,
  ): void {
    this.addErrorListener(handleError);
  }

  override getSubtitles(): string[] {
    return this.subtitles;
  }

  override getAudioOptions(): string[] {
    const audioTracks: string[] = [];
    this.dash.getTracksFor('audio').forEach(track => {
      if (track.lang) audioTracks.push(track.lang);
    });
    return audioTracks;
  }

  override setAudioTrack(lang: string): void {
    const audioTrack = this.dash
      .getTracksFor('audio')
      .find(track => track.lang === lang);
    if (audioTrack) this.dash.setCurrentTrack(audioTrack);
  }

  override enableCc(lang: string): void {
    if (lang) {
      const trackIndex = this.subtitles.findIndex(
        subtitleOption => subtitleOption === lang,
      );
      if (trackIndex >= 0) {
        this.dash.setTextTrack(trackIndex);
        this.dash.enableText(true);
      }
    } else {
      this.dash.enableText(false);
    }
  }

  override disableCc(): void {
    this.dash.enableText(false);
  }

  override extractSubtitleOptions(
    addedTracksEvent: dashjs.TextTracksAddedEvent,
  ) {
    const subtitles: string[] = [];
    addedTracksEvent.tracks.forEach(trackInfo => {
      const { kind, lang } = trackInfo;
      if (lang && kind === 'subtitles') subtitles.push(lang);
    });

    return subtitles;
  }

  override getPlayerMedia(): HTMLMediaElement | null {
    return this.dash.getVideoElement();
  }

  private getAdditionalSettings() {
    const platform = AppData!.device.getPlatform();
    const settings = {
      abr: {
        limitBitrateByPortal: true, // default is false
      },
    };

    if (platform === Platform.VIZIO) {
      return {
        abr: {
          ...settings.abr,
          maxBitrate: {
            video: 2000, // default is -1
          },
          usePixelRatioInLimitBitrateByPortal: true, // default is false
        },
        buffer: {
          stableBufferTime: 20, // default is 12
        },
        gaps: {
          enableStallFix: true, // default is false
        },
      };
    }
    return settings;
  }

  private addManifestLoadedListener() {
    const listener = dashjs.MediaPlayer.events.MANIFEST_LOADED;
    const cb = (event: dashjs.ManifestLoadedEvent) => {
      const textTracks: string[] = [];
      (event.data as any).Period_asArray.forEach((period: any) => {
        period.AdaptationSet_asArray.forEach((adaptationSet: any) => {
          if (
            adaptationSet.contentType === 'text' &&
            !textTracks.includes(adaptationSet.lang)
          ) {
            textTracks.push(adaptationSet.lang);
          }
        });
      });

      this.handleTracksUpdate?.(textTracks);
    };

    this.addListener(listener, cb);
  }

  private addTextTracksAddedListener() {
    const listener = dashjs.MediaPlayer.events.TEXT_TRACKS_ADDED;
    const cb = this.updateSubtitles.bind(this);

    this.addListener(listener, cb);
  }

  private addAdaptionListener(handleAdaption: (bitrate: number) => void) {
    const listener = dashjs.MediaPlayer.events.QUALITY_CHANGE_RENDERED;
    const cb = (event: dashjs.QualityChangeRenderedEvent) => {
      if (event.mediaType !== 'video') return;

      const bitRateIndex = event.newQuality;
      const bitRates = this.dash.getBitrateInfoListFor(event.mediaType);

      if (bitRates[bitRateIndex] !== undefined) {
        const bitRate = bitRates[bitRateIndex]!.bitrate / 1000;
        this.currentBitrate = bitRate;

        handleAdaption(bitRate);
      }
    };

    this.addListener(listener, cb);
  }

  private addErrorListener(
    handleError: (isFatal: boolean, errorMessage: string) => void,
  ) {
    const listener = dashjs.MediaPlayer.events.ERROR;
    const cb = (event: dashjs.ErrorEvent) => {
      const { error } = event;
      if (typeof error === 'string') {
        handleError(true, error);
      } else {
        const { code, message } = error;
        handleError(true, `Error ${code}: ${message}`);
      }
    };

    this.addListener(listener, cb);
  }

  private addListener(listener: string, cb: (e: any) => void) {
    this.removeListener(listener);

    this.dash.on(listener, cb);
    this.listeners[listener] = cb;
  }

  private removeListener(listener: string) {
    const cb = this.listeners[listener];
    if (!cb) return;

    this.dash.off(listener, cb);
    delete this.listeners[listener];
  }

  private clearListeners() {
    Object.keys(this.listeners).forEach(this.removeListener.bind(this));
  }
}
