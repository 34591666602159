import { Colors, Lightning } from '@lightningjs/sdk';
import constants from '../../../../static/constants.json';
import { ProgressBar as ProgressBarLightning } from '@lightningjs/ui';
import PlaybackProgressBar from 'components/common/progressBar/PlaybackProgressBar';
import { translate } from 'support/translate';
import { getFontFaceFromStyle } from 'support/textUtils';
import { AdCuePoint } from './PlaybackPage';
import { formatVideoTime, getVideoTimeTts } from 'support/contentUtils';

const FASTFORWARD_ICON = 'static/images/playback/fastforward-icon.svg';
const REWIND_ICON = 'static/images/playback/rewind-icon.svg';

const FOCUSED_ALPHA = 1;
const UNFOCUSED_ALPHA = 0.5;

const PROGRESS_BAR_WIDTH = constants.ui.progressBarWidth;
const PROGRESS_BAR_HEIGHT = 20;
const SEEK_BALL_DIAMETER = 30;
const AD_POD_MARKER_WIDTH = 8;
const VIDEO_TIME_Y = -63;

const TEXT_STYLE = {
  fontSize: 39,
};

const DEFAULT_SEEK_RATE = 1;
const DEFAULT_SEEK_SPEED = 0;

export interface ProgressBarTemplateSpec
  extends Lightning.Component.TemplateSpec {
  progress: number;
  duration: number;
  seekRate: number;
  seekSpeed: number;
  adCuePoints: AdCuePoint[];

  LoadedBar: typeof ProgressBarLightning;
  ProgressBar: typeof PlaybackProgressBar;
  AdPodMarkers: object;
  SeekBall: Lightning.textures.RectangleTexture;
  VideoData: {
    VideoTime: Lightning.textures.TextTexture;
    SeekInfo: {
      SeekIcon: Lightning.textures.ImageTexture;
      SeekSpeed: Lightning.textures.TextTexture;
    };
  };
}

export default class ProgressBar
  extends Lightning.Component<ProgressBarTemplateSpec>
  implements Lightning.Component.ImplementTemplateSpec<ProgressBarTemplateSpec>
{
  private _progress = 0;
  private _duration = 0;
  private _seekRate = DEFAULT_SEEK_RATE;
  private _seekSpeed = DEFAULT_SEEK_SPEED;
  private _adCuePoints: ProgressBarTemplateSpec['adCuePoints'] = [];

  private _LoadedBar = this.getByRef('LoadedBar')!;
  private _ProgressBar = this.getByRef('ProgressBar')!;
  private _AdPodMarkers = this.getByRef('AdPodMarkers')!;
  private _SeekBall = this.getByRef('SeekBall')!;
  private _VideoData = this.getByRef('VideoData')!;

  private _VideoTime = this._VideoData.getByRef('VideoTime')!;
  private _SeekInfo = this._VideoData.getByRef('SeekInfo')!;

  private _SeekIcon = this._SeekInfo.getByRef('SeekIcon')!;
  private _SeekSpeed = this._SeekInfo.getByRef('SeekSpeed')!;

  get title() {
    const [progress, duration] = this._VideoTime.text?.text.split(' / ') ?? [];
    const progressTts = getVideoTimeTts(progress ?? '');
    const durationTts = getVideoTimeTts(duration ?? '');
    return translate('ttsPrompts.progressBar', progressTts, durationTts);
  }

  get progress() {
    return this._progress;
  }

  set progress(progress: ProgressBarTemplateSpec['progress']) {
    this._progress = progress;
    this.updateProgressBar(progress);
    this.updateVideoData(progress);
  }

  get duration() {
    return this._duration;
  }

  set duration(duration: ProgressBarTemplateSpec['duration']) {
    this._duration = duration;
    this.updateVideoData(this.progress);
    this.renderAdPodMarkers();
  }

  get seekRate() {
    return this._seekRate;
  }

  set seekRate(seekRate: ProgressBarTemplateSpec['seekRate']) {
    this._seekRate = seekRate;
  }

  get seekSpeed() {
    return this._seekSpeed;
  }

  set seekSpeed(seekSpeed: ProgressBarTemplateSpec['seekSpeed']) {
    this._seekSpeed = seekSpeed;
    this.updateVideoData(this.progress);
  }

  get adCuePoints() {
    return this._adCuePoints;
  }

  set adCuePoints(adCuePoints: ProgressBarTemplateSpec['adCuePoints']) {
    this._adCuePoints = adCuePoints;
    this.renderAdPodMarkers();
  }

  static override _template(): Lightning.Component.Template<Lightning.Component.TemplateSpecLoose> {
    return {
      alpha: UNFOCUSED_ALPHA,
      LoadedBar: {
        type: ProgressBarLightning,
        w: PROGRESS_BAR_WIDTH,
        h: PROGRESS_BAR_HEIGHT,
        progressColor: Colors('white').alpha(0.7).get(),
        backgroundColor: Colors('transparent').get(),
        progressRadius: PROGRESS_BAR_HEIGHT / 2,
        backgroundRadius: PROGRESS_BAR_HEIGHT / 2,
        value: 0,
      },
      ProgressBar: {
        type: PlaybackProgressBar,
        w: PROGRESS_BAR_WIDTH,
        h: PROGRESS_BAR_HEIGHT,
        progressRadius: PROGRESS_BAR_HEIGHT / 2,
        value: 0,
      },
      AdPodMarkers: {},
      SeekBall: {
        y: PROGRESS_BAR_HEIGHT / 2,
        mount: 0.5,
        rect: true,
        color: Colors('white').get(),
        w: SEEK_BALL_DIAMETER,
        h: SEEK_BALL_DIAMETER,
        shader: {
          type: Lightning.shaders.RoundedRectangle,
          radius: SEEK_BALL_DIAMETER / 2,
        },
        visible: false,
      },
      VideoData: {
        y: VIDEO_TIME_Y,
        flex: { direction: 'row' },
        VideoTime: {
          text: {
            ...TEXT_STYLE,
            text: translate('playback.defaultTime'),
            fontFace: getFontFaceFromStyle('bold'),
            textColor: Colors('text').get(),
          },
        },
        SeekInfo: {
          flex: { direction: 'row', alignItems: 'center' },
          visible: false,
          SeekIcon: {
            y: -4,
            w: 35,
            h: 26,
            src: FASTFORWARD_ICON,
            flexItem: { marginLeft: 20 },
          },
          SeekSpeed: {
            text: {
              ...TEXT_STYLE,
              text: '',
              fontFace: getFontFaceFromStyle('bold'),
              textColor: Colors('text').get(),
            },
            flexItem: { marginLeft: 10 },
          },
        },
      },
    };
  }

  override _getFocused() {
    return this._ProgressBar;
  }

  override _focus() {
    this._SeekBall.visible = true;
    this.setSmooth('alpha', FOCUSED_ALPHA);
  }

  override _unfocus() {
    this._SeekBall.visible = false;
    this.setSmooth('alpha', UNFOCUSED_ALPHA);
  }

  private getSanitizedProgress(progress: number) {
    if (progress > this.duration) return this.duration;
    if (progress < 0) return 0;
    return progress;
  }

  private updateProgressBar(progress: number) {
    progress = this.getSanitizedProgress(progress);

    const percentage = progress / this.duration;
    this._ProgressBar.value = percentage;
    const position = percentage * this._ProgressBar.w;
    if (!isNaN(position)) {
      this._SeekBall.x = position;
    }
  }

  private renderAdPodMarkers() {
    this._AdPodMarkers.childList.clear();
    this.adCuePoints.forEach(adCuePoint => {
      if (!adCuePoint.played && adCuePoint.start !== 0) {
        this._AdPodMarkers.add({
          rect: true,
          x: (adCuePoint.start / this.duration) * PROGRESS_BAR_WIDTH,
          y: 1,
          w: AD_POD_MARKER_WIDTH,
          h: PROGRESS_BAR_HEIGHT - 2,
          color: Colors('black').alpha(0.78).get(),
        });
      }
    });
  }

  private updateVideoData(progress: number) {
    progress = this.getSanitizedProgress(progress);

    this._VideoData.patch({
      VideoTime: {
        text: {
          text: translate(
            'playback.progressTimestamp',
            formatVideoTime(progress),
            formatVideoTime(this.duration),
          ),
        },
      },
      SeekInfo: {
        visible: this.seekSpeed !== 0,
        SeekIcon: {
          src: this.seekRate > 0 ? FASTFORWARD_ICON : REWIND_ICON,
        },
        SeekSpeed: {
          text: {
            text: translate('playback.seekSpeed', this.seekSpeed),
          },
        },
      },
    });
  }

  hideAdPodMarkers() {
    this._AdPodMarkers.visible = false;
  }

  showAdPodMarkers() {
    this._AdPodMarkers.visible = true;
  }
}
