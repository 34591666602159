import { Lightning } from '@lightningjs/sdk';
import { getFontFaceFromStyle, withSeparator } from 'support/textUtils';
import { Live, LiveEvent, Show } from 'types/api/media';
import RatingBadge from './RatingBadge';
import {
  getDurationMinutesNoSpaceFormatted,
  isLiveContent,
  isLiveEvent,
  isMovie,
} from 'support/contentUtils';

export interface MetadataLabelTemplateSpec
  extends Lightning.Component.TemplateSpec {
  mediaContent: Show | Live | LiveEvent | null;
  Content: {
    RatingBadge: typeof RatingBadge;
    Info: Lightning.Component;
  };
}

const FONT_SIZE = 29;
const RATING_BADGE_MARGIN = 30;

export default class MetadataLabel
  extends Lightning.Component<MetadataLabelTemplateSpec>
  implements
    Lightning.Component.ImplementTemplateSpec<MetadataLabelTemplateSpec>
{
  private _Content = this.getByRef('Content')!;
  private _RatingBadge = this._Content.getByRef('RatingBadge')!;
  private _Info = this._Content.getByRef('Info')!;

  static override _template(): Lightning.Component.Template<MetadataLabelTemplateSpec> {
    return {
      Content: {
        flex: {
          direction: 'row',
          alignItems: 'center',
        },
        RatingBadge: {
          flexItem: { marginRight: RATING_BADGE_MARGIN },
          size: 'medium',
          type: RatingBadge,
          visible: false,
        },
        Info: {
          flexItem: { marginTop: 5 }, // Adding margin so badge and info are centered
          text: {
            fontSize: FONT_SIZE,
            fontFace: getFontFaceFromStyle('regular'),
          },
        },
      },
    };
  }

  set mediaContent(mediaContent: Show | Live | LiveEvent | null) {
    if (!mediaContent) return;

    let infoText = '';
    if (isLiveContent(mediaContent)) {
      const { title, liveTuneIn } = mediaContent as Live;
      this._RatingBadge.patch({ visible: false });
      this._Info.patch({ text: { fontFace: getFontFaceFromStyle('bold') } });

      infoText = withSeparator(title, liveTuneIn);
    } else if (isLiveEvent(mediaContent)) {
      this._RatingBadge.patch({ visible: false });
      infoText = (mediaContent as LiveEvent).tuneIn;
    } else {
      const { rating, activeYears, releaseYear, genres } = mediaContent as Show;
      const genresText = genres.join(', ');

      this._RatingBadge.patch({ visible: true, rating });
      this._Info.patch({ text: { fontFace: getFontFaceFromStyle('regular') } });

      if (isMovie(mediaContent)) {
        const durationMinutes = getDurationMinutesNoSpaceFormatted(
          mediaContent as Show,
        );
        infoText = withSeparator(releaseYear, durationMinutes, genresText);
      } else {
        infoText = withSeparator(activeYears, genresText);
      }
    }

    this._Info.patch({ text: { text: infoText } });

    this._Info.removeAllListeners('txLoaded');
    this._Info.on('txLoaded', () => {
      this._Content.stage.update();
      this.updateWidth();
      this.updateHeight();
      this._Info.removeAllListeners('txLoaded');
    });

    this._RatingBadge.removeAllListeners('txLoaded');
    this._RatingBadge.on('txLoaded', () => {
      this._Content.stage.update();
      this.updateWidth();
      this.updateHeight();
      this._RatingBadge.removeAllListeners('txLoaded');
    });
  }

  private updateWidth() {
    this.w = this._Content.finalW;
  }

  private updateHeight() {
    this.h = this._Content.finalH;
  }
}
