import { translate } from './translate';

export const MILLISECONDS_IN_SECOND = 1000;
export const MILLISECONDS_IN_MINUTE = 60 * MILLISECONDS_IN_SECOND;
export const MILLISECONDS_IN_HOUR = 60 * MILLISECONDS_IN_MINUTE;
export const MILLISECONDS_IN_DAY = 24 * MILLISECONDS_IN_HOUR;

const diffHelper = (later: Date, earlier: Date, milliseconds: number) => {
  return (later.getTime() - earlier.getTime()) / milliseconds;
};

export const secondsToMilliseconds = (seconds: number) => {
  return MILLISECONDS_IN_SECOND * seconds;
};

export const minutesToMilliseconds = (minutes: number) => {
  return MILLISECONDS_IN_MINUTE * minutes;
};

export const millisecondsToMinutes = (milliseconds: number) => {
  return milliseconds / MILLISECONDS_IN_MINUTE;
};

export const diffInMinutes = (later: Date, earlier: Date) => {
  return diffHelper(later, earlier, MILLISECONDS_IN_HOUR);
};

export const hoursToMilliseconds = (hours: number) => {
  return MILLISECONDS_IN_HOUR * hours;
};

export const millisecondsToHours = (milliseconds: number) => {
  return milliseconds / MILLISECONDS_IN_HOUR;
};

export const diffInHours = (later: Date, earlier: Date) => {
  return diffHelper(later, earlier, MILLISECONDS_IN_HOUR);
};

export const daysToMilliseconds = (days: number) => {
  return MILLISECONDS_IN_DAY * days;
};

export const diffInDays = (later: Date, earlier: Date) => {
  return diffHelper(later, earlier, MILLISECONDS_IN_DAY);
};

export const getClosestMinute = (date: Date) => {
  date.setMinutes(date.getMinutes(), 0, 0);
  return date;
};

export const getClosestThirtyMinuteOffsetDate = (date: Date) => {
  const currentMinutes = date.getMinutes();
  const thirtyMinuteOffset = currentMinutes % 30;

  date.setMinutes(currentMinutes - thirtyMinuteOffset, 0, 0);
  return date;
};

export const timeTo12HourClockHoursMinutes = (time: Date, full = false) => {
  const hours = time.getHours() % 12 === 0 ? 12 : time.getHours() % 12;

  let minutes = '00';
  if (full || time.getMinutes() > 1) {
    const minutesZeroPadding = time.getMinutes() < 10 ? '0' : '';
    minutes = `${minutesZeroPadding}${time.getMinutes()}`;
  }

  const pmOrAm =
    time.getHours() >= 12
      ? translate('global.lowercasePM')
      : translate('global.lowercaseAM');

  return `${hours}:${minutes}${pmOrAm}`;
};

export const millisecondsToContentDuration = (milliseconds: number) => {
  const hours = Math.floor(millisecondsToHours(milliseconds));
  const minutes = Math.ceil(millisecondsToMinutes(milliseconds));

  return hours === 0
    ? translate('global.durationMinutesNoSpace', minutes)
    : translate('global.durationHoursMinutesNoSpace', hours, minutes % 60);
};
