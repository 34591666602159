import { Merge } from 'types';
import {
  ErrorType,
  PlaybackEvent,
  SeekType,
  StartType,
  ViewContext,
} from 'types/analytics';
import { EpgChannel, Live, Video } from 'types/api/media';
import { PageId } from 'types/pageId';
import { Ad } from 'types/player';
import { ListItemContext } from '../../types/events';
import { secondsToMilliseconds } from 'support/dateUtils';

export type AdType = 'pre-roll' | 'mid-roll' | 'post-roll';
type ProgressTime = {
  relativeTime: number; // either current content time without ads, or ad time within ad pod
  rawTime: number; // time from video player (content + ads)
};

// handling the argument type this way allows us to add additional args for specific events
type ReportPlaybackArgs =
  | [event: PlaybackEvent, progressTime: ProgressTime]
  | [event: PlaybackEvent, progressTime: ProgressTime, type: undefined]
  | [event: PlaybackEvent.START, progressTime: ProgressTime, type: StartType]
  | [
      event: PlaybackEvent.END,
      progressTime: ProgressTime,
      state: {
        hasEnded: boolean;
        isAdPlaying: boolean;
      },
    ]
  | [
      event: PlaybackEvent.SEEK_START,
      progressTime: ProgressTime,
      type: SeekType,
    ]
  | [event: PlaybackEvent.AD_START, progressTime: ProgressTime, ad: Ad];

const getAdType = (ad: Ad): AdType => {
  let type: AdType;

  const podIndex = ad.getAdPodInfo().getPodIndex();
  if (podIndex === 0) {
    type = 'pre-roll';
  } else if (podIndex === -1) {
    type = 'post-roll';
  } else {
    type = 'mid-roll';
  }

  return type;
};

const reportStart = (progressTime: ProgressTime, type?: StartType) => {
  console.log('[reporting service video] reportStart');

  window.analytics.comscore.reportStart(progressTime.rawTime);
  window.analytics.mParticle.video.reportStart(progressTime.rawTime, type);
  window.analytics.nielsen.reportStart(progressTime.relativeTime);
};

const reportResume = (progressTime: ProgressTime) => {
  console.log('[reporting service video] reportResume');

  window.analytics.comscore.reportResume(progressTime.relativeTime);
  window.analytics.nielsen.reportResume();
  window.analytics.mParticle.video.reportResume(progressTime.rawTime);
};

const reportPause = (progressTime: ProgressTime) => {
  console.log('[reporting service video] reportPause');

  window.analytics.comscore.reportPause();
  window.analytics.nielsen.reportPaused(progressTime.relativeTime);
  window.analytics.mParticle.video.reportPause(progressTime.rawTime);
};

const reportSeekPause = (progressTime: ProgressTime) => {
  console.log('[reporting service video] reportSeekPause');

  window.analytics.comscore.reportSeekStart(progressTime.relativeTime);
};

const reportBeforeSeek = (progressTime: ProgressTime) => {
  console.log('[reporting service video] reportBeforeSeek');

  window.analytics.comscore.reportSeekStart(progressTime.relativeTime);
  window.analytics.nielsen.reportBeforeSeek(progressTime.relativeTime);
};

const reportSeekStart = (progressTime: ProgressTime, type?: SeekType) => {
  console.log('[reporting service video] reportSeekStart');

  window.analytics.comscore.reportSeekStart(progressTime.relativeTime);
  window.analytics.mParticle.video.reportSeek(progressTime.rawTime, type);
};

const reportSeekEnd = (progressTime: ProgressTime) => {
  console.log('[reporting service video] reportSeekEnd');

  window.analytics.comscore.reportSeekEnd(progressTime.relativeTime);
  window.analytics.nielsen.reportSeekEnd(progressTime.relativeTime);
};

const reportBufferStart = (progressTime: ProgressTime) => {
  console.log('[reporting service video] reportBufferStart');

  window.analytics.comscore.reportBufferStart(progressTime.relativeTime);
};

const reportBufferEnd = (progressTime: ProgressTime) => {
  console.log('[reporting service video] reportBufferEnd');

  window.analytics.comscore.reportBufferEnd(progressTime.relativeTime);
};

const reportAdPodStart = (progressTime: ProgressTime) => {
  console.log('[reporting service video] reportAdPodStart');

  window.analytics.nielsen.reportAdPodStart(progressTime.relativeTime);
  window.analytics.conviva.reportAdPodStart();
};

const reportAdStart = (progressTime: ProgressTime, ad?: Ad) => {
  console.log('[reporting service video] reportAdStart');

  const type = ad ? getAdType(ad) : 'pre-roll'; // pre-roll as a fallback, this should never occur
  const duration = secondsToMilliseconds(ad!.getDuration() ?? 0);

  window.analytics.comscore.reportAdStart(type, duration);
  window.analytics.mParticle.video.reportAdStart(progressTime.rawTime);
  window.analytics.nielsen.reportAdStart();
};

const reportAdEnd = (progressTime: ProgressTime) => {
  console.log('[reporting service video] reportAdEnd');

  window.analytics.nielsen.reportAdEnd(progressTime.relativeTime);
  window.analytics.mParticle.video.reportAdEnd(progressTime.rawTime);
};

const reportAdPodEnd = (progressTime: ProgressTime) => {
  console.log('[reporting service video] reportAdPodEnd');

  window.analytics.comscore.reportAdPodEnd();
  window.analytics.conviva.reportAdPodEnd();
  window.analytics.nielsen.reportAdPodEnd(progressTime.relativeTime);
};

const reportCreditsReached = (progressTime: ProgressTime) => {
  console.log('[reporting service video] reportCreditsReached');

  window.analytics.mParticle.video.reportCreditsReached(progressTime.rawTime);
};

const reportEnd = (
  progressTime: ProgressTime,
  hasEnded: boolean,
  isAdPlaying: boolean,
) => {
  console.log('[reporting service video] reportEnd');

  window.analytics.conviva.reportPlaybackEnded(isAdPlaying);
  window.analytics.comscore.reportEnd();
  window.analytics.nielsen.reportEnd(progressTime.relativeTime, hasEnded);
  window.analytics.mParticle.video.reportEnd(progressTime.rawTime, hasEnded);
};

const reportComplete = (progressTime: ProgressTime) => {
  console.log('[reporting service video] reportComplete');

  window.analytics.nielsen.reportComplete(progressTime.relativeTime);
};

export const reportPlayback = (
  ...[event, progressTime, additionalArg]: ReportPlaybackArgs
) => {
  const progressTimeInMs: ProgressTime = {
    relativeTime: progressTime.relativeTime * 1000,
    rawTime: progressTime.rawTime * 1000,
  };

  switch (event) {
    case PlaybackEvent.START:
      reportStart(progressTimeInMs, additionalArg);
      break;
    case PlaybackEvent.PAUSE:
      reportPause(progressTimeInMs);
      break;
    case PlaybackEvent.SEEK_PAUSE:
      reportSeekPause(progressTimeInMs);
      break;
    case PlaybackEvent.RESUME:
      reportResume(progressTimeInMs);
      break;
    case PlaybackEvent.SEEK_START:
      reportSeekStart(progressTimeInMs, additionalArg);
      break;
    case PlaybackEvent.BEFORE_SEEK:
      reportBeforeSeek(progressTimeInMs);
      break;
    case PlaybackEvent.SEEK_END:
      reportSeekEnd(progressTimeInMs);
      break;
    case PlaybackEvent.BUFFER_START:
      reportBufferStart(progressTimeInMs);
      break;
    case PlaybackEvent.BUFFER_END:
      reportBufferEnd(progressTimeInMs);
      break;
    case PlaybackEvent.AD_POD_START:
      reportAdPodStart(progressTimeInMs);
      break;
    case PlaybackEvent.AD_START:
      reportAdStart(progressTimeInMs, additionalArg);
      break;
    case PlaybackEvent.AD_END:
      reportAdEnd(progressTimeInMs);
      break;
    case PlaybackEvent.AD_POD_END:
      reportAdPodEnd(progressTimeInMs);
      break;
    case PlaybackEvent.CREDITS_REACHED:
      reportCreditsReached(progressTimeInMs);
      break;
    case PlaybackEvent.END:
      reportEnd(
        progressTimeInMs,
        !!additionalArg?.hasEnded,
        !!additionalArg?.isAdPlaying,
      );
      break;
    case PlaybackEvent.COMPLETE:
      reportComplete(progressTimeInMs);
      break;
    default:
      break;
  }
};

export const reportHeartbeat = (relativeTime: number) => {
  const relativeTimeInMs = relativeTime * 1000;

  window.analytics.nielsen.handleHeartbeat(relativeTimeInMs);
  window.analytics.conviva.reportAverageBitRate();
};

export const reportContentMetadata = (args: {
  mediaContent: Merge<Merge<Video, Live>, EpgChannel>;
  fromPageId: PageId | null;
  viewContext: ViewContext | null;
  listItemContext?: ListItemContext;
}) => {
  const { mediaContent, fromPageId, viewContext, listItemContext } = args;
  window.analytics.conviva.reportContentPlayback(mediaContent, viewContext);
  window.analytics.comscore.setContentMetadata(mediaContent);
  window.analytics.nielsen.setContentMetadata(mediaContent);
  window.analytics.mParticle.video.setContentPayload(
    mediaContent,
    fromPageId,
    listItemContext,
  );
};

export const reportAdMetadata = (ad: Ad) => {
  const type: AdType = getAdType(ad);

  window.analytics.nielsen.setAdMetadata(ad, type);
  window.analytics.permutive.reportAdPlay(ad);
};

export const reportBitrate = (bitrate: number) => {
  window.analytics.conviva.reportBitrate(bitrate);
};

export const reportPlaybackError = (
  isFatal: boolean,
  errorType: ErrorType,
  message: string,
) => {
  if (isFatal) {
    window.analytics.conviva.reportPlaybackFailed(message);
  } else {
    window.analytics.conviva.reportPlaybackWarning(message);
  }

  window.analytics.mParticle.video.reportPlaybackError(errorType, message);
};

export const reportBackgrounded = () => {
  window.analytics.conviva.reportBackgrounded();
  window.analytics.comscore.reportBackgrounded();
};

export const reportForegrounded = () => {
  window.analytics.conviva.reportForegrounded();
  window.analytics.comscore.reportForegrounded();
};

export const releaseAnalytics = () => {
  if (window.analytics) {
    window.analytics.conviva?.release?.();
    window.analytics.nielsen?.endSession?.();
  }
};
