import { AppData, Colors, Lightning, Registry } from '@lightningjs/sdk';
import { Video } from 'types/api/media';
import EndCardTile from './EndCardTile';
import { getFontFaceFromStyle } from 'support/textUtils';
import { translate } from 'support/translate';
import Button from 'components/common/Button';
import { getImageTextureObj } from 'support/generalUtils';
import { StateEvent } from 'types/lightning';
import constants from '../../../../static/constants.json';
import { StaticViewContexts, ViewContext } from 'types/analytics';

type EndCardContent = {
  recommended: Video[];
  current: Video;
};

export interface EndCardTemplateSpec extends Lightning.Component.TemplateSpec {
  content: EndCardContent;
  isEnd: boolean;
  Background: Lightning.Component;
  Container: {
    BackButton: typeof Button;
    WatchCreditsButton: typeof Button;
    TileContainers: {
      NextContainer: {
        UpNextText: Lightning.Component;
        UpNextTile: typeof EndCardTile;
      };
      RecommendedContainer: {
        RecommendedText: Lightning.Component;
        RecommendedList: {
          FirstRecommendation: typeof EndCardTile;
          SecondRecommendation: typeof EndCardTile;
        };
      };
    };
  };
}

const BACK_ICON_FOCUSED = getImageTextureObj(
  'static/images/back-button-icon-active.svg',
  44,
  38,
);
const BACK_ICON_UNFOCUSED = getImageTextureObj(
  'static/images/back-button-icon-inactive.svg',
  44,
  38,
);

const COUNTDOWN_START = 20;
const IDLE_DURATION_MILLISECONDS = 10000;

const ENDCARD_WIDTH = 1920;
const ENDCARD_HEIGHT = 1080;

const BACK_BUTTON_X = 96;
const BACK_BUTTON_Y = 60;
const BACK_BUTTON_W = 314;
const BACK_BUTTON_H = 50;
const BACK_BUTTON_FONT_SIZE = 29;
const BACK_BUTTON_PADDING = 4;
const BACK_BUTTON_PADDINGH = 8;
const BACK_BUTTON_MARGIN = 14;

const CREDITS_BUTTON_X = 1825;
const CREDITS_BUTTON_Y = 56;
const CREDITS_BUTTON_W = 245;
const CREDITS_BUTTON_H = 68;
const CREDITS_BUTTON_MOUNT_X = 1;
const CREDITS_BUTTON_PADDINGH = 30;
const CREDITS_BUTTON_RADIUS = 0;
const CREDITS_BUTTON_FONT_SIZE = 29;

const NEXT_CONTAINER_X = 114;
const NEXT_CONTAINER_Y = 341;

const NEXT_TEXT_FONT_SIZE = 32;

const RECOMMENDED_CONTAINER_X = 938;
const RECOMMENDED_CONTAINER_Y = 402;
const RECOMMENDED_MARGIN = 44;
export interface EndCardTemplateSpec extends Lightning.Component.TemplateSpec {
  content: EndCardContent;
  isEnd: boolean;
  Background: Lightning.Component;
  BackButton: typeof Button;
  WatchCreditsButton: typeof Button;
  Containers: {
    NextContainer: {
      UpNextText: Lightning.Component;
      UpNextTile: typeof EndCardTile;
    };
    RecommendedContainer: {
      RecommendedText: Lightning.Component;
      RecommendedList: {
        FirstRecommendation: typeof EndCardTile;
        SecondRecommendation: typeof EndCardTile;
      };
    };
  };
}

interface EndCardSignalMap extends Lightning.Component.SignalMap {
  backToSeries: void;
  watchCredits: void;
  play(video: Video, viewContext: ViewContext, index: number): void;
}

interface EndCardTypeConfig extends Lightning.Component.TypeConfig {
  SignalMapType: EndCardSignalMap;
}

const UNFOCUSED_ALPHA = 0.6;

const INVISIBLE = constants.ui.invisible;

export default class EndCard
  extends Lightning.Component<EndCardTemplateSpec, EndCardTypeConfig>
  implements Lightning.Component.ImplementTemplateSpec<EndCardTemplateSpec>
{
  private _recommended: Video[] | null = null;
  private _currentContent: Video | null = null;
  private _isEnd = false;
  private _shown = false;

  private _Container = this.getByRef('Container')!;
  private _BackButton = this._Container.getByRef('BackButton')!;
  private _WatchCreditsButton = this._Container.getByRef('WatchCreditsButton')!;
  private _TileContainers = this._Container.getByRef('TileContainers')!;
  private _NextContainer = this._TileContainers.getByRef('NextContainer')!;
  private _UpNextText = this._NextContainer.getByRef('UpNextText')!;
  private _UpNextTile = this._NextContainer.getByRef('UpNextTile')!;
  private _RecommendedContainer = this._TileContainers.getByRef(
    'RecommendedContainer',
  )!;
  private _RecommendedText =
    this._RecommendedContainer.getByRef('RecommendedText')!;
  private _RecommendedList =
    this._RecommendedContainer.getByRef('RecommendedList')!;
  private _FirstRecommendation = this._RecommendedList.getByRef(
    'FirstRecommendation',
  )!;
  private _SecondRecommendation = this._RecommendedList.getByRef(
    'SecondRecommendation',
  )!;

  private _countdownValue = COUNTDOWN_START;
  private _countdownStarted = false;
  private _countdownTimerId: any = null;

  private _idleTimerId: any = null;

  set content(content: EndCardContent) {
    this._recommended = content.recommended;
    this._currentContent = content.current;
    if (this._recommended[0]) {
      this._UpNextTile.patch({
        mediaItem: this._recommended[0],
        showTile: !this.isSameSlug(this._recommended[0], this._currentContent),
      });
    }

    let recommendationIndex = 0;
    if (this._recommended[1]) {
      this._RecommendedText.patch({ alpha: 1 });
      this._FirstRecommendation.patch({
        mediaItem: this._recommended[1],
        showTile: !this.isSameSlug(this._recommended[1], this._currentContent),
        alpha: 1,
      });
      recommendationIndex++;
      if (this._recommended[2]) {
        this._SecondRecommendation.patch({
          mediaItem: this._recommended[2],
          showTile: !this.isSameSlug(
            this._recommended[2],
            this._currentContent,
          ),
          alpha: 1,
        });
        recommendationIndex++;
        this._SecondRecommendation.announceContext = translate(
          'ttsPrompts.listInfo',
          2,
          recommendationIndex,
        );
      }
      this._FirstRecommendation.announceContext = translate(
        'ttsPrompts.listInfo',
        1,
        recommendationIndex,
      );
    }

    this._BackButton.setStartIcon(BACK_ICON_UNFOCUSED);
  }

  set isEnd(value: boolean) {
    this._isEnd = value;
    this._WatchCreditsButton.visible = !value;
    if (this._getState() === 'Credits') this._setState('BackButton');
  }

  static override _template(): Lightning.Component.Template<EndCardTemplateSpec> {
    return {
      Background: {
        rect: true,
        w: ENDCARD_WIDTH,
        h: ENDCARD_HEIGHT,
        colorTop: Colors('appBackground').alpha(0.79).get(),
        colorBottom: Colors('appBackground').get(),
      },
      Container: {
        alpha: INVISIBLE,
        BackButton: {
          type: Button,
          x: BACK_BUTTON_X,
          y: BACK_BUTTON_Y,
          w: BACK_BUTTON_W,
          height: BACK_BUTTON_H,
          margin: BACK_BUTTON_MARGIN,
          padding: BACK_BUTTON_PADDING,
          paddingH: BACK_BUTTON_PADDINGH,
          fontSize: BACK_BUTTON_FONT_SIZE,
          label: translate('endCard.backToDetailsPage'),
          fontColor: {
            unfocused: Colors('text').alpha(0.65).get(),
            focused: Colors('text').get(),
          },
          backgroundColor: {
            unfocused: Colors('transparent').get(),
            focused: Colors('transparent').get(),
          },
          action: '$backToSeries',
          signals: { $backToSeries: '$backToSeries', $onHover: '$onHover' },
        },
        WatchCreditsButton: {
          type: Button,
          x: CREDITS_BUTTON_X,
          y: CREDITS_BUTTON_Y,
          w: CREDITS_BUTTON_W,
          height: CREDITS_BUTTON_H,
          paddingH: CREDITS_BUTTON_PADDINGH,
          radius: CREDITS_BUTTON_RADIUS,
          fontSize: CREDITS_BUTTON_FONT_SIZE,
          mountX: CREDITS_BUTTON_MOUNT_X,
          label: translate('endCard.credits'),
          action: '$watchCredits',
          signals: { $watchCredits: '$watchCredits', $onHover: '$onHover' },
        },
        TileContainers: {
          NextContainer: {
            x: NEXT_CONTAINER_X,
            y: NEXT_CONTAINER_Y,
            flex: { direction: 'column' },
            UpNextText: {
              alpha: INVISIBLE,
              text: {
                text: translate('endCard.upNext'),
                fontSize: NEXT_TEXT_FONT_SIZE,
                fontFace: getFontFaceFromStyle('regular'),
                textColor: Colors('text').get(),
              },
            },
            UpNextTile: {
              type: EndCardTile,
              announce: translate('ttsPrompts.endCard.upNext'),
              signals: {
                $onHover: '$onHover',
                $onTileSelected: '$onTileSelected',
              },
            },
          },
          RecommendedContainer: {
            x: RECOMMENDED_CONTAINER_X,
            y: RECOMMENDED_CONTAINER_Y,
            flex: { direction: 'column' },
            RecommendedText: {
              alpha: INVISIBLE,
              text: {
                text: translate('endCard.recommendation'),
                fontSize: 32,
                fontFace: getFontFaceFromStyle('bold'),
                maxLines: 1,
                textColor: Colors('text').get(),
              },
            },
            RecommendedList: {
              flex: { direction: 'row' },
              FirstRecommendation: {
                type: EndCardTile,
                size: 'sub',
                alpha: 0,
                announce: translate('ttsPrompts.endCard.recommendation'),
                signals: {
                  $onHover: '$onHover',
                  $onTileSelected: '$onTileSelected',
                },
              },
              SecondRecommendation: {
                flexItem: { marginLeft: RECOMMENDED_MARGIN },
                type: EndCardTile,
                size: 'sub',
                alpha: 0,
                announce: translate('ttsPrompts.endCard.recommendation'),
                signals: {
                  $onHover: '$onHover',
                  $onTileSelected: '$onTileSelected',
                },
              },
            },
          },
        },
      },
    };
  }

  private setupUpNextText() {
    let upNextString = translate('endCard.upNext');

    const autoPlaySettings = AppData!.storageService.autoplaySettings.get();
    // Assume autoplay is true if unset
    if (autoPlaySettings ?? true)
      upNextString = translate('endCard.upNextSec', this._countdownValue);

    this._UpNextText.patch({
      text: { text: upNextString },
    });
  }

  private showUpNextText() {
    this._UpNextText.setSmooth('alpha', 1);
  }

  private hideUpNextText() {
    this._UpNextText.setSmooth('alpha', 0);
  }

  private startCountdown(suppressTts: boolean) {
    if (this._countdownStarted) return;

    if (!suppressTts) {
      // change announce temporarily for countdown tts
      this._UpNextTile.announce = translate(
        'ttsPrompts.endCard.upNextSec',
        this._countdownValue,
      );

      this.fireAncestors('$announce', this._UpNextTile.announce.join(', '), {
        append: true,
      });
      // reset announce for focus tts
      this._UpNextTile.announce = translate('ttsPrompts.endCard.upNext');
    }

    this._countdownStarted = true;

    this._countdownTimerId = Registry.setInterval(() => {
      this._countdownValue--;

      if (this._countdownValue <= 0) {
        this.endCountdown();
        this.signalUpNext();
        return;
      }

      this._UpNextText.patch({
        text: { text: translate('endCard.upNextSec', this._countdownValue) },
      });
    }, 1000);
  }

  private resetCountdown() {
    this.endCountdown();
    this._countdownStarted = false;
    this._countdownValue = COUNTDOWN_START;
  }

  private endCountdown() {
    if (this._countdownTimerId) Registry.clearInterval(this._countdownTimerId);
    this._countdownTimerId = null;
    this._UpNextText.setSmooth('alpha', 0);
  }

  private restartCountdown(firstCountdown = false) {
    const autoPlaySettings = AppData!.storageService.autoplaySettings.get();

    // Assume autoplay is true if unset
    if (autoPlaySettings ?? true) {
      this.resetCountdown();
      this.endIdleCountdown();
      this.setupUpNextText();
      this.showUpNextText();
      this.startCountdown(firstCountdown);
    } else {
      this.endCountdown();
      this.setupUpNextText();
      this.showUpNextText();
    }
  }

  private startIdleCountdown() {
    this.endCountdown();
    if (this._idleTimerId) this.endIdleCountdown();

    this._idleTimerId = Registry.setTimeout(() => {
      this.restartCountdown();
      this._idleTimerId = null;
    }, IDLE_DURATION_MILLISECONDS);
  }

  private endIdleCountdown() {
    Registry.clearTimeout(this._idleTimerId);
    this._idleTimerId = null;
  }

  private signalUpNext() {
    if (this._UpNextTile.mediaItem)
      this.signal(
        'play',
        this._UpNextTile.mediaItem,
        StaticViewContexts.END_CARD_AUTOPLAY,
        0,
      );
  }

  private isSameSlug(firstItem: Video, secondItem: Video): boolean {
    return firstItem.showSlug === secondItem.showSlug;
  }

  override _attach() {
    this.setupUpNextText();
  }

  override _enable() {
    const fadeInAnimation = this._Container.animation({
      duration: 0.5,
      delay: 0.5,
      actions: [{ p: 'alpha', v: { 0: 0, 1: 1 } }],
    });

    fadeInAnimation.on('finish', () => {
      const UpNextTextFadeInAnimation = this._UpNextText.animation({
        duration: 0.3,
        actions: [{ p: 'alpha', v: { 0: 0, 1: 1 } }],
      });

      // need to reset if user closes end card and it enables on hitting the end of content
      this.resetCountdown();
      const autoPlaySettings = AppData!.storageService.autoplaySettings.get();
      // set the announce before first focus of the EndCard
      this._UpNextTile.announce =
        autoPlaySettings ?? true
          ? translate('ttsPrompts.endCard.upNextSec', this._countdownValue)
          : translate('ttsPrompts.endCard.upNext');
      this._setState('RecommendationSection', [true]);
      UpNextTextFadeInAnimation.on('finish', () => {
        this._shown = true;
        this.restartCountdown(true);
      });
      UpNextTextFadeInAnimation.start();
    });
    fadeInAnimation.start();
  }

  override _disable() {
    this.hideUpNextText();
    this._Container.setSmooth('alpha', 0);
    // unsetting the state so we can reuse the EndCardTile's focus animation when we reenable EndCard.
    this._setState('');
    this._shown = false;
    this.endIdleCountdown();
  }

  override _captureKey() {
    return !this._shown;
  }

  $watchCredits() {
    this.signal('watchCredits');
  }

  $backToSeries() {
    this.signal('backToSeries');
  }

  $onHover(target: any) {
    switch (target) {
      case this._BackButton:
        this._setState('BackButton');
        break;
      case this._WatchCreditsButton:
        this._setState('Credits');
        break;
      case this._UpNextTile:
        this._setState('RecommendationSection.UpNext');
        break;
      case this._FirstRecommendation:
        this._setState('RecommendationSection.FirstRecommendation');
        break;
      case this._SecondRecommendation:
        this._setState('RecommendationSection.SecondRecommendation');
        break;
    }
    this.resetCountdown();
  }

  private $onTileSelected(target: any) {
    let index;
    switch (target) {
      case this._UpNextTile:
        index = 0;
        break;
      case this._FirstRecommendation:
        index = 1;
        break;
      case this._SecondRecommendation:
        index = 2;
        break;
      default:
        index = 0;
    }

    if (target.mediaItem) {
      window.analytics.mParticle.video.reportPlayRecommendation(
        'clicked',
        target.mediaItem,
      );
      this.signal(
        'play',
        target.mediaItem,
        StaticViewContexts.END_CARD_USER_SELECTED,
        index,
      );
    }
  }

  override _getFocused(): EndCardTile | Button | null {
    // suppress the focus until the Upnext fade in animation
    return null;
  }

  static override _states() {
    return [
      class RecommendationSection extends this {
        private _previousState = '';

        override $enter(stateObj: StateEvent, reset: boolean) {
          this._UpNextTile.setSmooth('alpha', 1);
          this._RecommendedContainer.setSmooth('alpha', 1);
          if (stateObj.newState === 'RecommendationSection') {
            // Entered by handleDown()
            if (!this._previousState || reset) {
              this._previousState = 'RecommendationSection.UpNext';
            }
            this._setState(this._previousState);
          } else {
            // Entered by hovering
            this._setState(stateObj.newState);
          }
        }

        override $exit(event: StateEvent) {
          if (event.newState) {
            this._UpNextTile.setSmooth('alpha', UNFOCUSED_ALPHA);
            this._RecommendedContainer.setSmooth('alpha', UNFOCUSED_ALPHA);
          }
        }

        override _handleUp() {
          this.startIdleCountdown();
          this._setState('BackButton');
        }

        static override _states() {
          return [
            class UpNext extends RecommendationSection {
              override $exit(event: StateEvent) {
                this.startIdleCountdown();
                this._UpNextTile.announce = translate(
                  'ttsPrompts.endCard.upNext',
                );
                this._previousState = event.prevState;
              }

              override _getFocused() {
                return this._UpNextTile;
              }

              override _handleRight() {
                if (this._FirstRecommendation.alpha)
                  this._setState('RecommendationSection.FirstRecommendation');
              }
            },

            class FirstRecommendation extends RecommendationSection {
              override $exit(event: StateEvent) {
                this.startIdleCountdown();
                this._previousState = event.prevState;
              }

              override _getFocused() {
                return this._FirstRecommendation;
              }

              override _handleRight() {
                if (this._SecondRecommendation.alpha)
                  this._setState('RecommendationSection.SecondRecommendation');
              }

              override _handleLeft() {
                this._setState('RecommendationSection.UpNext');
              }
            },

            class SecondRecommendation extends RecommendationSection {
              override $exit(event: StateEvent) {
                this.startIdleCountdown();
                this._previousState = event.prevState;
              }

              override _getFocused() {
                return this._SecondRecommendation;
              }

              override _handleLeft() {
                if (this._FirstRecommendation.alpha)
                  this._setState('RecommendationSection.FirstRecommendation');
              }
            },
          ];
        }
      },

      class Credits extends this {
        override $exit() {
          this.startIdleCountdown();
        }

        override _handleDown() {
          if (this._SecondRecommendation.alpha)
            this._setState('RecommendationSection');
        }

        override _handleLeft() {
          this._setState('BackButton');
        }

        override _handleUp() {
          return;
        }

        override _getFocused() {
          return this._WatchCreditsButton;
        }
      },

      class BackButton extends this {
        override $enter() {
          this._BackButton.setStartIcon(BACK_ICON_FOCUSED);
        }

        override $exit() {
          this._BackButton.setStartIcon(BACK_ICON_UNFOCUSED);
          this.startIdleCountdown();
        }

        override _handleDown() {
          this._setState('RecommendationSection');
        }

        override _handleUp() {
          return;
        }

        override _handleRight() {
          if (this._WatchCreditsButton.visible) this._setState('Credits');
        }

        override _getFocused() {
          return this._BackButton;
        }
      },
    ];
  }
}
