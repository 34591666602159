import { Colors, Lightning } from '@lightningjs/sdk';
import { HoverableComponent } from './HoverableComponent';
import { Toggle } from '@lightningjs/ui-components';

export interface ToggleSliderTemplateSpec
  extends Lightning.Component.TemplateSpec {
  isActive: boolean;
  Toggle: any;
}

export default class ToggleSlider
  extends HoverableComponent<ToggleSliderTemplateSpec>
  implements
    Lightning.Component.ImplementTemplateSpec<ToggleSliderTemplateSpec>
{
  private _Toggle = this.getByRef('Toggle')!;

  private _isActive = false;

  set isActive(isActive: boolean) {
    this._isActive = isActive;
    const isChecked = this._Toggle.checked;

    if (this._isActive !== isChecked) {
      this._Toggle.toggle();
    }
  }

  get isActive() {
    return this._isActive;
  }

  static override _template(): Lightning.Component.Template<ToggleSliderTemplateSpec> {
    return {
      h: 42,
      w: 80,
      Toggle: {
        type: Toggle,
        style: {
          strokeColor: Colors('appAccent').get(),
          backgroundColor: Colors('appBackground').get(),
          backgroundColorChecked: Colors('appAccent').get(),
          knobColor: Colors('white').get(),
          knobColorChecked: Colors('white').get(),
        },
      },
    };
  }

  $announce() {
    // Leave empty to intercept the "checked/unchecked" announce signal from Toggle component
  }

  override _setup() {
    super._setup();
  }
}
