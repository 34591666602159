import { Lightning, Registry } from '@lightningjs/sdk';

export function debounce<TArgs extends unknown[]>(
  fn: (...args: TArgs) => unknown,
  timeout = 1000,
) {
  let timer: ReturnType<typeof Registry.setTimeout>;

  return (...args: TArgs) => {
    if (timer) Registry.clearTimeout(timer);

    timer = Registry.setTimeout(() => {
      fn(...args);
      timer = undefined;
    }, timeout);
  };
}

// TODO: confirm if this is the best way to check/get window dimensions
export const is1280x720p = () => {
  return window.innerWidth === 1280 || window.innerHeight === 720;
};

export const getScalingMultiplier = () => {
  return is1280x720p() ? 1.5 : 1;
};

export const getImageTextureObj = (
  imagePath: string,
  width: number,
  height: number,
): Lightning.Element.PatchTemplate<any> => {
  if (imagePath.endsWith('.svg')) {
    return {
      texture: Lightning.Tools.getSvgTexture(imagePath, width, height),
    };
  } else {
    return {
      texture: {
        type: Lightning.textures.ImageTexture,
        src: imagePath,
        resizeMode: { type: 'cover', w: width, h: height, clipY: 0 },
      },
    };
  }
};

export const getImageTextureContainObj = (
  imagePath: string,
  width: number,
  height: number,
): Lightning.Element.PatchTemplate<any> => {
  return {
    texture: {
      type: Lightning.textures.ImageTexture,
      src: imagePath,
      resizeMode: {
        type: 'contain',
        w: width,
        h: height,
      },
    },
  };
};

export function getLastChildLeaf(ref: Lightning.Element) {
  if (!ref.hasChildren()) return ref;

  let currentChild = ref.children[ref.children.length - 1] as Lightning.Element;
  while (currentChild?.hasChildren()) {
    currentChild = currentChild.children[
      currentChild.children.length - 1
    ] as Lightning.Element;
  }
  return currentChild;
}

/**
 * Returns the focus depth of the given component
 *
 * @param application - Lightning application
 * @param component - Lightning component
 * @returns the focus depth of the given component if component exists in the focus path, otherwise, returns 0
 */
export const getFocusDepth = (
  application: Lightning.Application,
  component: Lightning.Component,
) => {
  const { focusPath = [] } = application;
  for (let i = 0; i < focusPath.length; i++) {
    if (focusPath[i]?.id === component.id) return i;
  }

  return 0;
};

export const ttsTagRemoval = (text: string) => {
  return text.replace(/<[^<>]*>/g, '');
};

export const roundMilliseconds = (time: number) => {
  return Math.ceil(time * 1000) / 1000;
};

/**
 * Gets the base domain of a URL. Path should be in the form [http|https]://[DOMAIN]/[PARAMS]
 * @param url URL string we want to get the base domain from
 */
export const getBaseDomainFromUrl = (url: string) => {
  const splitUrl = url.split('/');
  if (splitUrl.length < 3) return '';

  const protocol = splitUrl[0];
  if (protocol !== 'http:' && protocol !== 'https:') return '';

  const domain = splitUrl[2];
  return `${protocol}//${domain}`;
};

export const convertHexColor = (
  color: string,
  hexOpacity = 'ff',
): number | undefined => {
  let colorValue: string;

  if (color.startsWith('#')) {
    colorValue = `0x${hexOpacity}${color.split('#')[1] ?? '0'}`;
  } else {
    colorValue = `0x${hexOpacity}${color}`;
  }

  const value = Number(colorValue);
  if (Number.isNaN(value)) return undefined;

  return value;
};

export const separateHexColorAlpha = (hex: string) => {
  if (hex.startsWith('#') && hex.length === 9) {
    const color = hex.slice(0, 7);
    const alpha = hex.slice(7);
    return { color, alpha };
  }
};
