import { Lightning } from '@lightningjs/sdk';
import SizedRowList, {
  ROW_LIST_OFFSET_Y,
} from 'components/common/SizedRowList';
import { LiveEventChannel } from 'types/api/media';
import { translate } from 'support/translate';
import LiveEventTile from 'components/common/tiles/LiveEventTile';

export interface LiveEventRecommendedSectionPageSpec
  extends Lightning.Component.TemplateSpec {
  liveEvents: LiveEventChannel[];

  RecommendedList: typeof SizedRowList;
}

const HORIZONTAL_MARGIN = 96;
const VERTICAL_MARGIN = 40;

export default class LiveEventRecommendedSection
  extends Lightning.Component<LiveEventRecommendedSectionPageSpec>
  implements
    Lightning.Component
      .ImplementTemplateSpec<LiveEventRecommendedSectionPageSpec>
{
  private _RecommendedList = this.getByRef('RecommendedList')!;

  static get h() {
    return LiveEventTile.height + ROW_LIST_OFFSET_Y + VERTICAL_MARGIN * 2;
  }

  get index() {
    return this._RecommendedList.index;
  }

  get label() {
    return this._RecommendedList.label;
  }

  set liveEvents(liveEvents: LiveEventChannel[]) {
    this._RecommendedList.visible = !!liveEvents.length;
    this._RecommendedList.items = liveEvents.map(this.buildTile);
    this._RecommendedList.label = translate('liveEventDetail.moreLiveEvents');
  }

  static override _template(): Lightning.Component.Template<LiveEventRecommendedSectionPageSpec> {
    return {
      RecommendedList: {
        type: SizedRowList,
        // @ts-ignore
        listType: LiveEventTile,
        x: HORIZONTAL_MARGIN,
        y: VERTICAL_MARGIN,
        w: 1920 - 2 * HORIZONTAL_MARGIN,
        itemWidth: LiveEventTile.width,
        signals: {
          $onHover: '$onHover',
        },
      },
    };
  }

  override _getFocused() {
    return this._RecommendedList;
  }

  $onHover() {
    this.signal('$onHover', this);
  }

  private buildTile(liveEvent: LiveEventChannel) {
    return {
      imageUrl: liveEvent.images.eventThumbnail,
      data: liveEvent,
    };
  }
}
