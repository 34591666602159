import { AppData, Metadata } from '@lightningjs/sdk';
import { getScalingMultiplier } from './generalUtils';

export const getVodAdTagParams = async (
  guid: string,
  w: number,
  h: number,
): Promise<Record<string, string>> => {
  if (!AppData?.adParams) return {};

  const [deviceInfo, cohorts] = await Promise.all([
    AppData.device.getDeviceInfo(),
    window.analytics.permutive.getCohorts(),
  ]);

  const deviceAdId = deviceInfo.adId;
  const isLat = `${AppData.privacyService.getRdpTag()}`;
  const uoo = AppData.privacyService.getDoNotSell();
  const usPrivacy = AppData.privacyService.getIabUsPrivacy();
  const ip = deviceInfo.ip;
  const osVersion = deviceInfo.osVersion;
  const appVersion = Metadata.appVersion() ?? '';
  const userAgent = window.navigator.userAgent;
  const width = `${w || 1920 / getScalingMultiplier()}`;
  const height = `${h || 1080 / getScalingMultiplier()}`;
  const cacheBuster = `${new Date().getTime()}`;

  const adTagParams = getVodCohortAdTagObj(cohorts);

  Object.entries(AppData.adParams.platform.vod_params).forEach(
    ([key, value]) => {
      // Ignore additional cohort keys since they're already added to params
      if (key.includes('{{COHORT}}')) return;

      const modifiedValue = value
        .replace(/{{app.APP_VERSION}}/g, appVersion)
        .replace(/{{app.is_lat}}/g, isLat)
        .replace(/{{app.OS_VERSION}}/g, osVersion)
        .replace(/{{app.us_privacy}}/g, usPrivacy)
        .replace(/{{CACHEBUSTER}}/g, cacheBuster)
        .replace(/{{CW_GUID}}/g, guid)
        .replace(/{{DEVICE_ID}}/g, deviceAdId)
        .replace(/{{HEIGHT}}/g, height)
        .replace(/{{IP}}/g, ip)
        .replace(/{{USERAGENT}}/g, userAgent)
        .replace(/{{UserOptOut}}/g, `${uoo}`) // we've seen this passed as both UserOptOut and userOptOut
        .replace(/{{userOptOut}}/g, `${uoo}`)
        .replace(/{{WIDTH}}/g, width);

      adTagParams[key] = modifiedValue;
    },
  );

  return adTagParams;
};

export const stitchAdParamsToLiveStream = async (
  streamUrl: string,
  liveStreamType: string,
  adZone: string,
): Promise<string> => {
  if (!AppData?.adParams) return streamUrl;
  const parsedStreamUrl = new URL(streamUrl);
  const baseStreamUrl = parsedStreamUrl.origin + parsedStreamUrl.pathname;

  const [deviceInfo, cohorts] = await Promise.all([
    AppData.device.getDeviceInfo(),
    window.analytics.permutive.getCohorts(),
  ]);

  let paramsObj: Record<string, string> = {};
  let cohortString = '';

  if (!liveStreamType || liveStreamType === 'uplynk') {
    // An empty or 'uplynk' livestream type indicates we are playing live content on
    // the live playback page / EPG page
    paramsObj = AppData.adParams.platform.channel_params;
    cohortString = getLiveCohortTagString(cohorts);
  } else {
    paramsObj = AppData.adParams.platform.fast_params;
    // TODO: Potentially update amagi channel stream cohorts
    cohortString = getChannelCohortTagString(cohorts);
  }

  const paramsString = buildParamsStringFromObj(paramsObj);
  const streamUrlWithParams = `${baseStreamUrl}?${paramsString}`;

  const appId = AppData?.device.getAppId() ?? '';
  const deviceAdId = deviceInfo.adId;
  const { idtype } = AppData.google;
  const npa = AppData!.privacyService.getRdpTag();
  const uoo = AppData!.privacyService.getDoNotSell();
  const usPrivacy = AppData!.privacyService.getIabUsPrivacy();
  const appVersion = Metadata.appVersion() ?? '';
  const osVersion = deviceInfo.osVersion;
  const isLat = uoo ? '1' : '0';

  return streamUrlWithParams
    .replace(/{{AD_ZONE}}/g, encodeURIComponent(adZone))
    .replace(/{{app.idtype}}/g, encodeURIComponent(idtype))
    .replace(/{{app.is_lat}}/g, encodeURIComponent(isLat))
    .replace(/{{app.npa}}/g, encodeURIComponent(`${npa}`))
    .replace(/{{app.rdid}}/g, encodeURIComponent(deviceAdId))
    .replace(/{{app.us_privacy}}/g, encodeURIComponent(usPrivacy))
    .replace(/{{appid}}/g, encodeURIComponent(appId))
    .replace(/{{appversion}}/g, encodeURIComponent(appVersion))
    .replace(
      /{{COHORT}}/g,
      !liveStreamType || liveStreamType === 'uplynk'
        ? cohortString
        : encodeURIComponent(cohortString),
    )
    .replace(/{{d_id}}/g, encodeURIComponent(deviceAdId))
    .replace(/{{DEVICE_ID}}/g, encodeURIComponent(deviceAdId))
    .replace(/{{osver}}/g, encodeURIComponent(osVersion))
    .replace(/{{UserOptOut}}/g, encodeURIComponent(`${uoo}`)) // we've seen this passed as both UserOptOut and userOptOut
    .replace(/{{userOptOut}}/g, encodeURIComponent(`${uoo}`));
};

const getVodCohortAdTagObj = (cohorts: number[]): Record<string, string> => {
  const cohortTags: Record<string, string> = {};
  cohorts.forEach(value => {
    cohortTags[`imafw_${value}`] = 'permutive';
  });

  return cohortTags;
};

const getLiveCohortTagString = (cohorts: number[]): string => {
  return cohorts
    .map((value, index) => {
      if (index !== cohorts.length - 1) {
        return `${value},permutive`;
      } else {
        // Assuming live ad tag params have the format '...{{COHORT}},permutive...',
        // we don't need to add ',permutive' to the final cohort
        return `${value}`;
      }
    })
    .join(',');
};

const getChannelCohortTagString = (cohorts: number[]): string => {
  // Assuming channel ad tag params have the format '...permutive_keys={{COHORT}}...'
  return cohorts
    .map(value => {
      return `${value}=permutive`;
    })
    .join('&');
};

const buildParamsStringFromObj = (paramsObj: Record<string, string>) => {
  return Object.entries(paramsObj)
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join('&');
};
