import { AppData, Lightning } from '@lightningjs/sdk';
import AttributionsSection from './sections/AttributionsSection';
import SupportSection from './sections/SupportSection';
import TextSection from './sections/TextSection';
import ToggleSettingSection from './sections/toggleSection/ToggleSettingSection';
import WatchHistorySection from './sections/WatchHistorySection';
import { HoverableComponent } from 'components/common/HoverableComponent';
import { getSettingsText } from 'services/cwService';
import { translate } from 'support/translate';
import { Platform } from 'models/platforms/platform';
import TizenHistoryService from 'services/history/TizenHistoryService';
import ParentalControlsSection from './sections/toggleSection/ParentalControlsSection';

export interface SettingsSectionTemplateSpec
  extends Lightning.Component.TemplateSpec {
  selection: number;

  Sections: {
    Support: typeof SupportSection;
    TermsOfUse: typeof TextSection;
    PrivacyPolicy: typeof TextSection;
    NielsenMeasurement: typeof TextSection;
    Attributions: typeof AttributionsSection;
    WatchHistory: typeof WatchHistorySection;
    Dns: typeof ToggleSettingSection;
    AutoPlaySettings: typeof ToggleSettingSection;
    ShareHistoryWithTizen: typeof ToggleSettingSection;
    ParentalControls: typeof ParentalControlsSection;
  };
}

export const SETTING_SECTIONS: Array<
  keyof SettingsSectionTemplateSpec['Sections']
> = [
  'Support',
  'TermsOfUse',
  'PrivacyPolicy',
  'NielsenMeasurement',
  'Attributions',
  'WatchHistory',
  'Dns',
  'AutoPlaySettings',
];

export const TIZEN_SETTINGS_SECTION: typeof SETTING_SECTIONS = [
  ...SETTING_SECTIONS,
  'ShareHistoryWithTizen',
];

export const LG_SETTINGS_SECTION: typeof SETTING_SECTIONS = [
  ...SETTING_SECTIONS,
  'ParentalControls',
];

export const UNKNOWN_SETTINGS_SECTION: typeof SETTING_SECTIONS = [
  ...LG_SETTINGS_SECTION,
];

export default class SettingsSection
  extends HoverableComponent<SettingsSectionTemplateSpec>
  implements
    Lightning.Component.ImplementTemplateSpec<SettingsSectionTemplateSpec>
{
  private _Sections = this.getByRef('Sections')!;
  private _Dns = this._Sections.getByRef('Dns')!;

  private _selectionIndex = 0;

  set selection(selection: number) {
    this.getSectionFromIndex(this._selectionIndex)?.patch({
      visible: false,
    });
    this.getSectionFromIndex(selection)?.patch({
      visible: true,
    });
    this._selectionIndex = selection;
  }

  get selection() {
    return this._selectionIndex;
  }

  static override _template() {
    const fetchTermsOfUseText = () => getSettingsText('TERMS_OF_USE');
    const fetchPrivacyPolicyText = () => getSettingsText('PRIVACY_POLICY');
    const fetchNielsenMeasurementText = () =>
      getSettingsText('NIELSEN_MEASUREMENT');

    return {
      Sections: {
        h: 980,
        w: 815,
        Support: {
          type: SupportSection,
          visible: false,
        },
        TermsOfUse: {
          type: TextSection,
          sectionId: 'TermsOfUse',
          fetchTextData: fetchTermsOfUseText,
          visible: false,
        },
        PrivacyPolicy: {
          type: TextSection,
          sectionId: 'PrivacyPolicy',
          fetchTextData: fetchPrivacyPolicyText,
          visible: false,
        },
        NielsenMeasurement: {
          type: TextSection,
          sectionId: 'NielsenMeasurement',
          fetchTextData: fetchNielsenMeasurementText,
          visible: false,
        },
        Attributions: { type: AttributionsSection, visible: false },
        WatchHistory: { type: WatchHistorySection, visible: false },
        Dns: {
          type: ToggleSettingSection,
          visible: false,
          getToggleState: this.getDnsPreference,
          label: translate('settings.Dns.label'),
          description: translate('settings.Dns.description'),
          signals: {
            onChanged: '_onDnsPreferenceChanged',
          },
        },
        AutoPlaySettings: {
          type: ToggleSettingSection,
          visible: false,
          getToggleState: () =>
            AppData!.storageService.autoplaySettings.get() ?? true,
          label: translate('settings.AutoPlaySettings.label'),
          description: translate('settings.AutoPlaySettings.description'),
          signals: {
            onChanged: '_onAutoplaySettingsChanged',
          },
        },
        ShareHistoryWithTizen: {
          type: ToggleSettingSection,
          visible: false,
          getToggleState: this.shouldShareHistoryWithTizen,
          label: translate('settings.ShareHistoryWithTizen.label'),
          description: translate('settings.ShareHistoryWithTizen.description'),
          signals: {
            onChanged: '_onShareHistoryWithTizenChanged',
          },
        },
        ParentalControls: {
          type: ParentalControlsSection,
          visible: false,
        },
      },
    };
  }

  override _getFocused() {
    return (this.getSectionFromIndex(this._selectionIndex) ??
      this._Sections) as Lightning.Component;
  }

  override _enable() {
    this.updateDnsText();
  }

  static getSettingsSections() {
    const platform = AppData?.device.getPlatform();
    switch (platform) {
      case Platform.LG:
        return LG_SETTINGS_SECTION;
      case Platform.TIZEN:
        return TIZEN_SETTINGS_SECTION;
      case Platform.UNKNOWN:
        return UNKNOWN_SETTINGS_SECTION;
      default:
        return SETTING_SECTIONS;
    }
  }

  private static getDnsPreference() {
    const privacyService = AppData?.privacyService;

    return privacyService?.getAppDoNotSell() ?? false;
  }

  private static shouldShareHistoryWithTizen() {
    const platform = AppData?.device.getPlatform();
    if (platform !== Platform.TIZEN) return false;

    const historyService = AppData!.historyService as TizenHistoryService;
    return historyService.shouldShareHistoryWithTizen();
  }

  private updateDnsText() {
    this._Dns.label =
      AppData?.privacy?.do_not_sell_title ?? translate('settings.Dns.label');
    this._Dns.description =
      AppData?.privacy?.do_not_sell_subtitle ??
      translate('settings.Dns.description');
  }

  private getSectionFromIndex(index: number) {
    const settingsSections = SettingsSection.getSettingsSections();
    const section = settingsSections[index];
    if (section === undefined) return undefined;

    return this._Sections.getByRef(settingsSections[index]!);
  }

  private _onDnsPreferenceChanged(dns: boolean) {
    AppData?.privacyService.setDoNotSell(dns);
  }

  private _onAutoplaySettingsChanged(autoplay: boolean) {
    AppData!.storageService.autoplaySettings.set(autoplay);
  }

  private _onShareHistoryWithTizenChanged(share: boolean) {
    const platform = AppData?.device.getPlatform();
    if (platform !== Platform.TIZEN) return;

    const historyService = AppData!.historyService as TizenHistoryService;
    historyService.onShareHistoryWithTizenChanged(share);
  }
}
