import { Colors, Lightning } from '@lightningjs/sdk';
import { HoverableComponent } from 'components/common/HoverableComponent';
import { SECTION_PADDING } from './ToggleSettingSection';
import { getFontFaceFromStyle } from 'support/textUtils';
import { translate } from 'support/translate';
import constants from '../../../../../../static/constants.json';

interface ParentalControlsChangePinTemplateSpec
  extends Lightning.Component.TemplateSpec {
  Text: Lightning.textures.TextTexture;
  Underline: Lightning.textures.RectangleTexture;
  Highlight: typeof HoverableComponent;
}

const CHANGE_PIN_FONT_SIZE = 32;
const CHANGE_PIN_WIDTH = 163;

const INVISIBLE = constants.ui.invisible;

export default class ParentalControlsChangePin extends Lightning.Component<ParentalControlsChangePinTemplateSpec> {
  private _Text = this.getByRef('Text')!;
  private _Highlight = this.getByRef('Highlight')!;

  get title() {
    return [this._Text.text?.text || ''];
  }

  static override _template(): Lightning.Component.Template<ParentalControlsChangePinTemplateSpec> {
    return {
      flexItem: { marginTop: 53 },
      visible: false,
      Text: {
        x: SECTION_PADDING,
        text: {
          fontFace: getFontFaceFromStyle('regular'),
          fontSize: 32,
          lineHeight: 45,
          textColor: Colors('text').get(),
          text: translate('settings.ParentalControls.changePin'),
        },
        zIndex: 1,
      },
      Underline: {
        x: SECTION_PADDING,
        y: CHANGE_PIN_FONT_SIZE + 3,
        w: CHANGE_PIN_WIDTH,
        h: 2,
        rect: true,
        color: Colors('text').get(),
        zIndex: 1,
      },
      Highlight: {
        type: HoverableComponent,
        y: y => y - 8,
        w: w => w,
        h: 60,
        rect: true,
        color: Colors('buttonInactive').alpha(INVISIBLE).get(),
        signals: {
          $onHover: '$onHighlightHover',
        },
      },
    };
  }

  $onHighlightHover() {
    this.signal('$onHover', this);
  }

  setVisibility(visible: boolean) {
    this.patch({ visible });
  }

  setHighlightVisibility(visible: boolean) {
    this._Highlight.patch({
      color: Colors('buttonInactive')
        .alpha(visible ? 1 : INVISIBLE)
        .get(),
    });
  }
}
