import { AppData, Lightning, Registry, Router } from '@lightningjs/sdk';
import Page from '../../Page';
import Player from './Player';
import {
  Episode,
  LiveEventChannel,
  Media,
  PlayableMediaImages,
  Video,
} from 'types/api/media';
import { PageId } from 'types/pageId';
import { SeekType, ViewContext } from 'types/analytics';
import { ImaEvent } from 'types/player';
import {
  canSaveVideoProgress,
  isInCredits,
  isLiveEventChannel,
  isMovie,
  isSeries,
  isVideo,
} from 'support/contentUtils';
import { RoutePath } from 'config/routes';
import { debounce } from 'support/generalUtils';
import { STANDARD_FADE } from 'support/animations';
import constants from '../../../../static/constants.json';
import Modal from 'components/common/Modal';
import { translate } from 'support/translate';

import EndCard from './EndCard';
import {
  MILLISECONDS_IN_SECOND,
  minutesToMilliseconds,
} from 'support/dateUtils';
import {
  navigateDetailsFromVideo,
  navigateHome,
  navigateToPlay,
} from 'support/routerUtils';
import PlayerEventConsumer from './PlayerEventConsumer';
import VideoOverlay from './VideoOverlay';
import PauseAd from './PauseAd';
import { ErrorCause } from 'components/common/ErrorModal';
import { ListItemContext } from '../../../types/events';
import TextTrackDisplay from './TextTrackDisplay';

const INTERACTIVE_TO_IDLE_TRANSITION_TIME = 5000;

export interface AdCuePoint {
  start: number;
  end: number;
  played: boolean;
}

const FULL_SCREEN_PLAYER_X = 0;
const FULL_SCREEN_PLAYER_Y = 0;
const FULL_SCREEN_PLAYER_WIDTH = 1920;
const FULL_SCREEN_PLAYER_HEIGHT = 1080;

export interface PlaybackPageTemplateSpec
  extends Lightning.Component.TemplateSpec {
  pageData:
    | {
        mediaContent: Video | LiveEventChannel;
        contentSourceId: string;
        hasDrm?: boolean;
        drmLicenseUrl?: string;
        progress?: number;
        upNext?: Video[];
        viewContext: ViewContext;
        listItemContext: ListItemContext;
        fromPageId: PageId;
      }
    | undefined;
  Content: {
    Player: typeof Player;
    PlayerEventConsumer: typeof PlayerEventConsumer;
    TextTrackDisplay: typeof TextTrackDisplay;
    VideoOverlay: typeof VideoOverlay;
    EndCard: typeof EndCard;
    PauseAd: typeof PauseAd;
  };
}

export default class PlaybackPage
  extends Page<PlaybackPageTemplateSpec>
  implements
    Lightning.Component.ImplementTemplateSpec<PlaybackPageTemplateSpec>
{
  protected override _pageId = PageId.PLAYBACK;

  private _buffering = false;
  private _hasReachedEnd = false;
  private _isVideoSavedToHistory = false;
  private _paused = false;
  private _ccEnabled = false;

  private _video: Video | null = null;
  private _liveStream: LiveEventChannel | null = null;
  private _validEndCard = false;
  private _endCardShown = false;
  private _isAdPlaying = false;
  private _consecutivePlays = 0;
  private _ayswIdleLimit = 0;
  private _ayswTimeoutFunction: (() => void) | null = null;
  private _ayswLiveEventEndedTimeout = null;

  private _restartIdleTimer = debounce(
    this.idleCheck.bind(this),
    INTERACTIVE_TO_IDLE_TRANSITION_TIME,
  );

  private _ayswTimer = null;
  private _shouldPromptAysw = false;

  private _idleCheckTimeoutId: number | null = null;

  private _shouldResumeOnForeground = false;

  private _Content = this.getByRef('Content')!;
  private _Player = this._Content.getByRef('Player')!;
  private _PlayerEventConsumer = this._Content.getByRef('PlayerEventConsumer')!;

  private _TextTrackDisplay = this._Content.getByRef('TextTrackDisplay')!;
  private _VideoOverlay = this._Content.getByRef('VideoOverlay')!;
  private _EndCard = this._Content.getByRef('EndCard')!;
  private _PauseAd = this._Content.getByRef('PauseAd')!;

  static override _template(): Lightning.Component.Template<PlaybackPageTemplateSpec> {
    return {
      ...super._template(),
      Content: {
        Player: {
          collision: true,
          type: Player,
        },
        PlayerEventConsumer: {
          type: PlayerEventConsumer,
          signals: {
            $setIsAdPlaying: '$handleAdStateChange',
            $videoPlayerEnded: '$videoPlayerEnded',
            $videoPlayerPlay: '$videoPlayerPlay',
            $videoPlayerPause: '$videoPlayerPause',
            $videoPlayerSeeking: '$videoPlayerSeeking',
            $videoPlayerTimeUpdate: '$videoPlayerTimeUpdate',
            $videoPlayerEvent: '$videoPlayerEvent',
            $fatalError: '$fatalError',
            $bufferStart: '$bufferStart',
            $bufferEnd: '$bufferEnd',
            $onManualTextTrackChange: '$onManualTextTrackChange',
          },
        },
        TextTrackDisplay: {
          type: TextTrackDisplay,
        },
        VideoOverlay: {
          type: VideoOverlay,
          signals: {
            skip: '_skip',
            seek: '_seek',
            pause: '_pause',
            seekPause: '_seekPause',
            play: '_play',
            seeking: '_seeking',
            startOver: '_startOver',
            ccAudio: '_ccAudio',
            onSubtitlesChange: '_onSubtitlesChange',
            onAudioChange: '_onAudioChange',
            openMenu: '_openMenu',
            closeMenu: '_closeMenu',
            onControlHover: '_onControlHover',
          },
        },
        EndCard: {
          type: EndCard,
          alpha: 0,
          transitions: STANDARD_FADE,
          signals: {
            backToSeries: '_backToSeries',
            watchCredits: '_closeEndCard',
            play: '_playAnotherContent',
          },
        },
        PauseAd: {
          type: PauseAd,
        },
      },
    };
  }

  override _onDataProvided() {
    if (!this.pageData) {
      Router.navigate(RoutePath.ERROR);
      return;
    }
    const {
      mediaContent,
      contentSourceId,
      hasDrm,
      drmLicenseUrl,
      progress,
      upNext,
      fromPageId,
      listItemContext,
      viewContext,
    } = this.pageData;

    if (isLiveEventChannel(mediaContent)) {
      this._liveStream = mediaContent as LiveEventChannel;
      this._Player.liveStreamUrl = this._liveStream.streamUrl;
      this._Player.adZone = this._liveStream.adZone;
      this._VideoOverlay.contentType = 'live';
      this._ayswIdleLimit = minutesToMilliseconds(
        AppData?.ayswIdleTimes?.live ?? 0,
      );
      this._ayswTimeoutFunction = this.fireLiveAyswTimeout.bind(this);
    } else {
      this._video = mediaContent as Video;
      this._Player.videoId = this._video.guid;
      this._Player.liveStreamUrl = null;
      this._Player.adZone = this._video.adZone;
      this._VideoOverlay.contentType = 'vod';
      this._ayswIdleLimit = minutesToMilliseconds(
        AppData?.ayswIdleTimes?.vod ?? 0,
      );
      this._ayswTimeoutFunction = this.fireVodAyswTimeout.bind(this);

      if (upNext && upNext.length > 0) {
        this._EndCard.content = { recommended: upNext, current: this._video };
        this._EndCard.patch({ isEnd: false });
        this._validEndCard = true;
        this._endCardShown = false;
      }
    }

    this._hasReachedEnd = false;
    this._isVideoSavedToHistory = false;
    this._isAdPlaying = false;

    this._Player.contentSourceId = contentSourceId;
    this._Player.hasDrm = hasDrm;
    this._Player.drmLicenseUrl = drmLicenseUrl;
    this._Player.startTime = progress;

    this._VideoOverlay.initialize();
    this._VideoOverlay.duration = Number(mediaContent.durationSecs);
    this._VideoOverlay.mediaContent = mediaContent;

    this._setState('Idle');
    this._PlayerEventConsumer.initialize(
      this._Player,
      mediaContent,
      fromPageId,
      viewContext,
      listItemContext,
      this._consecutivePlays,
    );
    this._initPlayback();
  }

  override _enable() {
    this._Player.patch({
      absoluteX: FULL_SCREEN_PLAYER_X,
      absoluteY: FULL_SCREEN_PLAYER_Y,
      w: FULL_SCREEN_PLAYER_WIDTH,
      h: FULL_SCREEN_PLAYER_HEIGHT,
    });
    super._enable();
  }

  override _setup() {
    super._setup();
    this._setState('Idle');
    this._PauseAd.onLoad = () => this._setState('PauseAd');
    this._ccEnabled =
      AppData?.storageService.subtitle.get() !== constants.closedCaption.none ||
      !!AppData?.device.getCcSettings().enabled;
  }

  override _active() {
    this.hideBackground();

    window.backgroundingService.addBackgroundingAction(
      'playbackBackgrounded',
      this._onBackgrounded.bind(this),
    );
    window.backgroundingService.addForegroundingAction(
      'playbackForegrounded',
      this._onForegrounded.bind(this),
    );
    this.setupAyswTimer();
    this.setupAysw();
    this._handleCcChange = this.handleCcChange;
  }

  override _inactive() {
    window.backgroundingService.removeBackgroundingAction(
      'playbackBackgrounded',
    );
    window.backgroundingService.removeForegroundingAction(
      'playbackForegrounded',
    );

    this._endPlayback();
    this.showBackground();
    this._setState('Idle');
    this.clearIdleCheckTimeout();
    this.clearAyswTimeout();
    this.clearAyswEventTimeout();

    this._consecutivePlays = 0;
    this._handleCcChange = undefined;
  }

  override _captureKey(event: KeyboardEvent) {
    this.resetAyswTimer();
    if (!this._Player.isPlaying() && this._getState() !== 'PauseAd') {
      this.startPauseAd();
    }
    return false;
  }

  private handleCcChange(tvCcEnabled: boolean) {
    const availableSubtitle = this._Player.getSubtitles()[0];
    if (availableSubtitle) {
      this._onSubtitlesChange(
        tvCcEnabled ? availableSubtitle : constants.closedCaption.none,
      );
    }
  }

  private idleCheck() {
    this.clearIdleCheckTimeout();

    if (!!AppData?.announcerService.getIsSpeaking?.()) {
      this._idleCheckTimeoutId = Registry.setTimeout(
        this.idleCheck.bind(this),
        MILLISECONDS_IN_SECOND,
      );
    } else if (
      this._getState() === 'Interactive' &&
      !this._VideoOverlay.isInMenu()
    ) {
      this._setState('Idle');
    }
  }

  private clearIdleCheckTimeout() {
    if (this._idleCheckTimeoutId) {
      Registry.clearTimeout(this._idleCheckTimeoutId);
      this._idleCheckTimeoutId = null;
    }
  }

  private saveHistory(isComplete = false) {
    // if video is already saved, don't save again
    if (this._isVideoSavedToHistory) return;
    const video = this._video;
    const saveTime = this._Player.getSaveTime();

    if (canSaveVideoProgress(video, saveTime)) {
      AppData?.historyService.add(
        video!,
        saveTime,
        isComplete || isInCredits(video!, saveTime),
      );
      this._isVideoSavedToHistory = true;
    }
  }

  private setupAysw() {
    if (!this.pageData) return;
    const { mediaContent } = this.pageData;

    let showName = '';
    let cancelLabel = '';
    if (isLiveEventChannel(mediaContent)) {
      showName = (mediaContent as LiveEventChannel).title;
      cancelLabel = translate('global.returnToEvent');
    } else {
      if (isMovie(mediaContent)) {
        showName = mediaContent.title;
      } else {
        showName = (mediaContent as Episode).seriesName;
      }
      cancelLabel = translate('global.returnToDetails');
    }

    Modal.construct(this.widgets.modal, {
      title: translate('playback.stillWatching', showName),
      confirmLabel: translate('global.continue'),
      cancelLabel: cancelLabel,
      bgAlpha: 0.9,
      bgColor: 'black',
      onConfirm: this._handleContinueOnAysw.bind(this),
      onCancel: this._handleBackOnAysw.bind(this),
    });
  }

  private async showAyswOrEventEnded() {
    if (!this.pageData) return;
    const { mediaContent } = this.pageData;

    if (isLiveEventChannel(mediaContent)) {
      try {
        const liveStream = mediaContent as LiveEventChannel;
        if (liveStream) {
          const currentTime = new Date();
          const endTime = new Date(liveStream.endDateStream);

          if (currentTime < endTime) {
            const timeDifference = endTime.getTime() - currentTime.getTime();
            this.clearAyswEventTimeout();
            this._ayswLiveEventEndedTimeout = Registry.setTimeout(
              this.setupAyswEventEnded.bind(this),
              timeDifference,
            );
          } else {
            this.setupAyswEventEnded();
          }
        } else {
          this.setupAyswEventEnded();
        }
      } catch {
        this.setupAyswEventEnded();
      }
    }
    this._Player.pause();
    Router.focusWidget('Modal');
  }

  private setupAyswEventEnded() {
    this.widgets.modal.title = translate('playback.eventEnded');
    this.widgets.modal.onConfirm = null;
    this.widgets.modal.confirmLabel = '';
    this.widgets.modal.onCancel = navigateHome.bind(this);
    this.widgets.modal.cancelLabel = translate('global.returnToHome');
    this.widgets.modal.onBack = navigateHome.bind(this);
  }

  private setupAyswTimer() {
    if (this._ayswTimer === null) this.resetAyswTimer();
  }

  private resetAyswTimer() {
    this.clearAyswTimeout();
    if (this._ayswIdleLimit > 0)
      this._ayswTimer = Registry.setTimeout(
        this._ayswTimeoutFunction,
        this._ayswIdleLimit,
      );
  }

  private clearAyswTimeout() {
    this._shouldPromptAysw = false;
    if (this._ayswTimer) Registry.clearTimeout(this._ayswTimer);
    this._ayswTimer = null;
  }

  private clearAyswEventTimeout() {
    if (this._ayswLiveEventEndedTimeout)
      Registry.clearTimeout(this._ayswLiveEventEndedTimeout);
    this._ayswLiveEventEndedTimeout = null;
  }

  private fireVodAyswTimeout() {
    this._shouldPromptAysw = true;
    this._ayswTimer = null;
  }

  private fireLiveAyswTimeout() {
    this.showAyswOrEventEnded();
  }

  private startPauseAd() {
    const mediaContent = this.pageData?.mediaContent;
    const state = this._getState();

    if (
      !mediaContent ||
      !isVideo(mediaContent) ||
      state === 'EndCard' ||
      this._VideoOverlay.isInMenu() ||
      this._isAdPlaying
    ) {
      return;
    }

    const video = mediaContent as Video;
    const { guid } = video;
    const csid =
      (isLiveEventChannel(video)
        ? AppData?.adParams?.csid.live
        : AppData?.adParams?.csid.vod) ?? '';

    this._PauseAd.start({
      guid,
      playerWidth: this._Player.w,
      playerHeight: this._Player.h,
      csid: csid,
    });
  }

  private _pause() {
    this._Player.pause();
  }

  private _seekPause() {
    this._Player.seekPause();
  }

  private _play() {
    this._Player.resume();
  }

  private _skip(increment: number) {
    let seekType: SeekType | null = null;

    if (increment > 0) {
      seekType = SeekType.STEP_FORWARD;
    } else {
      seekType = SeekType.STEP_BACKWARD;
    }

    this._PlayerEventConsumer.patch({ seekType });
    this._Player.seek(this._Player.currentContentTime() + increment);
  }

  private _seek(position: number) {
    this._Player.seek(position);
  }

  private _seeking() {
    this._restartIdleTimer();
  }

  private _startOver() {
    this._PlayerEventConsumer.patch({ seekType: SeekType.START_OVER });
    this._Player.seek(0);
  }

  private _ccAudio() {
    const currSubtitle = AppData!.storageService.subtitle.get();
    const currAudio = AppData!.storageService.audio.get();
    const subtitles = this._Player.getSubtitles();

    let ccIndex = -1;
    let audioIndex = -1;

    if (currSubtitle) {
      ccIndex = subtitles.findIndex(
        subtitleOption => subtitleOption === currSubtitle,
      );
    } else if (AppData?.device.getCcSettings().enabled && subtitles.length) {
      // If no cc option is saved, but device cc is enabled, use the first subtitles
      ccIndex = 0;
    }

    const audio = this._Player.getAudioOptions();
    if (currAudio) {
      audioIndex = audio.findIndex(audioOption => audioOption === currAudio);
    }
    // If no audio index selected but we have audio options, use the first one (Default)
    if (audioIndex === -1 && audio.length) {
      audioIndex = 0;
    }

    const cc = [constants.closedCaption.none, ...this._Player.getSubtitles()];

    ccIndex += 1; // Adding one to account for 'none'

    this._VideoOverlay.updateCc(ccIndex, audioIndex, cc, audio);
  }

  private _openMenu() {
    this._setState('Interactive');
    this._PauseAd.stop();
  }

  private _closeMenu() {
    if (!this._Player.isPlaying()) this.startPauseAd();
  }

  private _onSubtitlesChange(option: string) {
    this._ccEnabled = option !== constants.closedCaption.none;
    if (!this._ccEnabled) {
      this._Player.disableCc();
      this._TextTrackDisplay.reset();
    } else {
      this._Player.enableCc(option);
    }

    AppData!.storageService.subtitle.set(option);
    window.analytics.mParticle.video.reportCloseCaptions(option);
  }

  private $onManualTextTrackChange(textTrack: TextTrack) {
    if (this._ccEnabled) {
      this._TextTrackDisplay.displayTextTrack(textTrack);
    }
  }

  private _onAudioChange(option: string) {
    this._Player.setAudioTrack(option);
    AppData!.storageService.audio.set(option);
  }

  private _initPlayback() {
    this._Player.open();
    this._Player.initialize(this._PlayerEventConsumer);
  }

  private _endPlayback() {
    this._PlayerEventConsumer.endPlayback({
      hasReachedEnd: this._hasReachedEnd,
    });
    this.saveHistory();
    this._Player.close();

    const iframes = document.querySelectorAll('iframe[title="Advertisement"]');
    iframes.forEach(iframe => iframe.remove());
  }

  private _showEndCard() {
    if (!this._validEndCard) return;
    this._endCardShown = true;
    this._EndCard.patch({ isEnd: this._hasReachedEnd });
    this._setState('EndCard');
  }

  private _closeEndCard() {
    this._setState('Idle');
    this._Player.resume();
  }

  private _handleContinueOnAysw() {
    this.resetAyswTimer();
    if (!this._hasReachedEnd) {
      this._Player.resume();
    } else {
      this._showEndCardOrAysw();
    }
  }

  private _handleBackOnAysw() {
    if (!this.pageData) return;
    const { mediaContent } = this.pageData;

    if (isLiveEventChannel(mediaContent)) {
      Router.navigate(`${RoutePath.LIVE}`);
    } else {
      const video = mediaContent as Video;
      if (isSeries(video)) {
        Router.navigate(`${RoutePath.SERIES_DETAIL}/${video.showSlug}`);
      } else if (isMovie(video)) {
        Router.navigate(`${RoutePath.MOVIE_DETAIL}/${video.showSlug}`);
      }
    }
  }

  private _showEndCardOrAysw() {
    if (!this._EndCard.hasFocus() && this._shouldPromptAysw) {
      this.showAyswOrEventEnded();
    } else if (
      this._validEndCard &&
      (!this._endCardShown || this._hasReachedEnd)
    ) {
      this._showEndCard();
    }
  }

  private _backToSeries() {
    if (!this._video) return;
    navigateDetailsFromVideo(this._video);
  }

  private _playAnotherContent(
    video: Media,
    context: ViewContext,
    index: number,
  ) {
    this._hasReachedEnd = true;
    this._closeEndCard();
    this._endPlayback();
    let guid = '';

    // Getting the guid regardless of its underlying content type,
    // in case of any incorrectly labeled contents.
    guid = video.firstEpisode?.guid ?? video.guid ?? '';

    if (guid) {
      this._consecutivePlays += 1;

      window.analytics.mParticle.video.reportPlayRecommendation(
        'autoPlay',
        video,
      );
      const images = video.images as PlayableMediaImages;
      const listItemContext: ListItemContext = {
        rowIndex: 0,
        itemIndex: index,
        imageUrl: images.thumbnail || '',
        rowTitle: context,
      };
      navigateToPlay(
        guid,
        {
          viewContext: context,
          listItemContext,
          pageId: this.pageId,
        },
        undefined,
        false,
      );
    } else {
      this.$fatalError();
    }
  }

  private _onBackgrounded() {
    const isPlaying = this._Player.isPlaying();
    this._shouldResumeOnForeground = isPlaying;
    if (isPlaying) {
      this._Player.pause();
    }
  }

  private _onForegrounded() {
    if (this._shouldResumeOnForeground) {
      this._Player.resume();
    }
  }

  private _onControlHover() {
    this._restartIdleTimer();
  }

  private _handleClick() {
    this._setState('Interactive');
  }

  private _handleAdPodStart() {
    const isAdPreroll = this._Player.isAdPreroll();
    const adDuration = this._Player.adDuration() || 0;

    this._VideoOverlay.adStarted(isAdPreroll, adDuration);
  }

  private _handleAdPodEnd() {
    const videoDuration = Number(this._video?.durationSecs) || 0;

    this._VideoOverlay.adBreakEnded(videoDuration);
    this._Player.snapForward();
  }

  $handleAdStateChange(isAdPlaying: boolean) {
    if (this._isAdPlaying === isAdPlaying) return;

    this._isAdPlaying = isAdPlaying;
    this._VideoOverlay.setIsAdPlaying(isAdPlaying);

    if (isAdPlaying) {
      this._handleAdPodStart();
    } else {
      this._handleAdPodEnd();
    }
  }

  $videoPlayerEnded() {
    this._hasReachedEnd = true;
    this._showEndCardOrAysw();
    this.saveHistory(true);
  }

  $videoPlayerPlay() {
    this._paused = false;

    this._VideoOverlay.updateFromPlay();
    this._PauseAd.stop();
    this.updateBufferingState();
  }

  $videoPlayerPause() {
    this._paused = true;

    this._VideoOverlay.updateFromPause();
    this.startPauseAd();
    this.updateBufferingState();
  }

  $videoPlayerSeeking(rawTime: number, relativeTime: number) {
    this._VideoOverlay.updateProgress(relativeTime);

    this._PlayerEventConsumer.patch({ seekType: null });
  }

  $videoPlayerTimeUpdate(relativeTime: number, hasReachedCredits: boolean) {
    this._VideoOverlay.updateProgress(relativeTime);

    if (!this._video) return;

    if (hasReachedCredits) {
      this._showEndCardOrAysw();
      this.saveHistory(true);
    }

    const isAdPlaying = this._isAdPlaying;
    const player = this._Player;

    if (player.shouldSkipAdPod(isAdPlaying)) {
      // Skip ad when it has already been watched and it's just starting
      player.skipAdPod();
    } else if (player.shouldCleanupAdPod(isAdPlaying)) {
      // Cleanup ad playing state if ad break ended event is never received
      player.cleanupAdPod();
    }
  }

  $videoPlayerEvent(type: string, event: any) {
    switch (type) {
      case ImaEvent.CUEPOINTS_CHANGED: {
        const { cuepoints } = event.getStreamData();

        const parsedCuePoints: Array<AdCuePoint | null> = cuepoints.map(
          (adCuePoint: AdCuePoint) => {
            const start = this._Player.getContentTimeForStreamTime(
              adCuePoint.start,
            );
            const end = this._Player.getContentTimeForStreamTime(
              adCuePoint.end,
            );

            if (start === null || end === null) return null;

            return {
              start,
              end,
              played: adCuePoint.played,
            };
          },
        );

        this._VideoOverlay.updateCuePoints(
          parsedCuePoints.filter(point => point !== null) as AdCuePoint[],
        );
        break;
      }
      case ImaEvent.AD_PROGRESS: {
        const remainingTime = this._Player.getRemainingAdTime();
        if (remainingTime !== null) {
          this._VideoOverlay.updateAdProgress(remainingTime);
        }
        break;
      }
      default:
        break;
    }
  }

  $fatalError() {
    // End playback on fatal error
    this._endPlayback();

    this.widgets.errormodal.update({ cause: ErrorCause.PLAYBACK });
    Router.focusWidget('ErrorModal');
  }

  private $bufferStart() {
    this._buffering = true;
    this.updateBufferingState();
  }

  private $bufferEnd() {
    this._buffering = false;
    this.updateBufferingState();
  }

  private updateBufferingState() {
    const buffering = this._buffering;
    const paused = this._paused;
    const state = this._getState();

    // Only hide loading spinner when:
    // 1. We are not buffering, OR
    // 2. We are paused, OR
    // 3. We are showing the end card, OR
    // 4. We are showing the pause ads
    if (!buffering || paused || state === 'EndCard' || state === 'PauseAd') {
      this.loaded = true;
    } else {
      this.loaded = false;
    }
  }

  static override _states() {
    return [
      class Idle extends this {
        override $enter() {
          this._VideoOverlay.hide();
          this.updateBufferingState();
        }

        override _captureBack() {
          this._endPlayback();

          Router.back();
        }

        override _captureKey(event: KeyboardEvent) {
          super._captureKey(event);
          this._setState('Interactive');
          return false;
        }
      },
      class Interactive extends this {
        override $enter() {
          this._VideoOverlay.show();
          this._restartIdleTimer();
          this.updateBufferingState();
        }

        override _getFocused() {
          return this._VideoOverlay;
        }

        override _captureKey(event: KeyboardEvent) {
          super._captureKey(event);
          this._restartIdleTimer();
          return false;
        }

        override _handleBack() {
          this._setState('Idle');
        }
      },
      class EndCard extends this {
        override $enter() {
          this._PauseAd.stop();
          this._VideoOverlay.hide();
          this._EndCard.setSmooth('alpha', 1);
          this.updateBufferingState();
        }

        override $exit() {
          this._EndCard.setSmooth('alpha', 0);
        }

        override _getFocused() {
          return this._EndCard;
        }

        override _captureBack() {
          Router.back();
        }
      },
      class PauseAd extends this {
        override $enter() {
          this._VideoOverlay.hide();
          this.updateBufferingState();
        }

        override _captureLeft() {
          this.stopPauseAd();
        }

        override _captureUp() {
          this.stopPauseAd();
        }

        override _captureRight() {
          this.stopPauseAd();
        }

        override _captureDown() {
          this.stopPauseAd();
        }

        override _captureEnter() {
          this.stopPauseAd();
        }

        override _captureBack() {
          this.stopPauseAd();
        }

        private stopPauseAd() {
          this._setState('Interactive');
          this._PauseAd.stop();
        }
      },
    ];
  }
}
