import { createAbstractAnnouncerIntegration } from '../AbstractAnnouncerIntegration';
import { SpeechType } from '@lightningjs/ui-components';

// we use a function so createAbstractAnnouncerIntegration only runs when it needs too
export const createVizioAnnouncerIntegration = () => {
  return class VizioAnnouncerIntegration extends createAbstractAnnouncerIntegration() {
    override _voiceOut(toSpeak: SpeechType) {
      // Note that we don't track voiceOutStart or voiceOutDone events since Vizio does
      // not provide methods to track TTS statuses. Therefore, announcer's isSpeaking
      // property will always be false
      const chromevox = (window as any).VIZIO?.Chromevox;
      if (!chromevox) return;

      if (toSpeak === undefined || typeof toSpeak === 'string') {
        chromevox.play(toSpeak ?? '');
      } else if (typeof toSpeak === 'function') {
        chromevox.play(toSpeak());
      } else if (toSpeak instanceof Promise) {
        toSpeak.then(res => {
          chromevox.play(res);
        });
      } else {
        // Assuming toSpeak is an array of strings
        const message = toSpeak.join(' ');
        chromevox.play(message);
      }
    }

    override $announcerCancel() {
      this._voiceOut('');
    }
  };
};
