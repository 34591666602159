import 'core-js/actual/promise';
import 'core-js/actual/object/entries';
import 'core-js/actual/object/values';
import { Launch, Lightning, PlatformSettings } from '@lightningjs/sdk';
import App from './App';
import { AppData } from 'config/AppData';
import { releaseAnalytics } from 'services/analytics/reportingServiceVideo';
import colors from '../static/colors.json';
// @ts-ignore
import processEnv from 'processEnv';
import { Platform } from 'models/platforms/platform';
import constants from '../static/constants.json';

export default function (
  appSettings: Lightning.Application.Options,
  platformSettings: PlatformSettings,
) {
  if (processEnv.APP_PLATFORM === Platform.LG) {
    const preSplashBackground = document.createElement('div');
    preSplashBackground.setAttribute(
      'id',
      constants.elements.preSplashBackgroundId,
    );
    preSplashBackground.style.width = '100%';
    preSplashBackground.style.height = '100%';
    preSplashBackground.style.backgroundColor = colors.splashScreen;

    document.body.appendChild(preSplashBackground);
  }

  const appData = new AppData();

  const keys = appData.device.getKeyMapping();
  appSettings = {
    ...appSettings,
    keys,
  };

  /**
   * `platformSettings.onClose` is defined in `startApp`. It calls `App.close()` and
   * clears the `canvas`/`app` any time the app is closed. We augment this function
   * to use our custom close logic, ensuring it will correctly call the device
   * integration's `closeApp()` when finished.
   *
   * Reference `node_modules/@lightningjs/sdk/src/startApp.js`
   */
  const initialOnCloseFn = platformSettings.onClose;
  platformSettings.onClose = () => {
    releaseAnalytics();
    appData.storageService.clearSession();
    initialOnCloseFn?.();

    /**
     * The initial `platformSettings.onClose` has some cleanup performed after
     * a timeout. There is no reference for this timeout, so we match this
     * operation with another timeout to call `device.closeApp()`
     */
    setTimeout(() => {
      appData.device.closeApp();
    });
  };

  return Launch(App, appSettings, platformSettings, appData);
}
