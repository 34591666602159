import { Colors, Lightning, Router } from '@lightningjs/sdk';
import ActionsList from 'components/common/ActionsList';
import Button from 'components/common/Button';
import { HoverableComponent } from 'components/common/HoverableComponent';
import PinInput from './PinInput';
import { getFontFaceFromStyle } from 'support/textUtils';
import { translate } from 'support/translate';
import constants from '../../../../static/constants.json';
import Keyboard, { InputChangedEvent } from 'components/common/input/Keyboard';
import { KeyboardLayouts } from 'components/common/input/Keys';
import { getFocusDepth } from 'support/generalUtils';

export interface ParentalPinTemplateSpec
  extends Lightning.Component.TemplateSpec {
  Content: {
    Title: Lightning.textures.TextTexture;
    Description: Lightning.textures.TextTexture;
    InvalidPinText: Lightning.textures.TextTexture;
    PinInput: typeof PinInput;
    Actions: typeof ActionsList;
    ResetPinText: Lightning.textures.TextTexture;
  };
  Keyboard: typeof Keyboard;
}

const TITLE_MARGIN_BOTTOM = 24;
const DESCRIPTION_MARGIN_BOTTOM = 90;
const PIN_INPUT_MARGIN_BOTTOM = 12;
const INVALID_PIN_MARGIN_BOTTOM = 52;

const TITLE_FONT_SIZE = 63;
const DESCRIPTION_FONT_SIZE = 42;

const INVISIBLE = constants.ui.invisible;

export default abstract class ParentalPin<
    TemplateSpec extends ParentalPinTemplateSpec = ParentalPinTemplateSpec,
  >
  extends HoverableComponent<TemplateSpec>
  implements Lightning.Component.ImplementTemplateSpec<ParentalPinTemplateSpec>
{
  static focusWidgetOnClose: keyof Router.Widgets | null = null;
  static onValidateCallback: (() => void) | null = null;
  static onCancelCallback: (() => void) | null = null;

  protected title = '';
  protected description = '';
  protected pin = '';
  protected hasError = false;

  protected _Content = (this as ParentalPin).getByRef('Content')!;
  protected _PinInput = this._Content.getByRef('PinInput')!;
  protected _Actions = this._Content.getByRef('Actions')!;
  protected _Title = this._Content.getByRef('Title')!;
  protected _Description = this._Content.getByRef('Description')!;
  protected _InvalidPinText = this._Content.getByRef('InvalidPinText')!;
  protected _Keyboard = (this as ParentalPin).getByRef('Keyboard')!;

  get announce() {
    const title = this._Title.text?.text ?? '';
    const description = this._Description.text?.text ?? '';

    if (this.hasError) {
      return [this.getErrorText()];
    } else {
      return [title, description];
    }
  }

  static override _template(): Lightning.Component.Template<ParentalPinTemplateSpec> {
    return {
      Content: {
        alpha: 0,
        rect: true,
        w: 1920,
        h: 1080,
        color: Colors('modalBackground').get(),
        flex: {
          direction: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        },
        Title: {
          flexItem: { marginBottom: TITLE_MARGIN_BOTTOM },
          text: {
            fontSize: TITLE_FONT_SIZE,
            fontFace: getFontFaceFromStyle('bold'),
          },
        },
        Description: {
          flexItem: { marginBottom: DESCRIPTION_MARGIN_BOTTOM },
          text: {
            fontSize: DESCRIPTION_FONT_SIZE,
          },
        },
        PinInput: {
          type: PinInput,
          zIndex: 2,
          signals: {
            $onHover: true,
            $openKeyboard: true,
          },
          flexItem: { marginBottom: PIN_INPUT_MARGIN_BOTTOM },
        },
        InvalidPinText: {
          alpha: INVISIBLE,
          flexItem: { marginBottom: INVALID_PIN_MARGIN_BOTTOM },
          text: {
            fontSize: 22,
            textColor: Colors('error').get(),
          },
        },
        Actions: {
          type: ActionsList,
          signals: {
            $validate: true,
            $cancel: true,
            $onHover: true,
          },
        },
        zIndex: 2,
      },
      Keyboard: {
        type: Keyboard,
        x: 0,
        w: 1920,
        currentLayout: KeyboardLayouts.PIN,
        maxCharacters: 4,
        signals: {
          hideKeyboard: '$onHideKeyboard',
          inputChanged: '$onInputChanged',
          inputComplete: '$onInputComplete',
          $onHover: true,
        },
        zIndexLocal: 3,
      },
    };
  }

  override _handleBack() {
    this.$cancel();
  }

  override _handleKey() {
    // Do not allow the user to bypass the parental pin screen
    return true;
  }

  override _focus() {
    this._setState('PinInputState');
    this.clearError();
    this.update();
    this._Content.alpha = 1;
  }

  protected $cancel() {
    // implemented in children
  }

  protected $validate() {
    // implemented in children
  }

  protected resetStaticStates() {
    // implemented in children
  }

  protected closeWidget() {
    // implemented in children
  }

  protected clearInput() {
    this._Keyboard.clearInput(); // this will fire $onInputChanged

    // this is redundant with $onInputChanged, however it is more robust (ensuring this function doesn't depend on $onInputChanged)
    this.pin = '';
    this._PinInput.patch({ input: '' });
  }

  protected getCancelLabel() {
    return translate('ParentalPin.cancel');
  }

  protected getErrorText() {
    return translate('ParentalPin.invalidPin');
  }

  protected update() {
    this._Title.patch({ text: { text: this.title } });
    this._Description.patch({ text: { text: this.description } });

    this._Actions.clearActions();
    this._Actions.addAction({
      type: Button,
      label: translate('ParentalPin.continue'),
      minWidth: 300,
      action: '$validate',
      signals: { $onHover: true },
      passSignals: { $validate: true },
      zIndex: 2,
    });

    this._Actions.addAction({
      type: Button,
      label: this.getCancelLabel(),
      minWidth: 300,
      action: '$cancel',
      signals: { $onHover: true },
      passSignals: { $cancel: true },
      zIndex: 2,
    });
  }

  protected handleInvalidPin() {
    this.hasError = true;
    this._PinInput.displayError();
    this._InvalidPinText.patch({
      alpha: 1,
      text: { text: this.getErrorText() },
    });
    this.fireAncestors(
      '$announcerRefresh',
      getFocusDepth(
        // @ts-ignore
        this.application,
        this as ParentalPin,
      ),
    );
  }

  protected clearError() {
    this.hasError = false;
    this._PinInput.clearError();
    this._InvalidPinText.patch({ alpha: 0 });
    this.clearInput();
  }

  $onHover(target: unknown) {
    switch (target) {
      case this._PinInput:
        this._setState('PinInputState');
        return;
      case this._Actions:
        this._setState('ActionsState');
        return;
      case this._Keyboard:
        this._setState('Keyboard');
        return;
    }
  }

  $openKeyboard() {
    this._setState('Keyboard');
  }

  $onHideKeyboard() {
    this._setState('PinInputState');
  }

  $onInputChanged(event: InputChangedEvent) {
    const { input } = event;

    this.pin = input;
    this._PinInput.patch({ input });
  }

  $onInputComplete() {
    this._setState('PinInputState');
    this.$validate();
  }

  static override _states(): Lightning.Component.Constructor[] {
    return [
      class PinInputState extends this {
        override _getFocused() {
          return this._PinInput;
        }

        override _handleDown() {
          this._setState('ActionsState');
        }
      },
      class ActionsState extends this {
        override _getFocused() {
          return this._Actions;
        }

        override _handleUp() {
          this._setState('PinInputState');
        }

        // child components implement _handleDown
      },
      class Keyboard extends this {
        override $enter() {
          if (this.hasError) {
            this.clearError();
          }
        }

        override _getFocused() {
          return this._Keyboard;
        }

        override _handleUp() {
          this._setState('PinInputState');
        }

        override _handleDown() {
          return true;
        }
      },
    ];
  }
}
