/*
 * If not stated otherwise in this file or this component's LICENSE file the
 * following copyright and licenses apply:
 *
 * Copyright 2020 Metrological
 *
 * Licensed under the Apache License, Version 2.0 (the License);
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { AppData, Router, Utils } from '@lightningjs/sdk';
import NavBar from './components/widgets/NavBar';
import LoadingSpinner from './components/common/LoadingSpinner';
import routerConfig from './config/routes';
import Modal from 'components/common/Modal';
import { translate } from 'support/translate';
import AppLaunch from 'components/widgets/AppLaunch';
import ErrorModal from 'components/common/ErrorModal';
import Announcer from 'config/Announcer';
import { ApplicationInstance } from '@lightningjs/sdk/src/Launch';
import constants from '../static/constants.json';
import { closeApp } from 'support/appUtils';
import EnterParentalPin from 'components/widgets/parental-pin/EnterParentalPin';
import ResetParentalPin from 'components/widgets/parental-pin/ResetParentalPin';
import SetParentalPin from 'components/widgets/parental-pin/SetParentalPin';

export default class App extends Announcer {
  private _interactionsDisabled = false;

  static language() {
    return 'en';
  }

  static colors() {
    return './static/colors.json';
  }

  static getFonts() {
    return [
      {
        family: 'Roboto',
        url: Utils.asset('fonts/Roboto-Regular.ttf'),
        descriptors: {
          weight: 'normal',
        },
      },
      {
        family: 'Roboto',
        url: Utils.asset('fonts/Roboto-Bold.ttf'),
        descriptors: { weight: 'bold' },
      },
      { family: 'Roboto-Light', url: Utils.asset('fonts/Roboto-Light.ttf') },
      { family: 'Roboto-Medium', url: Utils.asset('fonts/Roboto-Medium.ttf') },
      { family: 'Roboto-Bold', url: Utils.asset('fonts/Roboto-Bold.ttf') },
      { family: 'Roboto-Black', url: Utils.asset('fonts/Roboto-Black.ttf') },
    ];
  }

  static override _template() {
    return {
      ...super._template(),
      Loading: {
        type: LoadingSpinner,
        visible: false,
        x: 960,
        y: 540,
        isLoadingScreen: true,
      },
      Widgets: {
        NavBar: {
          type: NavBar,
        },
        Modal: {
          type: Modal,
        },
        ExitModal: {
          type: Modal,
          title: translate('global.appExitMessage'),
          confirmLabel: translate('global.appExitConfirmButton'),
          cancelLabel: translate('global.appExitDismissButton'),
          onCancel: closeApp,
        },
        AppLaunch: {
          type: AppLaunch,
        },
        EnterParentalPin: {
          type: EnterParentalPin,
        },
        ResetParentalPin: {
          type: ResetParentalPin,
        },
        SetParentalPin: {
          type: SetParentalPin,
        },
        ErrorModal: {
          type: ErrorModal,
        },
      },
    };
  }

  override _setup() {
    super._setup();

    Router.startRouter(routerConfig, this);
    this.initializeAnnouncer();

    this.application.on('loadingScreen', isLoading => {
      if (isLoading) {
        this._interactionsDisabled = true;
        this.announcerHandleLoadingStart();
      } else {
        this._interactionsDisabled = false;
        this.announcerHandleLoadingEnd();
      }
    });
  }

  override _captureKey(event: KeyboardEvent) {
    // Adding a button to enable/disable TTS while developing on browser
    if (!AppData?.isProduction && event.key === 't') {
      this.announcerEnabled = !this.announcerEnabled;
    }

    // Adding a button to enable/disable CC while developing on browser
    if (!AppData?.isProduction && event.key === 'c') {
      const ccEnabled = !(
        AppData?.storageService.subtitle.get() !== constants.closedCaption.none
      );

      ApplicationInstance?.emit('ccChange', ccEnabled);
    }

    return this._interactionsDisabled;
  }
}
