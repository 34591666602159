import { Colors, Lightning } from '@lightningjs/sdk';
import { HoverableComponent } from 'components/common/HoverableComponent';
import { translate } from 'support/translate';

export interface ResetPinButtonTemplateSpec
  extends Lightning.Component.TemplateSpec {
  Content: {
    Text: Lightning.textures.TextTexture;
    Underline: Lightning.textures.RectangleTexture;
  };
}

const RESET_PIN_FONT_SIZE = 32;
const RESET_PIN_WIDTH = 163;

export default class ResetPinButton
  extends HoverableComponent<ResetPinButtonTemplateSpec>
  implements
    Lightning.Component.ImplementTemplateSpec<ResetPinButtonTemplateSpec>
{
  protected _Content = this.getByRef('Content')!;
  protected _Underline = this._Content.getByRef('Underline')!;

  get announce() {
    return [translate('ParentalPin.forgotPin')];
  }

  static override _template(): Lightning.Component.Template<ResetPinButtonTemplateSpec> {
    return {
      w: RESET_PIN_WIDTH,
      h: 32,
      Content: {
        mountX: 0.5,
        x: (w: number) => w / 2,
        Text: {
          mountX: 0.5,
          text: {
            text: translate('ParentalPin.forgotPin'),
            fontSize: RESET_PIN_FONT_SIZE,
          },
        },
        Underline: {
          mountX: 0.5,
          y: RESET_PIN_FONT_SIZE + 3,
          w: RESET_PIN_WIDTH,
          h: 2,
          rect: true,
          alpha: 0,
          color: Colors('text').get(),
          zIndex: 1,
        },
      },
    };
  }

  override _focus() {
    this._Underline.patch({ alpha: 1 });
  }

  override _unfocus() {
    this._Underline.patch({ alpha: 0 });
  }
}
