import { AppData, VideoPlayer } from '@lightningjs/sdk';
import Lightning from '@lightningjs/sdk/src/Lightning';
import DashPlayerInstance from './DashPlayerInstance';
import HlsPlayerInstance from './HlsPlayerInstance';
import AbstractPlayerInstance from './AbstractPlayerInstance';
import { CustomEvent, StreamManager } from 'types/player';
import { roundMilliseconds } from 'support/generalUtils';
import { AdCuePoint } from './PlaybackPage';
import {
  getVodAdTagParams,
  stitchAdParamsToLiveStream,
} from 'support/playerUtils';

interface PlayerTemplateSpec extends Lightning.Component.TemplateSpec {
  contentSourceId: string | undefined;
  videoId: string | undefined;
  hasDrm: boolean | undefined;
  drmLicenseUrl: string | undefined;
  startTime: number | undefined;
  liveStreamUrl: string | null;
  liveStreamType: string;
  adZone: string;
  absoluteX?: number;
  absoluteY?: number;
  beforeLoad:
    | ((videoEl: HTMLVideoElement, streamManager: object | null) => void)
    | undefined;
  onStreamAdaption: ((bandWidth: number) => void) | undefined;
  onStreamLoaded: ((url: string) => void) | undefined;
  onStreamError: ((isFatal: boolean, message: string) => void) | undefined;
  onPlaybackError: ((isFatal: boolean, message: string) => void) | undefined;
  onTracksUpdate: ((textTracks: string[]) => void) | undefined;
  onManualTextTrackChange: ((textTrack: TextTrack) => void) | undefined;
}

const CUE_STARTING_TOLERANCE = 2;
const AD_SECOND_OFFSET = 3;

export default class Player extends Lightning.Component<PlayerTemplateSpec> {
  videoElement: HTMLMediaElement | undefined;

  private _player: AbstractPlayerInstance | undefined;
  private _streamManager: StreamManager | undefined;
  private _googleImaApi: any | undefined;
  private _beforeLoad: PlayerTemplateSpec['beforeLoad'];
  private _onStreamAdaption: PlayerTemplateSpec['onStreamAdaption'];
  private _onStreamLoaded: PlayerTemplateSpec['onStreamLoaded'];
  private _onStreamError: PlayerTemplateSpec['onStreamError'];
  private _onPlaybackError: PlayerTemplateSpec['onPlaybackError'];
  private _onTracksUpdate: PlayerTemplateSpec['onTracksUpdate'];
  private _onManualTextTrackChange: PlayerTemplateSpec['onManualTextTrackChange'];

  // Content position values
  private _preSeekContentTime: number | null = null;
  private _snapForwardRawTime: number | null = null;

  // These positions are relative to the entire page instead of the parent component
  private _absoluteX: number | undefined;
  private _absoluteY: number | undefined;

  // Live stream asset key.
  contentSourceId = AppData?.google.contentSourceId;
  videoId: string | undefined;
  hasDrm: boolean | undefined;
  drmLicenseUrl: string | undefined;
  startTime: number | undefined;
  liveStreamUrl: string | null = null;
  liveStreamType = '';
  adZone = '';

  set beforeLoad(beforeLoad: PlayerTemplateSpec['beforeLoad']) {
    this._beforeLoad = beforeLoad;
  }

  set onStreamAdaption(
    onStreamAdaption: PlayerTemplateSpec['onStreamAdaption'],
  ) {
    this._onStreamAdaption = onStreamAdaption;
  }

  set onStreamLoaded(onStreamLoaded: PlayerTemplateSpec['onStreamLoaded']) {
    this._onStreamLoaded = onStreamLoaded;
  }

  set onPlaybackError(onPlaybackError: PlayerTemplateSpec['onPlaybackError']) {
    this._onPlaybackError = onPlaybackError;
  }

  set onStreamError(onStreamError: PlayerTemplateSpec['onStreamError']) {
    this._onStreamError = onStreamError;
  }

  set onTracksUpdate(onTracksUpdate: PlayerTemplateSpec['onTracksUpdate']) {
    this._onTracksUpdate = onTracksUpdate;
  }

  set onManualTextTrackChange(
    onManualTextTrackChange: PlayerTemplateSpec['onManualTextTrackChange'],
  ) {
    this._onManualTextTrackChange = onManualTextTrackChange;
  }

  set absoluteX(absoluteX: number | undefined) {
    this._absoluteX = absoluteX;

    this.updateVideoElemDimensions({ x: absoluteX });
  }

  set absoluteY(absoluteY: number | undefined) {
    this._absoluteY = absoluteY;

    this.updateVideoElemDimensions({ y: absoluteY });
  }

  override set w(w: number) {
    super.w = w;

    this.updateVideoElemDimensions({ w });
  }

  override get w() {
    return super.w;
  }

  override set h(h: number) {
    super.h = h;

    this.updateVideoElemDimensions({ h });
  }

  override get h() {
    return super.h;
  }

  static override _template(): Lightning.Component.Template<PlayerTemplateSpec> {
    return {};
  }

  initialize(consumer: Lightning.Component): void {
    VideoPlayer.consumer(consumer);
    VideoPlayer.loader(this.loader.bind(this));
    VideoPlayer.unloader(this.unloader.bind(this));

    this._googleImaApi = window.google.ima.dai.api;
  }

  private async loader(
    streamUrl: string,
    videoEl: HTMLMediaElement,
  ): Promise<void> {
    this.videoElement = videoEl;
    this.videoElement.autoplay = true;
    this.videoElement.controls = false;

    this.updateVideoElemDimensions({
      x: this._absoluteX,
      y: this._absoluteY,
      w: this.w,
      h: this.h,
    });

    this._player =
      this.hasDrm && this.drmLicenseUrl
        ? new DashPlayerInstance(
            this.videoElement,
            this.drmLicenseUrl,
            this.hasDrm && !!this.liveStreamUrl,
          )
        : new HlsPlayerInstance(this.videoElement, !!this.liveStreamUrl);

    if (this._onStreamAdaption) {
      this._player.onAdaption(this._onStreamAdaption);
    }

    if (this._onPlaybackError) {
      this._player.onError(this._onPlaybackError);
    }

    if (this._onTracksUpdate) {
      this._player.onTracksUpdate(this._onTracksUpdate);
    }

    if (this._onManualTextTrackChange) {
      this._player.onManualTextTrackChange(this._onManualTextTrackChange);
    }

    if (streamUrl) {
      await this._requestLiveStream(streamUrl, videoEl);
    } else {
      await this._requestVODStream(videoEl);
    }

    AppData?.announcerService.setOnSpeakEvent(
      this.onSpeakEventHandler.bind(this),
    );
  }

  private async unloader(videoEl: HTMLMediaElement) {
    await this._player?.destroy();
    this._player = undefined;
    videoEl.removeAttribute('src');
    AppData?.announcerService.removeOnSpeakEvent();
    videoEl.load();
  }

  private onSpeakEventHandler(isSpeaking: boolean) {
    this._player?.updateTtsPlaybackVolume(isSpeaking);
  }

  private updateVideoElemDimensions(dimensions: {
    x?: number;
    y?: number;
    w?: number;
    h?: number;
  }) {
    const { x, y, w, h } = dimensions;

    if (x !== undefined) {
      VideoPlayer.position(y ?? this._absoluteY, x);
    }

    if (y !== undefined) {
      VideoPlayer.position(y, x ?? this._absoluteX);
    }

    if (!!w) {
      VideoPlayer.size(w, h ?? this.h);
    }

    if (!!h) {
      VideoPlayer.size(w ?? this.w, h);
    }
  }

  private createManager(videoEl: HTMLVideoElement) {
    if (!this._googleImaApi) {
      return this.handleError('Google IMA missing in loader');
    }
    this._streamManager = new this._googleImaApi.StreamManager(
      this.videoElement,
    );
    if (!this._streamManager) {
      return this.handleError('StreamManager failed to load');
    }

    this._beforeLoad?.(videoEl as HTMLVideoElement, this._streamManager);

    this._streamManager.addEventListener(
      [
        this._googleImaApi.StreamEvent.Type.LOADED,
        this._googleImaApi.StreamEvent.Type.AD_BREAK_STARTED,
        this._googleImaApi.StreamEvent.Type.AD_BREAK_ENDED,
        this._googleImaApi.StreamEvent.Type.ERROR,
        this._googleImaApi.StreamEvent.Type.CUEPOINTS_CHANGED,
        this._googleImaApi.StreamEvent.Type.AD_PROGRESS,

        // Required by Conviva
        this._googleImaApi.StreamEvent.Type.STARTED,
        this._googleImaApi.StreamEvent.Type.FIRST_QUARTILE,
        this._googleImaApi.StreamEvent.Type.MIDPOINT,
        this._googleImaApi.StreamEvent.Type.THIRD_QUARTILE,
        this._googleImaApi.StreamEvent.Type.COMPLETE,
        this._googleImaApi.StreamEvent.Type.SKIPPED,
      ],
      this._onStreamEvent.bind(this),
      false,
    );
  }

  private firePlayerEvent(...args: unknown[]) {
    VideoPlayer._consumer?.fire('$videoPlayerEvent', ...args);
  }

  // Stream events: https://developers.google.com/interactive-media-ads/docs/sdks/html5/dai/reference/js/StreamEvent
  private _onStreamEvent(e: any) {
    if (!this._googleImaApi) {
      this.handleError('Google IMA missing in streamEvent');
      return;
    }

    switch (e.type) {
      case this._googleImaApi.StreamEvent.Type.LOADED:
        this._loadImaUrl(e.getStreamData().url);
        break;
      case this._googleImaApi.StreamEvent.Type.ERROR:
        this._onStreamError?.(true, e.getStreamData().errorMessage);
        break;
      default:
        this.firePlayerEvent(e.type, e);
        break;
    }
  }

  private async _loadImaUrl(url: string) {
    if (!this._streamManager) {
      this.handleError('StreamManager missing in streamRequest');
      return;
    }

    this._onStreamLoaded?.(url);

    await this._player?.load(
      url,
      this.startTime
        ? this._streamManager.streamTimeForContentTime(this.startTime)
        : undefined,
    );
  }

  private async _requestVODStream(videoEl: HTMLMediaElement) {
    this.createManager(videoEl as HTMLVideoElement);

    const { apiKey } = AppData!.google;

    if (!this.contentSourceId || !this.videoId) {
      return this.handleError('invalid/missing content for player');
    }

    if (!apiKey) {
      return this.handleError('api key missing for player');
    }

    if (!this._streamManager) {
      this.handleError('StreamManager missing in streamRequest');
      return;
    }

    let adTagParams: Record<string, string> = {};
    try {
      adTagParams = await getVodAdTagParams(this.videoId, this.w, this.h);
    } catch (e) {
      this.handleError(e as Error | string);
      return;
    }

    const streamRequest = new this._googleImaApi.VODStreamRequest();
    streamRequest.contentSourceId = this.contentSourceId;
    streamRequest.videoId = this.videoId;
    streamRequest.apiKey = apiKey;
    streamRequest.format = this.hasDrm ? 'dash' : 'hls';
    streamRequest.enableNonce = true;
    streamRequest.adTagParameters = adTagParams;

    this._streamManager.requestStream(streamRequest);
  }

  private async _requestLiveStream(
    streamUrl: string,
    videoEl: HTMLMediaElement,
  ) {
    let urlWithParams: string;
    try {
      const liveStreamType = this.liveStreamType;
      const adZone = this.adZone;

      urlWithParams = await stitchAdParamsToLiveStream(
        streamUrl,
        liveStreamType,
        adZone,
      );
    } catch (e) {
      this.handleError(e as string | Error);
      return;
    }

    this._beforeLoad?.(videoEl as HTMLVideoElement, null);
    await this._player?.load(urlWithParams, this.startTime);
    this._onStreamLoaded?.(urlWithParams);
  }

  private getCurrentCuePoint(): AdCuePoint | null {
    const playerTime = VideoPlayer.currentTime;
    const cuePoint = this._streamManager?.previousCuePointForStreamTime(
      // If the player time = cue point start time, IMA returns the previous cue
      // point, despite being inside an ad. Using slight offset to account for
      // this behavior
      playerTime + CUE_STARTING_TOLERANCE,
    );

    if (!cuePoint || playerTime + CUE_STARTING_TOLERANCE < cuePoint.start) {
      return null;
    }
    return cuePoint;
  }

  open() {
    if (this.liveStreamUrl) {
      VideoPlayer.open(this.liveStreamUrl);
    } else {
      // URL is needed to trigger videoPlayer.open() that triggers the loader
      VideoPlayer.open('');
    }
  }

  close() {
    this._preSeekContentTime = null;
    this._snapForwardRawTime = null;

    VideoPlayer.close();
  }

  isPlaying() {
    return VideoPlayer.playing;
  }

  resume() {
    if (!VideoPlayer.playing) {
      VideoPlayer.playPause();
    }
  }

  seekPause() {
    this.firePlayerEvent(CustomEvent.SEEK_PAUSE);
    this.pause();
  }

  pause() {
    VideoPlayer.pause();
  }

  seek(seekTime: number) {
    if (!this._streamManager) return;

    this.firePlayerEvent(CustomEvent.BEFORE_SEEK);

    // TODO: Handle disabling snapback for X amount of time

    const streamSeekTime =
      this._streamManager.streamTimeForContentTime(seekTime);
    const previousCuePoint =
      this._streamManager.previousCuePointForStreamTime(streamSeekTime);
    const prerollCuePoint =
      this._streamManager.previousCuePointForStreamTime(1);

    const { start: adStart = 0, played: adPlayed } = previousCuePoint ?? {};

    // Prevent user from seeking past pre-roll if video has just started
    if (!this.startTime && prerollCuePoint && !prerollCuePoint.played) return;

    // If user has already partially watched content, no need to rewatch pre-roll
    const hasWatchedPreroll = this.startTime && adStart === 0;
    const isPreviousAdPlayed = !previousCuePoint || adPlayed;

    if (hasWatchedPreroll || isPreviousAdPlayed) {
      VideoPlayer.seek(streamSeekTime);
    } else {
      this._preSeekContentTime = this.currentContentTime();
      this._snapForwardRawTime = streamSeekTime;
      VideoPlayer.seek(adStart);
    }
  }

  currentContentTime(): number {
    return (
      this._streamManager?.contentTimeForStreamTime(VideoPlayer.currentTime) ??
      VideoPlayer.currentTime
    );
  }

  getSaveTime(): number {
    // Save pre-seek time if available (indicating user seeked into an ad)
    const preSeekContentTime = this._preSeekContentTime;
    const currentContentTime = this.currentContentTime();
    return preSeekContentTime ?? currentContentTime;
  }

  currentAdTime(): number {
    if (!this._streamManager) return 0;

    const cue = this.getCurrentCuePoint();

    if (!cue) return 0;

    const { start, end, played } = cue;

    const currentTime = VideoPlayer.currentTime - start;

    if (!played) return 0;
    if (end < currentTime) return end;

    return currentTime;
  }

  currentRawTime(): number {
    return VideoPlayer.currentTime;
  }

  getContentTimeForStreamTime(streamTime: number): number | null {
    if (!this._streamManager) return null;

    return this._streamManager.contentTimeForStreamTime(streamTime);
  }

  getStreamTimeForContentTime(contentTime: number): number | null {
    if (!this._streamManager) return null;

    return this._streamManager.streamTimeForContentTime(contentTime);
  }

  adDuration(): number | null {
    if (!this._streamManager) return null;

    const cue = this.getCurrentCuePoint();

    if (!cue) return null;

    const { start, end } = cue;
    return end - start;
  }

  getRemainingAdTime(): number | null {
    if (!this._streamManager) return null;

    const cue = this.getCurrentCuePoint();

    if (!cue) return null;

    const { end } = cue;
    return end - VideoPlayer.currentTime;
  }

  isAdPreroll(): boolean {
    if (!this._streamManager) return false;

    const cue = this.getCurrentCuePoint();
    return !!cue && cue.start === 0;
  }

  shouldSkipAdPod(hasStartedAds: boolean): boolean {
    // Do not skip if user is already watching ads (an ad pod's `played`
    // attribute will be true once an ad pod starts)
    if (hasStartedAds) return false;

    const cuePoint = this.getCurrentCuePoint();
    if (!cuePoint) return false;

    // There is a brief section of time where an ad pod is beginning but the ads
    // state is unset. Check if the cuepoint has already been played
    const playerCurrentTime = VideoPlayer.currentTime;

    return (
      cuePoint.played && playerCurrentTime <= cuePoint.end - AD_SECOND_OFFSET
    );
  }

  /**
   * Skips the current ad pod by seeking to ad pod's end
   */
  skipAdPod() {
    const cuePoint = this.getCurrentCuePoint();
    if (!cuePoint) return;

    // rounds up the milliseconds in the end time
    const roundedEndTime = roundMilliseconds(cuePoint.end);
    VideoPlayer.seek(roundedEndTime);

    this.firePlayerEvent(CustomEvent.SKIP_AD_POD);
  }

  snapForward() {
    if (this._snapForwardRawTime === null) return;

    VideoPlayer.seek(this._snapForwardRawTime);
    this._preSeekContentTime = null;
    this._snapForwardRawTime = null;
  }

  isPlayerInAdPod(): boolean {
    const cuePoint = this.getCurrentCuePoint();
    if (!cuePoint) return false;

    // Adding a slight offset to ad start / end time to account for any timing
    // issues
    const cuePointStart = cuePoint.start - AD_SECOND_OFFSET;
    const cuePointEnd = cuePoint.end + AD_SECOND_OFFSET;
    const playerCurrentTime = VideoPlayer.currentTime;

    return (
      cuePointStart <= playerCurrentTime && playerCurrentTime <= cuePointEnd
    );
  }

  shouldCleanupAdPod(hasStartedAds: boolean): boolean {
    // No need to cleanup ad pod if we are not in an ad state
    if (!hasStartedAds) return false;

    // IMA will occasionally fail to send an AD_BREAK_ENDED event. Verify that
    // we are still playing an ad
    return !this.isPlayerInAdPod();
  }

  cleanupAdPod() {
    this.firePlayerEvent(CustomEvent.CLEANUP_AD_POD);
  }

  getBitRate(): number | null {
    return this._player?.currentBitrate ?? null;
  }

  disableCc() {
    this._player?.disableCc();
  }

  enableCc(lang: string) {
    this._player?.enableCc(lang);
  }

  handleManualTextTrack(): boolean {
    return !!this._player?.handleManualTextTrack();
  }

  getSubtitles() {
    return this._player?.getSubtitles() ?? [];
  }

  getAudioOptions() {
    return this._player?.getAudioOptions() ?? [];
  }

  setAudioTrack(lang: string) {
    this._player?.setAudioTrack(lang);
  }

  handleError(error: Error | string) {
    let isFatal: boolean;
    let errorMessage;

    if (error instanceof Error) {
      isFatal = true;
      errorMessage = error.message;
    } else {
      isFatal = true;
      errorMessage = error;
    }

    console.error(errorMessage, error);
    this._onPlaybackError?.(isFatal, errorMessage);
  }
}
