import axios from 'axios';
import AbstractHistoryService from './AbstractHistoryService';
import { TizenDeviceIntegration } from 'config/platforms/tizen/TizenDeviceIntegration';
import { Video } from 'types/api/media';
import { getVideoReleaseYear, isMovie } from 'support/contentUtils';
import StorageService from 'services/StorageService';

const BASE_ENDPOINT = 'http://localhost:9013/' as const;

enum Endpoints {
  ADD_ITEM = '?module=EBHelper&amp;func=AddItem',
  DELETE_ITEM = '?module=EBHelper&amp;func=DeleteItem',
  GET_ITEMS = '?module=EBHelper&amp;func=GetItems',
}

type MovieRating = 'G' | 'PG' | 'PG-13' | 'R' | 'NC-17';
type SeriesRating = 'TV-Y' | 'TV-Y7' | 'TV-G' | 'TV-PG' | 'TV-14' | 'TV-MA';

type AddItemHeaders = {
  field: 0; // Always 0 for continue watching
  app_id: string; // Application ID
  app_name: string; // Application name
  app_icon: string; // Application icon (1:1 ratio)
  content_id: string; // Content ID
  payload: string; // Deeplink payload
  image_url: string; // Content thumbnail (16:9 ratio, <360KB)
  content_title: string; // Content title
  sub_title?: string; // Additional information such as episode title
  description?: string; // Additional information about the content
  rate: MovieRating | SeriesRating; // TV  or movie rating
  genre?: string; // Genre of the content, up to 5 (separated by ',')
  release: number; // Year released
  duration?: number; // Total time in seconds, up to 3600000
  playback?: number; // Played time in seconds, up to 3600000
  expiry?: number; // Expiry time (epoch time, expires in 30 days if missing)
  timestamp: number; // Last watched time (epoch time)
  Season: number; // Season number (required for <2019 TVs)
  Episode: number; // Episode number (required for <2019 TVs)
};

type DeleteItemHeaders = {
  field: 0; // Always 0 for continue watching
  app_id: string;
  content_id: string | 'ALL'; // 'ALL' clears all continue watching content
};

type GetItemHeaders = {
  app_id: string;
};

type TizenHistoryEntryKey = keyof Omit<AddItemHeaders, 'Season' | 'Episode'>;
/**
 * The structure of Tizen's continue watching history entry. Note that Tizen stores
 * every value as a string despite some being sent as numbers
 */
type TizenHistoryEntry = Record<TizenHistoryEntryKey, string>;

type TizenHistory = Pick<TizenHistoryEntry, 'content_id' | 'playback'>[];

type ConstructorArgs = {
  deviceIntegration: TizenDeviceIntegration;
  storageService: StorageService;
};

// We can only store up to 5 titles in Tizen's Continue Watching
const MAX_ITEMS = 5;

export default class TizenHistoryService extends AbstractHistoryService {
  private readonly deviceIntegration: TizenDeviceIntegration;

  private appIconPath: string;
  private appId: string;
  private appName: string;

  /**
   * Local reference of Tizen's continue watching history. When `null`, assume
   * there is an ambiguous problem with obtaining history
   */
  private tizenHistory: TizenHistory | null = null;
  private finishedUpdatingOnBoot = false;
  private shareHistoryWithTizen = true;

  constructor({ deviceIntegration, storageService }: ConstructorArgs) {
    super({ storageService });

    this.deviceIntegration = deviceIntegration;

    this.appIconPath = this.deviceIntegration.getAppIconPath();
    this.appId = this.deviceIntegration.getAppId();
    this.appName = this.deviceIntegration.getAppName();
  }

  shouldUpdateHistoryOnBoot() {
    return true;
  }

  async updateHistoryOnBoot(): Promise<void> {
    this.shareHistoryWithTizen =
      this.storageService.shareHistoryWithTizen.get() ?? true;

    // Purge history data before attempting to update Tizen continue watching
    this.purge();
    await this.syncTizenHistory();

    this.finishedUpdatingOnBoot = true;
  }

  override async updateHistory() {
    super.updateHistory();

    // Prevent augmenting Tizen history before it has been updated on boot. This
    // only occurs when we are migrating history from previous app or when syncing
    // Tizen history on boot
    if (!this.finishedUpdatingOnBoot) return;
    await this.syncTizenHistory();
  }

  shouldShareHistoryWithTizen() {
    return this.shareHistoryWithTizen;
  }

  async onShareHistoryWithTizenChanged(shareHistory: boolean) {
    // Don't do anything if share history state did not change
    if (this.shareHistoryWithTizen === shareHistory) return;

    this.storageService.shareHistoryWithTizen.set(shareHistory);
    this.shareHistoryWithTizen = shareHistory;
    await this.syncTizenHistory();
  }

  /**
   * Syncs Tizen's continue watching history by:
   * 1. Fetching the next 5 videos from watch history
   * 2. Removing any videos in Tizen's history not in the 5 fetched videos
   * 3. Adding any of the 5 fetched videos not already in Tizen's history
   */
  private async syncTizenHistory() {
    await this.initTizenHistory();

    const tizenHistory = this.tizenHistory;
    const shareHistoryWithTizen = this.shareHistoryWithTizen;

    // Do not sync if Samsung's access to watch history has been revoked
    if (!shareHistoryWithTizen) {
      // Clear history if Tizen history was not initialized or there is already content
      if (tizenHistory === null || tizenHistory.length)
        await this.clearTizenHistory();
      return;
    }

    const watchHistoryVideos = await this.getSortedWatchHistoryVideos(
      true,
      0,
      MAX_ITEMS,
    );

    let contentIdsToRemove: string[] | 'ALL' = [];
    let videosToAdd: Video[] = [];

    if (!tizenHistory) {
      // Assume there is an issue with Tizen continue watching storage, update all
      contentIdsToRemove = 'ALL';
      videosToAdd = watchHistoryVideos;
    } else {
      const contentIdsToKeep: string[] = [];
      for (const video of watchHistoryVideos) {
        const inTizenHistory = await this.isVideoInTizenHistory(video);

        if (inTizenHistory) {
          contentIdsToKeep.push(video.guid);
        } else {
          videosToAdd.push(video);
        }
      }

      tizenHistory.forEach(({ content_id }) => {
        const shouldKeepItem = contentIdsToKeep.includes(content_id);
        if (!shouldKeepItem) (contentIdsToRemove as string[]).push(content_id);
      });
    }

    if (contentIdsToRemove === 'ALL') {
      await this.clearTizenHistory();
    } else {
      await this.deleteContentIdsFromTizenHistory(contentIdsToRemove);
    }

    await this.addVideosToTizenHistory(videosToAdd);
  }

  private async isVideoInTizenHistory(video: Video): Promise<boolean> {
    const { guid, showSlug } = video;

    await this.initTizenHistory();
    const tizenHistory = this.tizenHistory;

    const historyEntry = tizenHistory?.find(
      ({ content_id }) => content_id === guid,
    );
    if (!historyEntry) return false;

    const historyProgress = parseInt(historyEntry.playback);
    const progress = Math.floor(this.getVideoProgress(showSlug, guid));
    return historyProgress === progress;
  }

  private async initTizenHistory() {
    // Already initialized
    if (this.tizenHistory) return;
    const tizenHistoryEntries = await this.getTizenHistoryEntries();

    this.tizenHistory = tizenHistoryEntries
      ? tizenHistoryEntries.map(({ content_id, playback }) => {
          return {
            content_id,
            playback,
          };
        })
      : null;
  }

  private async clearTizenHistory() {
    const headers: DeleteItemHeaders = {
      field: 0,
      app_id: this.appId,
      content_id: 'ALL',
    };

    const response = await this.fetchHelper<boolean>(
      Endpoints.DELETE_ITEM,
      headers,
    );

    if (!response || !response.data || typeof response.data !== 'boolean') {
      // Unsuccessful removal, nothing to do
    } else {
      this.tizenHistory = [];
    }
  }

  private async deleteContentIdsFromTizenHistory(contentIds: string[]) {
    const deleteContentIdPromises = contentIds.map(
      this.deleteContentIdFromTizenHistory.bind(this),
    );
    await Promise.allSettled(deleteContentIdPromises);
  }

  private async deleteContentIdFromTizenHistory(contentId: string) {
    const headers: DeleteItemHeaders = {
      field: 0,
      app_id: this.appId,
      content_id: contentId,
    };

    const response = await this.fetchHelper<boolean>(
      Endpoints.DELETE_ITEM,
      headers,
    );

    if (!response || !response.data || typeof response.data !== 'boolean') {
      // Unsuccessful removal, nothing to do
    } else if (this.tizenHistory) {
      const contentIndex = this.tizenHistory.findIndex(
        ({ content_id }) => content_id === contentId,
      );
      if (contentIndex > -1) this.tizenHistory.splice(contentIndex, 1);
    }
  }

  private async addVideosToTizenHistory(videos: Video[]) {
    const addVideoPromises = videos.map(this.addVideoToTizenHistory.bind(this));
    await Promise.allSettled(addVideoPromises);
  }

  private async addVideoToTizenHistory(video: Video) {
    const {
      durationSecs,
      episodeInSeason,
      guid,
      rating,
      season,
      seriesName,
      showSlug,
      title,
    } = video;

    const imageUrl = video.images.thumbnail ?? '';
    const contentTitle = isMovie(video) ? title : seriesName;
    const subtitle = isMovie(video) ? '' : title;

    const expirationTime = this.getVideoExpirationTime(showSlug, guid);
    const lastModified = this.getVideoLastModifiedTime(showSlug, guid);
    const progress = Math.floor(this.getVideoProgress(showSlug, guid));
    const releaseYear = getVideoReleaseYear(video);

    const headers: AddItemHeaders = {
      field: 0,
      app_id: this.appId,
      app_name: this.appName,
      app_icon: this.appIconPath,
      content_id: guid,
      payload: `{"content_type":"episode","id":"${guid}"}`,
      image_url: imageUrl,
      content_title: contentTitle,
      sub_title: subtitle,
      rate: rating as SeriesRating | MovieRating,
      release: releaseYear,
      duration: parseInt(durationSecs),
      playback: progress,
      expiry: expirationTime,
      timestamp: lastModified,
      Season: season ? parseInt(season) : 0,
      Episode: episodeInSeason ? parseInt(episodeInSeason) : 0,
    };

    const response = await this.fetchHelper<boolean>(
      Endpoints.ADD_ITEM,
      headers,
    );

    if (!response || !response.data || typeof response.data !== 'boolean') {
      // Unsuccessful add, nothing to do
    } else if (this.tizenHistory) {
      this.tizenHistory.push({
        content_id: headers.content_id,
        playback: `${headers.playback}`,
      });
    }
  }

  private async getTizenHistoryEntries() {
    const headers: GetItemHeaders = { app_id: this.appId };
    const response = await this.fetchHelper<TizenHistoryEntry[] | null>(
      Endpoints.GET_ITEMS,
      headers,
    );

    if (!response || !Array.isArray(response.data)) {
      // Treat null ambiguously, we weren't able to fetch history items
      return null;
    } else if (!response.data) {
      // response.data is only null when there are no continue watching items saved
      return [];
    } else {
      // response.data has some saved history
      return response.data;
    }
  }

  private async fetchHelper<ResponseType = unknown>(
    endpoint: Endpoints,
    headers: AddItemHeaders | DeleteItemHeaders | GetItemHeaders,
  ) {
    try {
      const url = `${BASE_ENDPOINT}${endpoint}`;
      return await axios.request<ResponseType>({
        method: 'GET',
        url,
        headers,
      });
    } catch (e: any) {
      console.warn(e);
      return null;
    }
  }
}
